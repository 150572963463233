<template>
  <div class="app__body">
    <div class="gd-user-acknowledgement">
      <div v-if="custSARID" class="alert alert--success">
        <span class="icon icon-tick"></span>
        <p>
          <span>{{ $t("labeltext.ackText") }}</span>
          <br />
          <span>{{  $t('labeltext.reqID') }}</span>
          <strong class="label-padding" data-auto="custSARID"> {{ custSARID }} </strong>
          <br />
        </p>
      </div>
      <div class="pt-medium gd-text-right">
        <button
          class="button"
          id="newSARRequest"
          type="submit"
          @click="redirectToNewForm"
        >
          {{ $t("labeltext.makeNewReq") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "UserAcknowledgement",
  props: {
    custSARID: {
      required: true,
    },
  },
  methods: {
    redirectToNewForm() {
      window.location.reload();
    },
  },
});
</script>