<template>
  <div>
    <div class="mb-gutter">
      <h3>{{ $t("labeltext.sarmgmtUploadDoc") }}</h3>
    </div>
    <div class="gd-documents mb-gutter">
      <div class="form-field form-field--gd">
        <label class="field-row">
          <span
            data-auto="assignTo-lbl"
            class="label label--text label--inline"
            >{{ $t("labeltext.sarmgmtDocTitle") }}</span
          >
          <input
            type="text"
            v-model="attachmentModel.DocumentTitle"
            name="AssignTo"
            maxlength="100"
          />
        </label>
      </div>
      <div class="form-field form-field--gd">
        <div class="field-row">
          <span
            class="label label--text label--inline"
            data-auto="choosefile-lbl"
            >{{ $t("labeltext.selectUpload") }}</span
          >
          <div class="gd-file-upload">
            <label class="gd-file-selector">
              <input
                type="file"
                name="choosefile"
                ref="fileupload"
                @change="fileChange($event.target.files)"
              />
              <br />
              <span class="font-small" data-auto="choosefiletext-lbl">
                (<span v-for="(ext, index) in allowedFileTypes" :key="index"
                  >.{{ ext
                  }}<span v-if="index !== allowedFileTypes.length - 1"
                    >,
                  </span></span
                >)
              </span>
            </label>
            <button
              class="button button--alt"
              id="uploadfile"
              type="button"
              @click="upload"
            >
              {{ $t("labeltext.upload") }}
            </button>
          </div>
        </div>
      </div>
      <div class="gd-form-part">
        <h3>
          <span v-if="showFileUploadSuccessMessage" class="success-text">
            {{ $t("labeltext.fileUploadSuccessful") }}
          </span>
        </h3>
      </div>
      <div class="gd-file-list font-small" v-if="DocumentList.length > 0">
        <p>{{ $t("labeltext.listUploadedFiles") }}</p>
        <ul class="list-styled">
          <li v-for="(item, index) in DocumentList" :key="index">
            {{ index + 1 }}. {{ item.fileName }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SARLegalAttachmentModel } from "@/models/SARLegalAttachmentModel";
import { UploadConfig } from "@/config/application-config";
import { GSARManagementService } from "@/services/gsar.management.service";
import { SARLegalInitModel } from "@/models/SARLegalInitModel";
import { SARLegalMgmtModel } from "@/models/SARLegalMgmtModel";
import CommonMixin from "@/mixins/common.mixin";
import VueScrollTo from "vue-scrollto";

export default defineComponent({
  name: "SARMgmtFileUpload",
  mixins: [CommonMixin],
  props: {
    initModel: {
      type: SARLegalInitModel,
      required: true,
    },
    sarMgmtModel: {
      type: SARLegalMgmtModel,
      required: true,
    },
  },
  data() {
    return {
      files: new FormData(),
      showFileUploadSuccessMessage: false,
      validationMessage: [] as string[],
      selectedFile: {} as File,
      allowedFileTypes: [
        "doc",
        "docx",
        "xls",
        "xlsx",
        "pdf",
        "jpg",
        "png",
        "gif",
        "txt",
        "msg",
      ],
      attachmentModel: new SARLegalAttachmentModel(),
      DocumentList: [] as any,
    };
  },
  methods: {
    fileChange(fileList: any) {
      this.files.append("file", fileList[0], fileList[0].name);
      if (fileList.length !== 0) {
        this.selectedFile = fileList[0];
        this.attachmentModel.FileName = fileList[0].name;
        this.showFileUploadSuccessMessage = false;
      }
    },

    clear() {
      const fileUploadInput = this.$refs.fileupload as any;
      fileUploadInput.type = "text";
      fileUploadInput.type = "file";
      this.attachmentModel.DocumentTitle = "";
      this.files = new FormData();
      this.attachmentModel.FileName = "";
    },

    upload() {
      this.validateInput();

      if (!this.validationMessage.length) {
        let self = this;

        const service = new GSARManagementService();
        service
          .fileUpload(this.files)
          .then((response) => {
            if (response !== undefined && response.data !== null) {
              this.attachmentModel.SarID = this.initModel.SARID;
              this.attachmentModel.FileGuid = response.data;
              this.attachmentModel.UploadedDate = this.formatDate(new Date());
              this.saveAttachmentDetails();
            } else {
              this.logFileUploadError();
            }
          })
          .catch((error) => {
            self.logFileUploadError();
          });
      } else {
        this.scrollToTop();
      }
    },

    saveAttachmentDetails() {
      let self = this;

      const service = new GSARManagementService();
      service
        .saveAttachments(this.attachmentModel)
        .then((response) => {
          if (response.data !== null) {
            this.showFileUploadSuccessMessage = true;

            var obj = {} as any;
            obj["fileName"] = this.attachmentModel.FileName;
            obj["title"] = this.attachmentModel.DocumentTitle;

            this.DocumentList.push(obj);
            if (response.data.length > 0) {
              this.sarMgmtModel.CurrentAttachments = response.data;
            }
          } else {
            this.logFileUploadError();
          }

          this.clear();
        })
        .catch((error) => {
          self.logFileUploadError();
        });
    },

    logFileUploadError() {
      this.validationMessage = [];
      this.validationMessage.push(
        this.translate("validationtext.validationFileUploadFailed")
      );
      this.showFileUploadSuccessMessage = false;
      this.$emit("showValidationMessage", this.validationMessage);
    },

    validateInput() {
      this.validationMessage = [];

      if (!this.attachmentModel.DocumentTitle) {
        this.validationMessage.push(
          this.translate("validationtext.validationDocTitle")
        );
      }
      if (!this.attachmentModel.FileName) {
        this.validationMessage.push(
          this.translate("validationtext.validationSelectFile")
        );
      } else {
        let fileExtension = this.attachmentModel.FileName.split(".").pop();
        let allowedFileExtension = this.allowedFileTypes;
        if (allowedFileExtension.indexOf(fileExtension!.toLowerCase()) < 0) {
          this.validationMessage.push(
            this.$t("validationtext.validationAllowedFileTypes", {
              allowedFileExtensions: this.allowedFileTypes.toString(),
            })
          );
        }
      }
      if (
        this.selectedFile !== undefined &&
        Math.round(this.selectedFile.size / 1024) >
          UploadConfig.maxFileUploadSize
      ) {
        this.validationMessage.push(
          this.$t("validationtext.validationMaxFileSize", {
            filesize: UploadConfig.maxFileUploadinMB,
          })
        );
        this.files = new FormData();
      }

      if (this.validationMessage.length !== 0) {
        this.showFileUploadSuccessMessage = false;
      }

      this.$emit("showValidationMessage", this.validationMessage);
    },

    scrollToTop() {
      VueScrollTo.scrollTo("#top", 0);
    },
  },
});
</script>
