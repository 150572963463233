import { defineStore } from 'pinia';

export const useUserModule = defineStore('userModule', {
    state: () => ({
        state: {
            jwtTokenAuthLegal: '',
            jwtTokenAuthCustomer: ''
        }
    }),
    persist: true
});