<template>
  <div>
    <div class="gd-user-contact-details mb-gutter type-of-request mt-gutter">
      <fieldset class="form-field">
        <div class="grid">
          <div class="grid__item">
            <legend class="text-right">
              {{ $t("labeltext.typeOfRequest") }}
            </legend>
          </div>

          <div class="grid__item">
            <div class="form__row">
              <label class="label--inline">
                <input
                  type="checkbox"
                  id="DataAccessRequest"
                  name="typeofrequest"
                  value="1"
                  v-model="newUser.RequestType"
                  :disabled="initModel.isDisabled"
                />
                <span class="css-checkbox"></span>
                <span
                  class="label label--inline label-padding"
                  data-auto="DataAccessRequest-lbl"
                >
                  {{ $t("labeltext.requestDataAccess") }}
                </span>
              </label>
              <span class="tooltip-icon"
                ><i data-auto="AccessRequestInfo-btn"
                  >i
                  <v-tooltip
                    activator="parent"
                    location="bottom"
                    width="320px"
                    :contained="true"
                    :open-on-click="true"
                  >
                    {{ getBrandTranslation("labeltext.tooltipAccessRequest") }}
                  </v-tooltip>
                </i></span
              >
            </div>

            <div class="form__row">
              <label class="label--inline">
                <input
                  type="checkbox"
                  id="DataDeleteRequest"
                  name="typeofrequest"
                  value="2"
                  v-model="newUser.RequestType"
                  :disabled="initModel.isDisabled"
                />
                <span class="css-checkbox"></span>
                <span
                  class="label label--inline label-padding"
                  data-auto="DataDeleteRequest-lbl"
                >
                  {{ $t("labeltext.requestDataDelete") }}
                </span>
              </label>
              <span class="tooltip-icon"
                ><i data-auto="DeleteRequestInfo-btn"
                  >i
                  <v-tooltip
                    activator="parent"
                    location="bottom"
                    width="320px"
                    :contained="true"
                    :open-on-click="true"                    
                  >
                    {{ getBrandTranslation("labeltext.tooltipDataDeletion") }}
                  </v-tooltip>
                </i></span
              >
            </div>

            <div class="form__row">
              <label class="label--inline">
                <input
                  type="checkbox"
                  id="DataRectificationRequest"
                  name="typeofrequest"
                  value="3"
                  v-model="newUser.RequestType"
                  :disabled="initModel.isDisabled"
                />
                <span class="css-checkbox"></span>
                <span
                  class="label label--inline label-padding"
                  data-auto="DataRectificationRequest-lbl"
                >
                  {{ $t("labeltext.requestDataRect") }}
                </span>
              </label>
              <span class="tooltip-icon"
                ><i data-auto="RectificationRequestInfo-btn"
                  >i
                  <v-tooltip
                    activator="parent"
                    location="bottom"
                    width="320px"
                    :contained="true"
                    :open-on-click="true"                    
                  >
                    {{
                      getBrandTranslation("labeltext.tooltipDataRectification")
                    }}
                  </v-tooltip>
                </i></span
              >
            </div>

            <div class="form__row">
              <label class="label--inline">
                <input
                  type="checkbox"
                  id="DataProcessingRequest"
                  name="typeofrequest"
                  value="4"
                  v-model="newUser.RequestType"
                  :disabled="initModel.isDisabled"
                />
                <span class="css-checkbox"></span>
                <span
                  class="label label--inline label-padding"
                  data-auto="DataProcessingRequest-lbl"
                >
                  {{ $t("labeltext.requestDataObject") }}
                </span>
              </label>
              <span class="tooltip-icon"
                ><i data-auto="ProcessingRequestInfo-btn"
                  >i
                  <v-tooltip
                    activator="parent"
                    location="bottom"
                    width="320px"
                    :contained="true"
                    :open-on-click="true"                    
                  >
                    {{ getBrandTranslation("labeltext.tooltipDataObject") }}
                  </v-tooltip>
                </i></span
              >
            </div>
          </div>
        </div>
      </fieldset>
    </div>

    <div class="mb-gutter" v-if="!initModel.isDisabled">
      <p data-auto="OtherTypesText">
        {{ $t("labeltext.addtionalRequestTypeText") }}
      </p>
    </div>

    <div class="mb-gutter">
      <h3>{{ $t("labeltext.partAHeadingText") }}</h3>
      <p>
        {{ getBrandTranslation("labeltext.partABodyText") }}
      </p>
    </div>

    <div class="gd-user-contact-details gd-user-contact-details--alt mb-gutter">
      <div class="form-field form-field--gd">
        <label class="field-row">
          <span class="label label--text label--inline" data-auto="title-lbl">{{
            $t("labeltext.title")
          }}</span>
          <select
            v-model="initModel.selectedTitleValue"
            name="title"
            :disabled="initModel.isDisabled"
          >
            <option value="">
              {{ $t("labeltext.titleDefaultValue") }}
            </option>
            <option
              v-for="(titleDDL, index) in getDropdownValues"
              :value="titleDDL.value"
              :key="index"
            >
              {{ getDDLTranslatedValue(titleDDL.resourceKeyVal) }}
            </option>
          </select>
        </label>
      </div>

      <div
        class="form-field form-field--gd"
        v-if="initModel.selectedTitleValue == '5'"
        id="otherTitle"
      >
        <label class="field-row">
          <span
            class="label label--text label--inline"
            data-auto="othertitle-lbl"
            >{{ $t("labeltext.otherTitleLabel") }}</span
          >
          <input
            type="text"
            v-model="initModel.selectedOtherTitle"
            name="othertitle"
            maxlength="15"
            :disabled="initModel.isDisabled"
            :placeholder="
              initModel.isDisabled == true
                ? ''
                : translate('labeltext.otherTitlePlc')
            "
          />
        </label>
      </div>

      <div class="form-field form-field--gd">
        <label class="field-row">
          <span
            class="label label--text label--inline"
            data-auto="surname-lbl"
            >{{ $t("labeltext.surname") }}</span
          >
          <input
            type="text"
            v-model="newUser.LastName"
            name="surname"
            maxlength="30"
            :disabled="initModel.isDisabled"
            :placeholder="
              initModel.isDisabled == true
                ? ''
                : translate('labeltext.surname')
            "
          />
        </label>
      </div>

      <div class="form-field form-field--gd">
        <label class="field-row">
          <span
            class="label label--text label--inline"
            data-auto="forename-lbl"
            >{{ $t("labeltext.forename") }}</span
          >
          <input
            type="text"
            v-model="newUser.FirstName"
            name="forename"
            maxlength="50"
            :disabled="initModel.isDisabled"
            :placeholder="
              initModel.isDisabled == true
                ? ''
                : translate('labeltext.forename')
            "
          />
        </label>
      </div>

      <div class="form-field form-field--gd">
        <label class="field-row">
          <span
            class="label label--text label--inline"
            data-auto="curraddressline1-lbl"
            >{{ $t("labeltext.currentAddress") }}</span
          >
          <input
            type="text"
            v-model="newUser.CurrentAddress.AddressLine1"
            :disabled="initModel.isDisabled"
            :placeholder="
              initModel.isDisabled == true
                ? ''
                : translate('labeltext.addline1Plc')
            "
            name="curraddressline1"
            maxlength="100"
          />
        </label>
      </div>

      <div class="form-field form-field--gd">
        <label class="field-row">
          <span class="label label--text label--inline"></span>
          <input
            type="text"
            v-model="newUser.CurrentAddress.AddressLine2"
            :disabled="initModel.isDisabled"
            :placeholder="
              initModel.isDisabled == true
                ? ''
                : translate('labeltext.addLine2Plc')
            "
            name="curraddressline2"
            maxlength="100"
          />
        </label>
      </div>

      <div class="form-field form-field--gd">
        <label class="field-row">
          <span class="label label--text label--inline"></span>
          <input
            type="text"
            v-model="newUser.CurrentAddress.AddressLine3"
            :disabled="initModel.isDisabled"
            :placeholder="
              initModel.isDisabled == true
                ? ''
                : translate('labeltext.addLine3Plc')
            "
            name="curraddressline3"
            maxlength="100"
          />
        </label>
      </div>

      <div class="form-field form-field--gd">
        <label class="field-row">
          <span class="label label--text label--inline"></span>
          <input
            type="text"
            v-model="newUser.CurrentAddress.Town"
            :disabled="initModel.isDisabled"
            :placeholder="
              initModel.isDisabled == true
                ? ''
                : translate('labeltext.townPlc')
            "
            name="currtown"
            maxlength="50"
          />
        </label>
      </div>

      <div class="form-field form-field--gd">
        <label class="field-row">
          <span class="label label--text label--inline"></span>
          <input
            type="text"
            v-model="newUser.CurrentAddress.County"
            :disabled="initModel.isDisabled"
            :placeholder="
              initModel.isDisabled == true
                ? ''
                : translate('labeltext.countyPlc')
            "
            name="currcounty"
            maxlength="50"
          />
        </label>
      </div>

      <div class="form-field form-field--gd">
        <label class="field-row">
          <span class="label label--text label--inline"></span>
          <select
            v-model="newUser.CurrentAddress.Country"
            :disabled="initModel.isDisabled"
            name="currcountry"
          >
            <option value="">
              {{ $t("labeltext.countryDefaultValue") }}
            </option>
            <option
              v-for="(countryVal, index) in initModel.CountriesList"
              :value="countryVal.CountryCode"
              :key="index"
            >
              {{ countryVal.CountryName }}
            </option>
          </select>
        </label>
      </div>

      <div class="form-field form-field--gd">
        <label class="field-row">
          <span
            class="label label--text label--inline"
            data-auto="currpostcode-lbl"
            >{{ $t("labeltext.postCode") }}</span
          >
          <input
            type="text"
            v-model="newUser.CurrentAddress.PostCode"
            :disabled="initModel.isDisabled"
            :placeholder="
              initModel.isDisabled == true
                ? ''
                : translate('labeltext.postCode')
            "
            name="currpostcode"
            maxlength="25"
          />
        </label>
      </div>

      <div class="form-field form-field--gd">
        <div class="field-row">
          <span class="label label--text label--inline label--multi-line">{{
            $t("labeltext.prevAddressWarning")
          }}</span>

          <div class="gd-field-multi-select form-field--gd__options">
            <label>
              <input
                type="radio"
                id="PrevAddressYes"
                value="true"
                name="isPrevAddress"
                v-model="newUser.PrevAddress"
                :disabled="initModel.isDisabled"
                @change="clearInputFieldsPrevAddress"
              />
              <span class="css-radio"></span>
              <span class="label label--inline label-padding" data-auto="isPrevAddress-yes-lbl">{{
                $t("labeltext.yesText")
              }}</span>
            </label>

            <label>
              <input
                type="radio"
                id="PrevAddressNo"
                value="false"
                name="isPrevAddress"
                v-model="newUser.PrevAddress"
                :disabled="initModel.isDisabled"
                @change="clearInputFieldsPrevAddress"
              />
              <span class="css-radio"></span>
              <span class="label label--inline label-padding" data-auto="isPrevAddress-no-lbl">{{
                $t("labeltext.noText")
              }}</span>
            </label>
          </div>
        </div>
      </div>

      <div class="mb-gutter" v-if="newUser.PrevAddress.toString() === 'true'">
        <div class="form-field form-field--gd">
          <label class="field-row">
            <span
              class="label label--text label--inline"
              data-auto="prevaddressline1-lbl"
              >{{ $t("labeltext.prevAddress") }}</span
            >
            <input
              type="text"
              v-model="newUser.PreviousAddress.AddressLine1"
              :placeholder="
                initModel.isDisabled == true
                  ? ''
                  : translate('labeltext.addline1Plc')
              "
              :disabled="initModel.isDisabled"
              name="prevaddressline1"
              maxlength="100"
            />
          </label>
        </div>

        <div class="form-field form-field--gd">
          <label class="field-row">
            <span class="label label--text label--inline"></span>
            <input
              type="text"
              v-model="newUser.PreviousAddress.AddressLine2"
              :placeholder="
                initModel.isDisabled == true
                  ? ''
                  : translate('labeltext.addLine2Plc')
              "
              :disabled="initModel.isDisabled"
              name="prevaddressline2"
              maxlength="100"
            />
          </label>
        </div>

        <div class="form-field form-field--gd">
          <label class="field-row">
            <span class="label label--text label--inline"></span>
            <input
              type="text"
              v-model="newUser.PreviousAddress.AddressLine3"
              :placeholder="
                initModel.isDisabled == true
                  ? ''
                  : translate('labeltext.addLine3Plc')
              "
              :disabled="initModel.isDisabled"
              name="prevaddressline3"
              maxlength="100"
            />
          </label>
        </div>

        <div class="form-field form-field--gd">
          <label class="field-row">
            <span class="label label--text label--inline"></span>
            <input
              type="text"
              v-model="newUser.PreviousAddress.Town"
              :placeholder="
                initModel.isDisabled == true
                  ? ''
                  : translate('labeltext.townPlc')
              "
              :disabled="initModel.isDisabled"
              name="prevtown"
              maxlength="50"
            />
          </label>
        </div>

        <div class="form-field form-field--gd">
          <label class="field-row">
            <span class="label label--text label--inline"></span>
            <input
              type="text"
              v-model="newUser.PreviousAddress.County"
              :placeholder="
                initModel.isDisabled == true
                  ? ''
                  : translate('labeltext.countyPlc')
              "
              :disabled="initModel.isDisabled"
              name="prevcounty"
              maxlength="50"
            />
          </label>
        </div>

        <div class="form-field form-field--gd">
          <label class="field-row">
            <span class="label label--text label--inline"></span>
            <select
              v-model="newUser.PreviousAddress.Country"
              :disabled="initModel.isDisabled"
              class="fixedWidth"
              name="prevcountry"
            >
              <option value="">
                {{ $t("labeltext.countryDefaultValue") }}
              </option>
              <option
                v-for="(countryVal, index) in initModel.CountriesList"
                :value="countryVal.CountryCode"
                :key="index"
              >
                {{ countryVal.CountryName }}
              </option>
            </select>
          </label>
        </div>

        <div class="form-field form-field--gd">
          <label class="field-row">
            <span class="label label--text label--inline">{{
              $t("labeltext.postCode")
            }}</span>
            <input
              type="text"
              v-model="newUser.PreviousAddress.PostCode"
              :placeholder="
                initModel.isDisabled == true
                  ? ''
                  : translate('labeltext.postCode')
              "
              :disabled="initModel.isDisabled"
              name="prevpostcode"
              maxlength="25"
            />
          </label>
        </div>
      </div>

      <div class="form-field form-field--gd">
        <label class="field-row">
          <span
            class="label label--text label--inline"
            data-auto="emailaddress-lbl"
            >{{ $t("labeltext.emailAddress") }}</span
          >
          <input
            type="text"
            v-model="newUser.EmailAddress"
            :placeholder="
              initModel.isDisabled == true
                ? ''
                : translate('labeltext.emailAddress')
            "
            :disabled="initModel.isDisabled"
            name="emailaddress"
            maxlength="50"
          />
        </label>
      </div>

      <div class="form-field form-field--gd">
        <label class="field-row">
          <span
            class="label label--text label--inline"
            data-auto="contactnumber-lbl"
            >{{ $t("labeltext.contactNumber") }}</span
          >
          <input
            type="text"
            v-model="newUser.ContactNumber"
            :placeholder="
              initModel.isDisabled == true
                ? ''
                : translate('labeltext.contactNumber')
            "
            :disabled="initModel.isDisabled"
            name="contactnumber"
            maxlength="15"
          />
        </label>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import UserContactDetailsMixin from "@/mixins/userContactDetails.mixin";
import { ResourceItem } from "@/models/SARLegalInitModel";

export default defineComponent({
  name: "UserContactDetails",
  mixins: [UserContactDetailsMixin],
  computed: {
    getDropdownValues(): any {
      return (
        this.initModel.TitleList != null &&
        this.initModel.TitleList.filter(
          (titleDDL: ResourceItem) =>
            !!this.getDDLTranslatedValue(titleDDL.resourceKeyVal)
        )
      );
    },
  },
});
</script>