<template>
  <div>
    <div class="app__body" id="top">
      <v-error
        v-if="showErrorPage"
        :errorMsgFromParent="errorMessage"
      ></v-error>
      <v-sarreports
        v-if="!showErrorPage"
        @showErrorEvent="onShowErrorPage"
        @showHeaderEvent="onShowHeaderFooter"
      >
      </v-sarreports>
      <v-footer v-if="showHeaderFooterComponent"></v-footer>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
import SARError from "@/components/body/SARReview/SARError.vue";
import SARReports from "@/components/body/SARReportView/SARReports.vue";

export default defineComponent({
  name: "SARLegalTeamReports",
  mixins: [CommonMixin],
  components: {
    "v-header": Header,
    "v-footer": Footer,
    "v-error": SARError,
    "v-sarreports": SARReports,
  },
  data() {
    return {
      showErrorPage: false,
      errorMessage: "",
      showHeaderFooterComponent: true,
    };
  },
  methods: {
    onShowErrorPage(errorMsg: string) {
      this.showErrorPage = true;
      this.errorMessage = errorMsg;
    },
    onShowHeaderFooter(showHeaderValue: boolean) {
      this.showHeaderFooterComponent = showHeaderValue;
    },
  },
});
</script>
