<template>
  <div>
    <div v-if="legalteamview">
      <link rel="stylesheet" type="text/css" href="./assets/css/styles_kfplc_1.css" />
      <v-header v-if="showHeaderFooter" />
      <div class="app__body">
        <v-navbar v-if="showHeaderFooter"></v-navbar>
      </div>
    </div>
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { RouteName } from "./common/common.model";
import { eventBus } from "@/plugins/mitt-helper";
import NavBar from "@/components/navBar.vue";
import Header from "@/components/header.vue";

export default defineComponent({
  name: "App",
  components: {
    "v-navbar": NavBar,
    "v-header": Header,
  },
  data() {
    return {
      legalteamview: false,
      showHeaderFooter: false
    };
  },
  watch: {
    $route: {
      handler(val, oldVal) {
        if (
          this.$route.name === RouteName.SARManagement ||
          this.$route.name === RouteName.SARLegalTeamReports ||
          this.$route.name === RouteName.SARHome
        ) {
          this.legalteamview = true;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    eventBus.on("showHeaderFooterEvent", (data) => {
      this.showHeaderFooter = data;
    });
  },
});
</script>

<style scoped>

</style>