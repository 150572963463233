<template>
  <div
    id="manageSARDetails"
    class="pt-gutter pb-gutter bodydynamic"
  >
    <form>
      <div class="mb-gutter" v-if="initModel.modelErrors.length">
        <div v-if="authModel.isRequestAuthorized" class="alert alert--error">
          <span class="icon icon-alert"></span>
          <p>
            <strong>{{ $t("validationtext.validationHeading") }} </strong>
          </p>
          <ul class="list-styled">
            <li v-for="(error, index) in initModel.modelErrors" :key="index">
              {{ error }}
            </li>
          </ul>
        </div>
        <div v-else class="alert alert--error">
          <span class="icon icon-alert"></span>
          <div v-for="(error, index) in initModel.modelErrors" :key="index">
            {{ error }}
          </div>
        </div>
      </div>

      <br v-if="initModel.modelErrors.length" />
      <div v-if="authModel.isRequestAuthorized && showReviewComponent">
        <v-SAREdit
          :initModel="initModel"
          :sarLegalMgmt="newSARLegalMgmt"
          :sargridsearchTaskResultFromParent="searchTaskResult"
          :showtaskgrid="enableTaskGrid"
          @sarMgmtAddNewTask="addNewTask"
          :sarLegalTask="sarLegalMgmtTask"
          @sarMgmtConsentHistory="getConsentHistory"
          :sarConsentHstryGrid="consentHistoryGridDetails"
          :showConsentHstryGrid="enableConsentHstryGrid"
          :sarConsentHistoryDataModel="sarConsentHistryModel"
          :consentHistoryDownloadOptions="consentHistoryDownloadOptions"
          :sarLegalNotes="sarLegalMgmtNotes"
          @sarMgmttUpdate="saveSarMgmtChanges"
          @sarMgmtNotesUpdate="saveSarNotes"
          @showValidationMessageEventFromChild="showErrorMessageForFileUpload"
          @fileDownloadEventFromChild="downloadAttachments"
        >
        </v-SAREdit>

        <v-usercontactdetails
          :initModel="initModel"
          :newUser="newUser"
          v-show="
            !isComponentVersionUpdateRequired(childComponentSection.PartA)
          "
        >
        </v-usercontactdetails>

        <v-usercontactdetailsv2
          :initModel="initModel"
          :newUser="newUser"
          v-show="
            isComponentVersionUpdateRequired(childComponentSection.PartA) &&
            userContactDetailsVersion == 2
          "
        ></v-usercontactdetailsv2>

        <v-usercontactdetailsv3
          :initModel="initModel"
          :newUser="newUser"
          v-show="
            isComponentVersionUpdateRequired(childComponentSection.PartA) &&
            userContactDetailsVersion == 3
          "
        ></v-usercontactdetailsv3>

        <v-usercontactdetailsv4
          :initModel="initModel"
          :newUser="newUser"
          v-show="
            isComponentVersionUpdateRequired(childComponentSection.PartA) &&
            userContactDetailsVersion == 4
          "
        ></v-usercontactdetailsv4>

        <v-usercontactdetailsv5
          :initModel="initModel"
          :newUser="newUser"
          v-show="
            isComponentVersionUpdateRequired(childComponentSection.PartA) &&
            userContactDetailsVersion == 5
          "
        ></v-usercontactdetailsv5>

        <v-usercontactdetailsv6
          :initModel="initModel"
          :newUser="newUser"
          v-show="
            isComponentVersionUpdateRequired(childComponentSection.PartA) &&
            userContactDetailsVersion == 6
          "
        ></v-usercontactdetailsv6>

        <v-usercontactdetailsv7
          :initModel="initModel"
          :newUser="newUser"
          :styleCode="styleId"
          v-show="
            styleId &&
            isComponentVersionUpdateRequired(childComponentSection.PartA) &&
            userContactDetailsVersion == 7
          "
        />

        <v-userrelationship
          :initModel="initModel"
          :newUser="newUser"
          v-show="
            !isComponentVersionUpdateRequired(childComponentSection.PartB)
          "
        >
        </v-userrelationship>

        <v-userrelationshipv2
          :initModel="initModel"
          :newUser="newUser"
          v-show="
            isComponentVersionUpdateRequired(childComponentSection.PartB) &&
            userCustEmpRelationshipVersion == 2
          "
        ></v-userrelationshipv2>

        <v-userrelationshipv4
          :initModel="initModel"
          :newUser="newUser"
          v-show="
            isComponentVersionUpdateRequired(childComponentSection.PartB) &&
            userCustEmpRelationshipVersion == 4
          "
        ></v-userrelationshipv4>

        <v-userrelationshipv5
          :initModel="initModel"
          :newUser="newUser"
          :styleCode="styleId"
          v-show="
            styleId &&
            isComponentVersionUpdateRequired(childComponentSection.PartB) &&
            userCustEmpRelationshipVersion == 5
          "
        />

        <v-usercctvdetails
          :initModel="initModel"
          :newUser="newUser"
          v-show="
            !isComponentVersionUpdateRequired(childComponentSection.PartC)
          "
        >
        </v-usercctvdetails>

        <v-usercctvdetailsv2
          :initModel="initModel"
          :newUser="newUser"
          v-show="
            isComponentVersionUpdateRequired(childComponentSection.PartC) &&
            userCCTVDetailsVersion == 2
          "
        >
        </v-usercctvdetailsv2>

        <v-usercctvdetailsv3
          :initModel="initModel"
          :newUser="newUser"
          v-show="
            isComponentVersionUpdateRequired(childComponentSection.PartC) &&
            userCCTVDetailsVersion == 3
          "
        >
        </v-usercctvdetailsv3>

        <v-usercctvdetailsv4
          :initModel="initModel"
          :newUser="newUser"
          v-show="
            isComponentVersionUpdateRequired(childComponentSection.PartC) &&
            userCCTVDetailsVersion == 4
          "
        >
        </v-usercctvdetailsv4>

        <v-usercctvdetailsv5
          :initModel="initModel"
          :newUser="newUser"
          v-show="
            isComponentVersionUpdateRequired(childComponentSection.PartC) &&
            userCCTVDetailsVersion == 5
          "
        >
        </v-usercctvdetailsv5>

        <v-userattachmentdetails
          v-if="newUser.DocumentList.length !== 0"
          :newUser="newUser"
          :initModel="initModel"
          @emmitedFileName="downloadAttachments"
          v-show="
            !isComponentVersionUpdateRequired(childComponentSection.PartE)
          "
        >
        </v-userattachmentdetails>

        <v-userattachmentdetailsv2
          v-if="newUser.DocumentList.length !== 0"
          :newUser="newUser"
          :initModel="initModel"
          @emmitedFileName="downloadAttachments"
          v-show="
            isComponentVersionUpdateRequired(childComponentSection.PartE) &&
            userAttachmentDetailsVersion == 2
          "
        >
        </v-userattachmentdetailsv2>

        <v-userattachmentdetailsv4
          :newUser="newUser"
          :initModel="initModel"
          @emmitedFileName="downloadAttachments"
          v-show="
            isComponentVersionUpdateRequired(childComponentSection.PartE) &&
            userAttachmentDetailsVersion == 4
          "
        >
        </v-userattachmentdetailsv4>

        <v-userattachmentdetailsv6
          :newUser="newUser"
          :initModel="initModel"
          @emmitedFileName="downloadAttachments"
          v-show="
            isComponentVersionUpdateRequired(childComponentSection.PartE) &&
            userAttachmentDetailsVersion == 6
          "
        >
        </v-userattachmentdetailsv6>

        <v-userattachmentdetailsv7
          v-if="newUser.DocumentList.length !== 0"
          :newUser="newUser"
          :initModel="initModel"
          @emmitedFileName="downloadAttachments"
          v-show="
            isComponentVersionUpdateRequired(childComponentSection.PartE) &&
            userAttachmentDetailsVersion == 7
          "
        >
        </v-userattachmentdetailsv7>

        <v-userattachmentdetailsv8
          v-if="newUser.DocumentList.length !== 0"
          :newUser="newUser"
          :initModel="initModel"
          @emmitedFileName="downloadAttachments"
          :styleCode="styleId"
          v-show="
            styleId &&
            isComponentVersionUpdateRequired(childComponentSection.PartE) &&
            userAttachmentDetailsVersion == 8
          "
        />
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SARLegalInitModel } from "@/models/SARLegalInitModel";
import { SARLegalUserModel } from "@/models/SARLegalUserModel";
import { SARLegalMgmtModel } from "@/models/SARLegalMgmtModel";
import { SARLegalMgmtNotesModel } from "@/models/SARLegalMgmtNotesModel";
import { SARConsentHistoryModel } from "@/models/SARConsentHistoryModel";
import { SARLegalMgmtTaskModel } from "@/models/SARLegalMgmtTaskModel";
import { ChildComponentSection, AttachmentFileDetail, AuthModel } from "@/common/common.model";

import UserContactDetails from "@/components/body/UserDetails/UserContactDetails.vue";
import UserContactDetailsV2 from "@/components/body/UserDetails/UserContactDetailsV2.vue";
import UserContactDetailsV3 from "@/components/body/UserDetails/UserContactDetailsV3.vue";
import UserContactDetailsV4 from "@/components/body/UserDetails/UserContactDetailsV4.vue";
import UserContactDetailsV5 from "@/components/body/UserDetails/UserContactDetailsV5.vue";
import UserContactDetailsV6 from "@/components/body/UserDetails/UserContactDetailsV6.vue";
import UserContactDetailsV7 from "@/components/body/UserDetails/UserContactDetailsV7.vue";
import UserCCTVDetails from "@/components/body/UserDetails/UserCCTVDetails.vue";
import UserCCTVDetailsV2 from "@/components/body/UserDetails/UserCCTVDetailsV2.vue";
import UserCCTVDetailsV3 from "@/components/body/UserDetails/UserCCTVDetailsV3.vue";
import UserCCTVDetailsV4 from "@/components/body/UserDetails/UserCCTVDetailsV4.vue";
import UserCCTVDetailsV5 from "@/components/body/UserDetails/UserCCTVDetailsV5.vue";
import UserAttachmentDetails from "@/components/body/SARReview/SARAttachments.vue";
import UserAttachmentDetailsV2 from "@/components/body/SARReview/SARAttachmentsV2.vue";
import UserAttachmentDetailsV4 from "@/components/body/SARReview/SARAttachmentsV4.vue";
import UserAttachmentDetailsV6 from "@/components/body/SARReview/SARAttachmentsV6.vue";
import UserAttachmentDetailsV7 from "@/components/body/SARReview/SARAttachmentsV7.vue";
import UserAttachmentDetailsV8 from "@/components/body/SARReview/SARAttachmentsV8.vue";
import UserCustEmpRelationship from "@/components/body/UserDetails/UserCustEmpRelationship.vue";
import UserCustEmpRelationshipV2 from "@/components/body/UserDetails/UserCustEmpRelationshipV2.vue";
import UserCustEmpRelationshipV4 from "@/components/body/UserDetails/UserCustEmpRelationshipV4.vue";
import UserCustEmpRelationshipV5 from "@/components/body/UserDetails/UserCustEmpRelationshipV5.vue";
import SAREdit from "@/components/body/SARMgmtDetails/SARMgmtEdit.vue";

import { eventBus } from "@/plugins/mitt-helper";
import { GSARService } from "@/services/gasr.service";
import { GSARManagementService } from "@/services/gsar.management.service";
import { SARMgmtInitModel, SARMgmtTasks } from "@/models/SARMgmtInitModel";
import { SARApplicant } from "@/models/SARApplicant";
import { ICustomerConsentInit } from "@/models/service-request-response-model";

import CommonMixin from "@/mixins/common.mixin";
import VueScrollTo from "vue-scrollto";

export default defineComponent({
  name: "SARReview",
  mixins: [CommonMixin],
  components: {
    "v-usercontactdetails": UserContactDetails,
    "v-usercontactdetailsv2": UserContactDetailsV2,
    "v-usercontactdetailsv3": UserContactDetailsV3,
    "v-usercontactdetailsv4": UserContactDetailsV4,
    "v-usercontactdetailsv5": UserContactDetailsV5,
    "v-usercontactdetailsv6": UserContactDetailsV6,
    "v-usercontactdetailsv7": UserContactDetailsV7,
    "v-usercctvdetails": UserCCTVDetails,
    "v-usercctvdetailsv2": UserCCTVDetailsV2,
    "v-usercctvdetailsv3": UserCCTVDetailsV3,
    "v-usercctvdetailsv4": UserCCTVDetailsV4,
    "v-usercctvdetailsv5": UserCCTVDetailsV5,
    "v-userattachmentdetails": UserAttachmentDetails,
    "v-userattachmentdetailsv2": UserAttachmentDetailsV2,
    "v-userattachmentdetailsv4": UserAttachmentDetailsV4,
    "v-userattachmentdetailsv6": UserAttachmentDetailsV6,
    "v-userattachmentdetailsv7": UserAttachmentDetailsV7,
    "v-userattachmentdetailsv8": UserAttachmentDetailsV8,
    "v-userrelationship": UserCustEmpRelationship,
    "v-userrelationshipv2": UserCustEmpRelationshipV2,
    "v-userrelationshipv4": UserCustEmpRelationshipV4,
    "v-userrelationshipv5": UserCustEmpRelationshipV5,
    "v-SAREdit": SAREdit,
  },
  computed: {
    isFormVersioningRequired(): boolean {
      return this.initModel.formVersion > 1;
    },
    isMappingExistsForCurrentBanner(): boolean {
      return (
        this.initModel.ComponentMappings != null &&
        this.initModel.ComponentMappings.length > 0
      );
    },
  },
  watch: {
    authModel: {
      handler(val: AuthModel) {
        eventBus.emit("showHeaderFooterEvent", true);
        if (val.isRequestAuthorized) {
          this.initModel.RequestGuid = this.$route.params.sarid.toString();

          const service = new GSARService();
          service
            .initialiseDetails(this.initModel.RequestGuid)
            .then((response) => {
              this.initModel.CountriesList = response.data.Countries;
              this.initModel.brandPlcName = response.data.Countries[0].OpcoName;
              let cultureInfo = response.data.Countries[0].CultureInfo;
              this.initModel.formVersion = response.data.FormVersion;
              this.initModel.languageVersion = response.data.LanguageVersion;
              this.initModel.ComponentMappings =
                response.data.ComponentMappings;

              let language = cultureInfo.substring(0, cultureInfo.indexOf("-"));

              // Change the environment language to match that from the service result
              this.$i18n.locale = language;

              this.initModel.datepickerLang = "en";
              if (!!language) {
                this.initModel.datepickerLang = language;
              }

              this.getSARMgmtDetails();
            })
            .catch((error) => {
              if (error.response.status == 401)
                this.unAuthorizedUser(
                  this.translate("validationtext.validationNotAuth")
                );
              else
                this.logError(
                  this.translate("validationtext.validationRetreiveDetails")
                );

              this.scrollToTop();
            });
        } else {
          this.unAuthorizedUser(
            this.translate("validationtext.validationNotAuth")
          );
          this.scrollToTop();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      authModel: new AuthModel(),
      initModel: new SARLegalInitModel(),
      newUser: new SARLegalUserModel(),
      newSARLegalMgmt: new SARLegalMgmtModel(),
      sarLegalMgmtNotes: new SARLegalMgmtNotesModel(),
      sarConsentHistryModel: new SARConsentHistoryModel(),
      sarLegalMgmtTask: new SARLegalMgmtTaskModel(),
      searchTaskResult: new Array<SARMgmtTasks>(),
      showReviewComponent: false,
      enableTaskGrid: false,
      consentHistoryGridDetails: {} as any,
      enableConsentHstryGrid: false,
      consentHistoryDownloadOptions: {} as any,
      userContactDetailsVersion: 1,
      userCustEmpRelationshipVersion: 1,
      userCCTVDetailsVersion: 1,
      userAttachmentDetailsVersion: 1,
      childComponentSection: ChildComponentSection,
      styleId: "",
    };
  },

  created: function () {
    eventBus.emit("showHeaderFooterEvent", false);

    let sarIDParam = this.$route.params.sarid.toString();
    let tempToken = this.$route.query.token?.toString();
    this.authorizeUser(sarIDParam, tempToken, "review/", this.authModel);
  },

  methods: {
    unAuthorizedUser(errorText: string) {
      this.authModel.isRequestAuthorized = false;
      this.$emit("showErrorEvent", errorText);
      this.logError(errorText);
    },

    logError(errorText: string) {
      this.initModel.modelErrors = [];
      this.initModel.isPostSuccessful = false;
      this.initModel.modelErrors.push(errorText);
      this.scrollToTop();
    },

    getSARMgmtDetails() {
      let sarGuid = this.$route.params.sarid.toString();

      const service = new GSARManagementService();
      service
        .initialise(sarGuid)
        .then((response) => {
          if (
            response.data.isValidGUID === "1" &&
            response.data.isValidUser === "1"
          ) {
            this.newUser = response.data as any; // These two types do not match in the original code and so any is used to facilitate the mismatch
            this.setUserValues(response.data);

            if (!!response.data.SARMgmt.Tasks) {
              for (
                var taskrowindex = 0;
                taskrowindex < response.data.SARMgmt.Tasks.length;
                taskrowindex++
              ) {
                if (
                  response.data.SARMgmt.Tasks[taskrowindex].CompletedDT ==
                  "0001-01-01T00:00:00"
                ) {
                  response.data.SARMgmt.Tasks[taskrowindex].CompletedDT = "";
                }

                if (
                  response.data.SARMgmt.Tasks.length > 0 &&
                  response.data.SARMgmt.Tasks[taskrowindex].CreatedDT != null &&
                  response.data.SARMgmt.Tasks[taskrowindex].CreatedDT?.indexOf(
                    "T"
                  ) !== -1
                ) {
                  response.data.SARMgmt.Tasks[taskrowindex].CreatedDT =
                    response.data.SARMgmt.Tasks[
                      taskrowindex
                    ].CreatedDT!.replace("T", " ");
                }
                if (
                  response.data.SARMgmt.Tasks.length > 0 &&
                  response.data.SARMgmt.Tasks[taskrowindex].CompletedDT !==
                    null &&
                  response.data.SARMgmt.Tasks[
                    taskrowindex
                  ].CompletedDT?.indexOf("T") !== -1
                ) {
                  response.data.SARMgmt.Tasks[taskrowindex].CompletedDT =
                    response.data.SARMgmt.Tasks[
                      taskrowindex
                    ].CompletedDT!.replace("T", " ");
                }
                let resourceKeyValDDL =
                  response.data.SARMgmt.Tasks[taskrowindex]
                    .TaskStatusResource_Key;
                response.data.SARMgmt.Tasks[
                  taskrowindex
                ].TaskStatusResource_Key = this.translate(
                  "labeltext." + resourceKeyValDDL
                );
              }

              this.searchTaskResult = response.data.SARMgmt.Tasks;
              this.enableTaskGrid = true;
            }
            //Load Task End

            this.getSARExistingDetails();
          } else if (
            response.data.isValidGUID === "1" &&
            response.data.isValidUser === "0"
          ) {
            this.unAuthorizedUser(
              this.translate("validationtext.validationNotAuth")
            );
          } else if (
            response.data.isValidGUID === "0" &&
            response.data.isValidUser === "0"
          ) {
            this.unAuthorizedUser(
              this.translate("validationtext.validation404")
            );
          } else {
            this.unAuthorizedUser(
              this.translate("validationtext.validationNotAuth")
            );
          }
        })
        .catch((error) => {
          if (
            error.response === undefined ||
            (error.response !== undefined && error.response.status == 401)
          )
            this.unAuthorizedUser(
              this.translate("validationtext.validationNotAuth")
            );
          else if (error.response !== undefined && error.response.status == 400)
            this.unAuthorizedUser(
              this.translate("validationtext.validation404")
            );
          else
            this.unAuthorizedUser(
              this.translate("validationtext.validationRetreiveDetails")
            );

          this.scrollToTop();
        });
    },

    setUserValues(responseData: SARMgmtInitModel) {
      this.initModel.RequestArrivalTypeList =
        responseData.RequestArrivalTypeList;
      this.initModel.RequestStatusList = responseData.RequestStatusList;
      this.initModel.TaskStatusList = responseData.TaskStatusList;

      this.initModel.RequestGuid = responseData.SARMgmt.RequestGuid;
      this.initModel.SARID = responseData.SARMgmt.SARId.toString();

      // Note: Old code attempts to map directly but there are missing properties
      // and so Typescript will not allow this
      this.newSARLegalMgmt = new SARLegalMgmtModel();
      this.newSARLegalMgmt.SARId = responseData.SARMgmt.SARId.toString();
      this.newSARLegalMgmt.RequestStatusID =
        responseData.SARMgmt.RequestStatusID.toString();
      this.newSARLegalMgmt.Assignee = responseData.SARMgmt.Assignee;
      this.newSARLegalMgmt.RequestArrivalTypeID =
        responseData.SARMgmt.RequestArrivalTypeID.toString();
      this.newSARLegalMgmt.ModifiedDT = responseData.SARMgmt.ModifiedDT;
      this.newSARLegalMgmt.ModifiedBy = responseData.SARMgmt.ModifiedBy;
      this.newSARLegalMgmt.Notes = responseData.SARMgmt.Notes;
      this.newSARLegalMgmt.SARManagementID =
        responseData.SARMgmt.SARManagementID.toString();
      this.newSARLegalMgmt.CurrentAttachments =
        responseData.SARMgmt.CurrentAttachments;
      this.newSARLegalMgmt.ConsentId = responseData.SARMgmt.ConsentId;
      this.newSARLegalMgmt.TenantId = responseData.SARMgmt.TenantId;
      this.newSARLegalMgmt.OpcoName = responseData.SARMgmt.OpcoName;
      this.newSARLegalMgmt.HistoricalNotes =
        responseData.SARMgmt.HistoricalNotes;
      this.newSARLegalMgmt.Tasks = responseData.SARMgmt.Tasks;

      if (responseData.SARMgmt.ActualStartDT == "0001-01-01T00:00:00")
        this.newSARLegalMgmt.ActualStartDT = "";
      else 
        this.newSARLegalMgmt.ActualStartDT = responseData.SARMgmt.ActualStartDT;

      if (responseData.SARMgmt.ExpectedCompletionDT == "0001-01-01T00:00:00")
        this.newSARLegalMgmt.ExpectedCompletionDT = "";
      else 
        this.newSARLegalMgmt.ExpectedCompletionDT = responseData.SARMgmt.ExpectedCompletionDT;

      if (responseData.SARMgmt.ActualCompletionDT == "0001-01-01T00:00:00")
        this.newSARLegalMgmt.ActualCompletionDT = "";
      else
        this.newSARLegalMgmt.ActualCompletionDT = responseData.SARMgmt.ActualCompletionDT;

      if (responseData.SARMgmt.SARCreatedDT) {
        this.newSARLegalMgmt.SARCreatedDT =
          responseData.SARMgmt.SARCreatedDT.indexOf("T") >= 0
            ? responseData.SARMgmt.SARCreatedDT.substring(
                0,
                responseData.SARMgmt.SARCreatedDT.indexOf("T")
              )
            : "";
      }
    },

    addNewTask(refObject: any) {
      if (this.sarLegalMgmtTask.IsUpdateRequest === 0) {
        this.validateTaskInputForCreate();
      } else if (this.sarLegalMgmtTask.IsUpdateRequest === 1) {
        this.validateTaskInoutForEdit(refObject.EditModel);
      }

      if (!this.initModel.modelErrors.length) {
        this.sarLegalMgmtTask.SARId = this.initModel.SARID;
        if (this.sarLegalMgmtTask.IsUpdateRequest === 0)
          this.sarLegalMgmtTask.CreatedDT = this.formatDate(new Date());

        if (this.sarLegalMgmtTask.IsUpdateRequest === 1) {
          this.sarLegalMgmtTask.Description = refObject.EditModel.Description;
          this.sarLegalMgmtTask.Assignee = refObject.EditModel.Assignee;
          this.sarLegalMgmtTask.Comment = refObject.EditModel.Comment;
        } else if (
          this.sarLegalMgmtTask.IsUpdateRequest === 1 ||
          this.sarLegalMgmtTask.IsUpdateRequest === 2
        )
          this.sarLegalMgmtTask.ModifiedDT = this.formatDate(new Date());

        const service = new GSARManagementService();
        service
          .SARTaskActivity(this.sarLegalMgmtTask)
          .then((response) => {
            let hasErrors: boolean = response.data.hasOwnProperty('SubCode');           
            if (!hasErrors) {
              for (
                var taskrowindex = 0;
                taskrowindex < response.data.length;
                taskrowindex++
              ) {
                response.data[taskrowindex].TaskStatusResource_Key =
                  this.translate(
                    "labeltext." +
                      response.data[taskrowindex].TaskStatusResource_Key
                  );
                if (
                  response.data[taskrowindex].CompletedDT !== null &&
                  response.data[taskrowindex].CompletedDT.indexOf("T") !== -1
                ) {
                  response.data[taskrowindex].CompletedDT = response.data[
                    taskrowindex
                  ].CompletedDT.replace("T", " ");
                }

                if (
                  response.data[taskrowindex].CreatedDT !== null &&
                  response.data[taskrowindex].CreatedDT.indexOf("T") !== -1
                ) {
                  response.data[taskrowindex].CreatedDT = response.data[
                    taskrowindex
                  ].CreatedDT.replace("T", " ");
                }
              }
            }

            if (
              !hasErrors &&
              response.data.length > 0
            ) {
              this.searchTaskResult = response.data;
              this.enableTaskGrid = true;
              this.initModel.isPostSuccessful = true;
              this.sarLegalMgmtTask.Description = "";
              this.sarLegalMgmtTask.Assignee = "";
              this.sarLegalMgmtTask.Comment = "";

            } else if (
              !hasErrors &&
              response.data.length === 0
            ) {
              //No records found
              this.searchTaskResult = response.data;
              this.enableTaskGrid = false;
              this.newSARLegalMgmt.Tasks = "";
            } else if (
              hasErrors &&
              response.data.SubCode != 4011 &&
              response.data.SubCode != 4012
            ) {
              this.initModel.isPostSuccessful = true;
              this.sarLegalMgmtTask.Description = "";
              this.sarLegalMgmtTask.Assignee = "";
              this.sarLegalMgmtTask.Comment = "";
            } else {
              this.logError(
                this.translate("validationtext.validationAddTaskError")
              );
              this.scrollToTop();
            }
          })
          .catch((error) => {
            if (error.response !== undefined && error.response.status === 401) {
              this.logError(this.translate("validationtext.validationNotAuth"));
            } else
              this.logError(
                this.translate("validationtext.validationSubmitForm")
              );
            this.scrollToTop();
          });
      }
    },

    validateTaskInputForCreate() {
      this.initModel.modelErrors = [];
      if (!this.sarLegalMgmtTask.Description.trim())
        this.initModel.modelErrors.push(
          this.translate("validationtext.validationAddTaskDescription")
        );

      if (!this.sarLegalMgmtTask.Assignee.trim())
        this.initModel.modelErrors.push(
          this.translate("validationtext.validationAddTaskAssignee")
        );

      if (!(this.initModel.modelErrors.length > 0)) {
        return true;
      } else this.scrollToTop();
    },

    validateTaskInoutForEdit(editModel: any) {
      this.initModel.modelErrors = [];
      if (!editModel.Description.trim())
        this.initModel.modelErrors.push(
          this.translate("validationtext.validationAddTaskDescription")
        );

      if (!editModel.Assignee.trim())
        this.initModel.modelErrors.push(
          this.translate("validationtext.validationAddTaskAssignee")
        );

      if (!(this.initModel.modelErrors.length > 0)) {
        return true;
      } else this.scrollToTop();
    },

    getSARExistingDetails() {
      let sarGuid = this.$route.params.sarid.toString();

      const service = new GSARService();
      service
        .SARDetail(sarGuid)
        .then((response) => {
          if (
            response.data.isValidGUID === "1" &&
            response.data.isValidUser === "1"
          ) {
            this.newUser = JSON.parse(JSON.stringify(response.data));
            this.setUserExistingValues(response.data);
            this.showReviewComponent = true;
            this.styleId = response.data.StyleCode;
          } else if (
            response.data.isValidGUID === "1" &&
            response.data.isValidUser === "0"
          ) {
            this.unAuthorizedUser(
              this.translate("validationtext.validationNotAuth")
            );
          } else if (
            response.data.isValidGUID === "0" &&
            response.data.isValidUser === "0"
          ) {
            this.unAuthorizedUser(
              this.translate("validationtext.validation404")
            );
          } else {
            this.unAuthorizedUser(
              this.translate("validationtext.validationNotAuth")
            );
          }
        })
        .catch((error) => {
          if (error.response.status == 401)
            this.unAuthorizedUser(
              this.translate("validationtext.validationNotAuth")
            );
          else if (error.response.status == 400)
            this.unAuthorizedUser(
              this.translate("validationtext.validation404")
            );
          else
            this.unAuthorizedUser(
              this.translate("validationtext.validationRetreiveDetails")
            );

          this.scrollToTop();
        });
    },

    setUserExistingValues(responseData: SARApplicant) {
      this.setTitle(responseData);
      this.setAddress(responseData);
      this.setStoreVisitDateTime(responseData);

      if (
        this.isFormVersioningRequired &&
        this.isMappingExistsForCurrentBanner
      ) {
        this.setDeclarations(responseData);
      }
    },

    setTitle(responseData: SARApplicant) {
      if (
        this.initModel.TitleList.filter((x) => {
          return (
            this.translate("labeltext." + x.resourceKeyVal) ==
            responseData.Title
          );
        })[0] !== undefined
      ) {
        this.initModel.selectedTitleValue = this.initModel.TitleList.filter(
          (x) => {
            return (
              this.translate("labeltext." + x.resourceKeyVal) ==
              responseData.Title
            );
          }
        )[0].value;
      } else {
        this.initModel.selectedTitleValue = "5";
        this.initModel.selectedOtherTitle = responseData.Title;
      }
    },

    setAddress(responseData: SARApplicant) {
      if (responseData.CurrentAddress == null) {
        this.newUser.CurrentAddress = {
          AddressLine1: "",
          AddressLine2: "",
          AddressLine3: "",
          Town: "",
          County: "",
          Country: "-1",
          PostCode: "",
        };
      } else if (
        responseData.CurrentAddress != null &&
        this.newUser.CurrentAddress.Country == ""
      ) {
        this.newUser.CurrentAddress.Country = "-1";
      }
      if (responseData.PreviousAddress == null) {
        this.newUser.PreviousAddress = {
          AddressLine1: "",
          AddressLine2: "",
          AddressLine3: "",
          Town: "",
          County: "",
          Country: "-1",
          PostCode: "",
        };
      } else {
        this.newUser.PrevAddress = "true";
        if (this.newUser.PreviousAddress.Country == "") {
          this.newUser.PreviousAddress.Country = "-1";
        }
      }
    },

    setStoreVisitDateTime(responseData: SARApplicant) {
      if (responseData.VisitDT !== null) {
        this.initModel.StoreDateofvisit = responseData.VisitDT;
        this.initModel.storeStartHourOfVisit = new Date(responseData.VisitDT)
          .getHours()
          .toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          });
        this.initModel.storeStartMinuteOfVisit = new Date(responseData.VisitDT)
          .getMinutes()
          .toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          });
        if (new Date(this.initModel.StoreDateofvisit).getFullYear() === 1900)
          this.initModel.StoreDateofvisit = "";
        if (this.initModel.storeStartHourOfVisit == "00")
          // Hour of visit set to blank
          this.initModel.storeStartHourOfVisit = -1;
        if (this.initModel.storeStartMinuteOfVisit == "00")
          // Minute of visit set to blank
          this.initModel.storeStartMinuteOfVisit = -1;
      } else {
        this.initModel.storeStartHourOfVisit = -1;
        this.initModel.storeStartMinuteOfVisit = -1;
      }

      if (this.isFormVersioningRequired) {
        if (responseData.VisitStartDT !== null) {
          this.initModel.StoreDateofvisit = responseData.VisitStartDT;
          this.initModel.storeStartHourOfVisit = new Date(
            responseData.VisitStartDT
          )
            .getHours()
            .toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            });
          this.initModel.storeStartMinuteOfVisit = new Date(
            responseData.VisitStartDT
          )
            .getMinutes()
            .toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            });
          if (new Date(this.initModel.StoreDateofvisit).getFullYear() === 1900)
            this.initModel.StoreDateofvisit = "";
          if (this.initModel.storeStartHourOfVisit == "00")
            // Hour of visit set to blank
            this.initModel.storeStartHourOfVisit = -1;
          if (this.initModel.storeStartMinuteOfVisit == "00")
            // Minute of visit set to blank
            this.initModel.storeStartMinuteOfVisit = -1;
        } else {
          this.initModel.storeStartHourOfVisit = -1;
          this.initModel.storeStartMinuteOfVisit = -1;
        }

        if (responseData.VisitEndDT !== null) {
          this.initModel.storeEndHourOfVisit = new Date(responseData.VisitEndDT)
            .getHours()
            .toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            });
          this.initModel.storeEndMinuteOfVisit = new Date(
            responseData.VisitEndDT
          )
            .getMinutes()
            .toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            });
          if (this.initModel.storeEndHourOfVisit == "00")
            // Hour of visit set to blank
            this.initModel.storeEndHourOfVisit = -1;
          if (this.initModel.storeEndMinuteOfVisit == "00")
            // Minute of visit set to blank
            this.initModel.storeEndMinuteOfVisit = -1;
        } else {
          this.initModel.storeEndHourOfVisit = -1;
          this.initModel.storeEndMinuteOfVisit = -1;
        }
      }
    },

    setDeclarations(responseData: SARApplicant) {
      this.initModel.Declaration = !responseData.SubmittedOnBehalfOfCustomer;
      this.initModel.ColleagueDeclaration =
        responseData.SubmittedOnBehalfOfCustomer;
    },

    initDownloadOptions() {
      this.consentHistoryDownloadOptions = {
        json_fields: {
          [this.translate("labeltext.consentHstryCustomerId")]: "CustomerId",
          [this.translate("labeltext.consentHstryOpcoName")]: "OpcoName",
          [this.translate("labeltext.consentHstryChannel")]: "Channel",
          [this.translate("labeltext.consentHstryPrivacyPolicyVerId")]:
            "PrivacyPolicyVerId",
          [this.translate("labeltext.consentHstryMarketingChannelVersion")]:
            "MarketingChannelVersion",
          [this.translate("labeltext.consentHstryOptIn")]: "OptIn",
          [this.translate("labeltext.consentHstrySubmitedDate")]:
            "ConsentHstrySubmitedDate",
          [this.translate("labeltext.consentHstrySource")]: "Source",
          [this.translate("labeltext.consentHstrySubmittedBy")]: "SubmittedBy",
        },
        json_meta: [
          [
            {
              key: "charset",
              value: "utf-8",
            },
          ],
        ],
      };
    },

    isComponentVersionUpdateRequired(componentName: any) {
      let updateRequired = false;
      let componentVersion = 1;

      if (
        this.isFormVersioningRequired &&
        this.isMappingExistsForCurrentBanner
      ) {
        this.initModel.ComponentMappings.forEach((value, index, array) => {
          if (
            value.ComponentName === componentName &&
            value.ComponentVersion > 1
          ) {
            updateRequired = true;
            componentVersion = value.ComponentVersion;
          }
        });

        if (updateRequired) {
          switch (componentName) {
            case ChildComponentSection.PartA:
              this.userContactDetailsVersion = componentVersion;
              break;
            case ChildComponentSection.PartB:
              this.userCustEmpRelationshipVersion = componentVersion;
              break;
            case ChildComponentSection.PartC:
              this.userCCTVDetailsVersion = componentVersion;
              break;
            case ChildComponentSection.PartE:
              this.userAttachmentDetailsVersion = componentVersion;
              break;
          }
        }
      }

      return updateRequired;
    },

    initConsentHistoryTable() {
      this.consentHistoryGridDetails = {
        columns: [
          "CustomerId",
          "OpcoName",
          "Channel",
          "PrivacyPolicyVerId",
          "MarketingChannelVersion",
          "OptIn",
          "ConsentHstrySubmitedDate",
          "Source",
          "SubmittedBy",
        ],
        options: {
          perPage: 10,
          filterable: false,
          perPageValues: [10, 20, 30, 40, 50],
          texts: {
            limit: this.translate("labeltext.reportRecords"),
            count: this.translate("labeltext.reportPaginationText"),
          },
          headings: {
            CustomerId: this.translate("labeltext.consentHstryCustomerId"),
            OpcoName: this.translate("labeltext.consentHstryOpcoName"),
            Channel: this.translate("labeltext.consentHstryChannel"),
            PrivacyPolicyVerId: this.translate(
              "labeltext.consentHstryPrivacyPolicyVerId"
            ),
            MarketingChannelVersion: this.translate(
              "labeltext.consentHstryMarketingChannelVersion"
            ),
            OptIn: this.translate("labeltext.consentHstryOptIn"),
            ConsentHstrySubmitedDate: this.translate(
              "labeltext.consentHstrySubmitedDate"
            ),
            Source: this.translate("labeltext.consentHstrySource"),
            SubmittedBy: this.translate("labeltext.consentHstrySubmittedBy"),
          },
          skin: "table-styled",
        },
      };
    },

    showErrorMessageForFileUpload(errorData: any) {
      this.initModel.modelErrors = errorData;
      this.scrollToTop();
    },

    getConsentHistory() {
      if (!this.newSARLegalMgmt.ConsentId.trim()) {
        this.initModel.modelErrors.push(
          this.translate("validationtext.validationconsenthistory")
        );
        this.scrollToTop();
      } else {
        let customerConsentModel: ICustomerConsentInit = {
          ConsentId: this.newSARLegalMgmt.ConsentId.trim(),
          TenantId: this.newSARLegalMgmt.TenantId,
          OpcoName: this.newSARLegalMgmt.OpcoName,
        };

        const service = new GSARManagementService();
        service
          .getConsentHistory(customerConsentModel)
          .then((response) => {
            if (
              response !== null &&
              response.data !== null &&
              response.data.length > 0
            ) {
              //populatetablegrid

              this.initModel.modelErrors = [];
              this.initConsentHistoryTable();
              this.initDownloadOptions();
              this.sarConsentHistryModel = response.data;

              this.enableConsentHstryGrid = true;
            } else if (
              response !== null &&
              response.data !== null &&
              response.data.length === 0
            ) {
              //no results returned
              this.enableConsentHstryGrid = false;
              this.logError(
                this.translate(
                  "validationtext.validationNoRecordsFoundConsentHstry"
                )
              );
            } else {
              //error!
              this.logError(
                this.translate("validationtext.validationConsentHistoryGrid")
              );
            }
          })
          .catch((error) => {
            if (error.response !== undefined && error.response.status === 401) {
              this.logError(this.translate("validationtext.validationNotAuth"));
            } else
              this.logError(
                this.translate("validationtext.validationConsentHistoryGrid")
              );
            this.scrollToTop();
          });
      }
    },

    validateInput() {
      this.initModel.modelErrors = [];
      if (!this.sarLegalMgmtNotes.Notes.trim())
        this.initModel.modelErrors.push(
          this.translate("validationtext.validationAddNotes")
        );

      if (!(this.initModel.modelErrors.length > 0)) return true;
      else this.scrollToTop();
    },

    downloadAttachments(attachmentDetails: AttachmentFileDetail) {
      let guidFileName = attachmentDetails.guidFileName;
      let originalFileName = attachmentDetails.originalFileName;

      const service = new GSARService();
      service
        .download(guidFileName, originalFileName)
        .then((response) => {
          var headers = response.headers;
          var blob = new Blob([response.data], {
            type: headers["content-type"],
          });
          var navigator: any = window.navigator;
          if (navigator && navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(blob, originalFileName);
          } else {
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = originalFileName;
            link.click();
          }
        })
        .catch((error) => {
          if (error.response.status == 401)
            this.unAuthorizedUser(
              this.translate("validationtext.validationNotAuth")
            );
          else if (error.response.status == 404)
            this.logError(
              this.translate("validationtext.validationDownloadFile")
            );
          else
            this.logError(
              this.translate("validationtext.validationRetreiveDetails")
            );

          this.scrollToTop();
        });
    },

    saveSarNotes() {
      this.validateInput();
      if (!this.initModel.modelErrors.length) {
        this.sarLegalMgmtNotes.SARId = this.initModel.SARID;
        this.sarLegalMgmtNotes.NotesCreatedDate = this.formatDate(new Date());

        const service = new GSARManagementService();
        service
          .postSARMgmtNotes(this.sarLegalMgmtNotes)
          .then((response: any) => {
            if (
              response.data.SubCode === undefined &&
              response.data.length > 0
            ) {
              this.newSARLegalMgmt.HistoricalNotes = response.data;
              this.initModel.isPostSuccessful = true;
              this.sarLegalMgmtNotes.Notes = "";
            } else if (
              response.data.SubCode !== undefined &&
              response.data.SubCode != 4011 &&
              response.data.SubCode != 4012
            ) {
              this.initModel.isPostSuccessful = true;
              this.sarLegalMgmtNotes.Notes = "";
            } else {
              this.logError(
                this.translate("validationtext.validationAddNotesError")
              );
            }
          })
          .catch((error: any) => {
            if (error.response !== undefined && error.response.status === 401) {
              this.logError(this.translate("validationtext.validationNotAuth"));
            } else
              this.logError(
                this.translate("validationtext.validationSubmitForm")
              );
            this.scrollToTop();
          });
      }
    },

    saveSarMgmtChanges() {
      this.newSARLegalMgmt.SARId = this.initModel.SARID;
      this.newSARLegalMgmt.ModifiedDT = this.formatDate(new Date());
      this.newSARLegalMgmt.ActualStartDT = this.getDateFromDateTime(
        this.newSARLegalMgmt.ActualStartDT
      );
      this.newSARLegalMgmt.ActualCompletionDT = this.getDateFromDateTime(
        this.newSARLegalMgmt.ActualCompletionDT
      );
      this.newSARLegalMgmt.ExpectedCompletionDT = this.getDateFromDateTime(
        this.newSARLegalMgmt.ExpectedCompletionDT
      );

      const service = new GSARManagementService();
      service
        .post(this.newSARLegalMgmt)
        .then((response: any) => {
          if (response.data.SubCode != 4011 && response.data.SubCode != 4012) {
            this.initModel.isPostSuccessful = true;
            this.initModel.modelErrors = [];
          } else {
            this.logError(
              this.translate("validationtext.validationSubmitForm")
            );
            this.scrollToTop();
          }
        })
        .catch((error: any) => {
          if (error.response !== undefined && error.response.status === 401) {
            this.logError(this.translate("validationtext.validationNotAuth"));
          } else
            this.logError(
              this.translate("validationtext.validationSubmitForm")
            );
          this.scrollToTop();
        });
    },

    scrollToTop() {
      VueScrollTo.scrollTo("#top", 0);
    },
  },
});
</script>
