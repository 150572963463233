import { DateTime } from 'luxon';

export class SARLegalMgmtModel {
    SARId: string;
    RequestStatusID: string;
    Assignee: string;
    RequestArrivalTypeID: string;
    ActualStartDT: string | null;
    ExpectedCompletionDT: string | null;
    ActualCompletionDT: string | null;
    SARCreatedDT: string | null;
    ModifiedDT: string | null;
    ModifiedBy: string;
    Notes: string;
    SARManagementID: string;
    HistoricalNotes: any;
    CurrentAttachments: any[];
    Tasks: any;
    ConsentId: string;
    TenantId: string;
    OpcoName: string;

    constructor() {
        this.SARId = "";
        this.RequestStatusID = '';
        this.Assignee = '';
        this.RequestArrivalTypeID = '';
        this.ActualStartDT = null;
        this.ExpectedCompletionDT = null;
        this.ActualCompletionDT = null;
        this.SARCreatedDT = '';
        this.ModifiedDT = '';
        this.ModifiedBy = '';
        this.Notes = '';
        this.SARManagementID = '';
        this.HistoricalNotes = '';
        this.CurrentAttachments = [];
        this.Tasks = '';
        this.ConsentId = '';
        this.TenantId = '';
        this.OpcoName = ''
    }
}
