export default {
    validationtext: {
        validationHeading:'Corrija los siguientes errores',
        validationHeadingV2:'Por favor corrija las siguientes anomalías',
        validationReqType: 'Especifique al menos un Tipo de Solicitud',
        validationReqTypeV2: 'Elija al menos un tipo de solicitud de las opciones proporcionadas.',
        validationTitle: 'Especifique el Título',
        validationTitleV2: 'Por favor completa tu “Civilidad”',
        validationOtherTitle: 'Defina "Otro Título"',
        validationForename: 'Indique su nombre',
        validationForenameV2: 'Por favor complete su “Nombre”',
        validationSurname: 'Indique su apellido',
        validationSurnameV2: 'Por favor complete su “Apellido”',
        validationEmailAddress: 'Indique su correo electrónico',
        validationEmailAddressV2: 'Por favor, introduzca su dirección de correo electrónico',
        validationCCTV: 'Confirme si se solicita metraje de CCTV',
        validationValidEmailAdd: 'Indique una dirección de correo electrónico válida',
        validationDocType:'Seleccione un Tipo de Documento antes de subir los documentos',
        validationSelectFile: 'Seleccione una Carpeta antes de subir los archivos',
        validationLoadingForm: 'Se ha producido un error al cargarse el formulario. Por favor inténtelo de nuevo en unos minutos',
        validationNotAuth: 'No tiene la autorización necesaria para poder visualizar esta página',
        validationSubmitForm: 'Se ha producido un error al enviarse el formulario. Por favor inténtelo de nuevo en unos minutos',
        validationRetreiveDetails: 'Se ha producido un error al recuperar los detalles. Por favor inténtelo de nuevo en unos minutos',
        validationDownloadFile: 'Se ha producido un error al descargarse el archivo. Por favor inténtelo de nuevo en unos minutos',
        validation404: "El recurso que solicitaste no puede encontrarse",
        validationMaxFileSize: 'El tamaño del archivo excede el límite máximo {filesize}',
        validationFileUploadFailed: 'Se ha producido un error al subirse los archivos. Por favor inténtalo de nuevo más tarde',
        validationAllowedFileTypes: 'Los tipos de archivos que pueden subirse son {allowedFileExtensions}. Por favor sube un archivo que sea compatible',
        validationStartDate: "La fecha de inicio no puede ser mayor que la de hoy",
        validationEndDate: "La fecha de fin no puede ser mayor que la fecha de hoy",
        validationStoreVisitDate: "La fecha de visita no puede ser mayor",
        validationEndDateLessThanStartDate: "La fecha final no puede ser menor que la fecha de Inicio",
    
        validationNoRecordsFound: "No se han encontrado registros para los criterios de búsqueda solicitados",
        validationSearchFailed: 'Se han producido algunos errores. Por favor inténtelo de nuevo',
        
        validationAddNotes: "Por favor, introduzca una nota",
        validationAddNotesError:"Algunos errores al añadir notas. Por favor, inténtelo de nuevo más tarde",
        validationDocTitle: "Por favor, introduzca un título de documento",
    
        validationAddTaskDescription: "La descripción no puede estar en blanco",
        validationAddTaskAssignee: "Cesionario no puede estar en blanco",
        validationAddTaskError:"Ocurrieron algunos errores mientras que la adición de tareas. Por favor, inténtelo de nuevo más tarde",
    
        validationconsenthistory: "Por favor, introduzca un identificador de consentimiento es posible recuperar detalles",
        validationConsentHistoryGrid: "Algunos se han producido errores, mientras que al recuperar la historia consentimiento. Por favor, inténtelo de nuevo más tarde.",
        validationNoRecordsFoundConsentHstry: 'No se han encontrado para la Identificación de usuario ingresado registros'
    },
    labeltext: {
        dataProtectionAct: 'Protección de Datos',
        dataProtectionActV2: 'Reglamento general de protección de datos',
        dataSAR: 'Formulario para presentar una solicitud sobre datos personales',
        dataSARV2: 'Formulario de solicitud de acceso a los datos',
        completedByWarning:'ESTE FORMULARIO DEBE COMPLETARSE POR EL TITULAR DE LOS DATOS PERSONALES OBJETO DE LA SOLICITUD',
        completedByWarningV2:'Este formulario debe rellenarlo la persona que desee acceder a sus propios datos personales',
        typeOfRequest: '*Tipo de Solicitud',
        typeOfRequestV2: 'Tipo de Solicitud',
        requestDataAccess: 'Solicitud de acceso',
        requestDataAccessV2: 'Derecho a acceder a los datos',
        requestDataDelete: 'Solicitud de eliminación/supresión',
        requestDataDeleteV2: 'Derecho de supresión (derecho al olvido)',
        requestDataRect: 'Solicitud de rectificación',
        requestDataRectV2: 'Derecho de rectificación',
        requestDataObject: 'Solicitud para oponerse al tratamiento',
        requestDataObjectV2: 'Derecho de objeción',
        partAHeadingText: 'Parte A: Detalles del interesado',
        partAHeadingTextV2: 'Sección A: Información de contacto',
        partABodyText: 'Complete el siguiente formulario. La información que proporcione se usará por {brandPlcName1} para tramitar y gestionar su solicitud. Puede ser que {brandPlcName2} resuelva su solicitud con ayuda de un tercero, en cuyo caso puede ser que se revelen los datos imprescindibles para ello',
        partABodyTextV2: 'Completa este formulario. {brandPlcName1} utilizará la información que proporciones para procesar tu solicitud. Es posible que {brandPlcName2} necesite recurrir a un subcontratista para esta solicitud y, por tanto, compartir tus datos personales con dicho subcontratista a fin de procesarla. Una vez completada tu solicitud, tus datos se conservarán durante un año y, después, se eliminarán.',
        title: '*Título',
        titleV2: '*Tratamiento',
        titleDefaultValue: 'Por favor selecciona un título',
        titleDefaultValueV2: 'Selecciona cómo quieres que nos dirijamos a ti',
        otherTitleLabel:'*Si ha seleccionado “Otro”, especifique',
        otherTitlePlc: 'Otro título',
        surname: '*Apellido',
        surnameV2: '*Apellido',
        forename: '*Nombre',
        forenameV2: '*Nombre',
        currentAddress: 'Dirección actual',
        currentAddressV2: 'Dirección',
        addline1Plc:'Dirección 2',
        addLine2Plc: 'Dirección 3',
        addLine3Plc: 'Dirección 4',
        townPlc: 'Ciudad',
        countyPlc: 'Población',
        countyPlcV2: 'Departamento',
        countryDefaultValue: 'Por favor selecciona un País',
        countryDefaultValueV2: 'selecciona un País',
        postCode: 'Código postal',
        postCodeV2: 'Código postal',
        prevAddressWarning: 'Quiere incluir alguna otra dirección que contenga otros datos personales?',
        prevAddressWarningV2: 'Te gustaría añadir alguna otra dirección en la que puedan haberse recopilado tus datos personales?',
        prevAddress: 'Dirección anterior',
        prevAddressV2: 'Otra dirección',
        emailAddress: '*Dirección Email',
        emailAddressV2: '*Dirección de correo electrónico',
        contactNumber: 'Número de contacto',
        contactNumberV2: 'Número de teléfono',
        custEmpRel:'Indique tu relación con Brico Depôt',
        relationshipDetailsText: 'Indica cuál es la relación entre el titular de los datos personales y {brandPlcName1}, y proporciona el resto de la información solicitada.',
        relationshipDetailsTextV2: 'Indica cuál es la relación entre el titular de los datos personales y {brandPlcName1}, y proporciona el resto de la información solicitada.',
        customer:'Cliente',
        employee:'Trabajador (Actual o Extrabajador)',
        employeeV2:'empleado (si has sido o eres empleado de {brandPlcName})',
        custNo:'Número Cliente',
        custNoV2:'Si es cliente: Número de cliente',
        orderNo: 'Número de Pedido',
        complainRef:' Referencia de la Reclamación',
        complainRefV2:' Referencia de queja',
        empNo: 'Número trabajador',
        empNoV2: 'Si es empleado: Número de registro',
        startDate: 'Fecha inicio',
        startDateV2: 'Fecha de entrada',
        endDate:'Fecha final (si aplica)',
        endDateV2:'Fecha de lanzamiento (si es relevante)',
        storeHeadOff: 'Tienda',
        storeHeadOffV2: 'Último puesto ocupado',
        partBHeaderText:'Parte B: Detalles de la solicitud (para solicitudes relacionadas con cámaras de videovigilancia complete la Parte C)',
        reqDetailsText:'Proporciona cualquier otro dato que sea relevante para tu solicitud:',
        reqDetailsTextV2:'Proporciona cualquier otro dato que sea relevante para tu solicitud:',
        reqDetailsPlc: 'Detalles de la solicitud',
        reqDetailsPlcV2: 'Detalles del pedido',
        partCHeaderText: 'Parte C: Solicitudes relacionadas con cámaras de videovigilancia',
        cctvfootageReq: '*Metraje de CCTV',
        yesText: 'sí',
        noText: 'No',
        yesStoreVehicle: 'Si ha respondido sí, indique en relación con qué es el metraje',
        store: 'Tienda',
        vehicle: 'Vehículo',
        storeConcerned: 'Tienda implicada',
        dateOfVisit:'Fecha en que se produjo la visita',
        timeOfVisit: 'Tiempo de visita (24 hrs)',
        hour: 'Hora',
        minute: 'Minuto',
        clothAtVisit: 'Ropa que llevaba en el momento de la visita',
        vehicleMake:'Marca del vehículo',
        model: 'Modelo',
        colour: 'Color',
        regNo: 'Número de registro',
        attachments: 'Adjuntos',
        partDHeadingText: 'Parte D: Completando el formulario',
        partDBodyText: 'Salvo que se acuerde otra cosa, junto con su solicitud debe presentar copia de al menos dos documentos oficiales de los que resulte claramente su nombre y su dirección actual para que {brandPlcName1} pueda asegurarse de su identidad. Al menos uno de estos documentos deberá incluir una fotografía suya. Ejemplos de documentos aptos para demostrar su identidad: DNI, Carné de conducir, pasaporte',
        authThirdParty:'Si ha autorizado a una tercera persona para interponer la solicitud en su nombre (un representante) deberá proporcionar también, junto con el resto de documentos, la copia del documento en el que se concede la autorización',
        authThirdPartyV2:'Si autorizas a una tercera persona (por ejemplo, un abogado) para que realice la solicitud en tu nombre, esta primero debe enviarnos una copia de la autorización.',
        docTypeDefaultValue: 'Por favor selecciona un tipo de documento',
        docTypeDefaultValueV2: 'Selecciona el tipo de documento',
        idProof: 'Identificación',
        idProofV2: 'Prueba de identidad',
        addProof: 'Evidencias de la dirección física',
        addProofV2: '',
        authDoc:'Documento de autorización',
        authDocV2:'Autorización',
        otherDoc:'Otro',
        otherDocV2:'Otros',
        chooseFile: 'Escoje el archivo',
        upload: 'Sube el archivo',
        declaration: 'Declaración',
        declarationText: 'DECLARO QUE LA INFORMACIÓN QUE PROPORCIONO EN ESTE FORMULARIO ES CORRECTA Y QUE SOY EL TITULAR DE LOS DATOS PERSONALES AFECTADOS. ENTIENDO QUE SI NO COMPLETO ESTE FORMULARIO DEBIDAMENTE PUEDE SER QUE MI SOLCITUD SE DEMORE',
        declarationTextV2: 'Corroboro que la información proporcionada en este formulario es correcta y que soy el titular de los datos que aparecen en esta solicitud. Se me ha informado de que el procesamiento de la solicitud puede demorarse si el formulario está incompleto o contiene información incorrecta.',
        declarationHeader1: 'Declaración ',
        declarationHeader2:'(selecciona la declaración que se corresponda con tu solicitud): ',
        declarationHeader2V2:'(selecciona la declaración que se corresponda con tu solicitud): ',
        declarationSubHeader1: 'Solo para uso interno: ',
        declarationType1: 'Si eres la persona a la que se refieren los datos personales:',
        declarationType1V2: 'Si eres la persona a la que se refieren los datos personales:',
        declarationType2: 'Si trabajas en {brandPlcName1}, confirma lo siguiente en nombre del titular de los datos personales: ',
        declarationType2V2: 'Si trabajas en {brandPlcName1}, confirma lo siguiente en nombre del titular de los datos personales: ',
        declarationtext2: 'La información proporcionada en este formulario de solicitud es correcta y coincide con la información que ha ofrecido el titular de los datos personales. La persona a la que hace referencia este formulario entiende que el proceso podría demorarse si la información proporcionada no es suficiente para procesar correctamente esta solicitud.',
        declarationtext2V2: 'La información proporcionada en este formulario de solicitud es correcta y coincide con la información que ha ofrecido el titular de los datos personales. La persona a la que hace referencia este formulario entiende que el proceso podría demorarse si la información proporcionada no es suficiente para procesar correctamente esta solicitud.',
        cancel: 'Cancelar',
        print: 'Imprimir',
        submit: 'Enviar',
        ackText: 'Su solicitud se ha enviado con éxito',
        reqID: 'Solicitud de ID#:',
        makeNewReq: 'Hacer otra solicitud',
        fileUploadSuccessful: 'Se ha subido el archivo correctamente',
        selectDatePlc: 'Seleccione la fecha',
        selectDatePlcV2: 'Selecciona una fecha',
        addtionalRequestTypeText: 'Para cualquier otra solicitud, or favor describe la solicitud en la Parte B del formulario',
        listUploadedFiles: 'Subir archivos:',
        Mr: 'Sr',
        MrV2: 'Sr',
        Mrs:'Sra',
        MrsV2:'Sra',
        Miss: '',
        MissV2: 'Srta',
        Ms: '',
        MsV2: '',
        OtherTitle: 'Otro',
        OtherTitleV2: '',
        partA: 'Parte A: ',
        partB: 'Parte B: ',
        partBV2: 'Sección B: ',
        partC: 'Parte C: ',
        partD: 'Parte D: ',
        cctvHeader:'Solicitudes relacionadas con cámaras de videovigilancia',
        formCompletionHeader: 'Completando el formulario',
        formCompletionHeaderV2: 'Añade un archivo adjunto, si procede',
        formCompletionBody: 'Con el fin de garantizar la seguridad de tus datos, y en función de la naturaleza y el contenido de tu solicitud, es posible que te pidamos más información para poder identificarte.',
        formCompletionBodyV2: 'Con el fin de garantizar la seguridad de tus datos, y en función de la naturaleza y el contenido de tu solicitud, es posible que te pidamos más información para poder identificarte.',
        reportRequestID: 'Solicitud de ID',
        reportEmailID: 'Correo electrónico',
        reportLastName: 'Apellido del consumidor',
        reportBrandName: 'Nombre de la marca',
        reportStartDate: 'Fecha de inicio',
        reportEndDate: 'Fecha de finalización',
        reportStatus: 'Estado',
        reportBrandNameDefaultValue: 'Selecciona marca',
        reportStatusDefaultValue: 'Selecciona Estado',
        
        reportHeaderName: 'NOMBRE',
        reportHeaderReceivedType: 'TIPO DE RECIBIDO',
        reportHeaderReceivedDate: 'RECIBIDO DT',
        reportHeaderAccessData: 'DATOS DE ACCESO',
        reportHeaderDataRect: 'RECTIFICACIÓN DE DATOS',
        reportHeaderDataDel: 'La eliminación de datos',
        reportHeaderObject: 'Derecho de oposición',
        reportHeaderCompletedDate: 'COMPLETED DT',
        
        reportSearch: 'Buscar',
        reportDownload: 'Descargar',
        reportRecords: 'Archivos',
        reportsYes:'Y',
        reportsNo:'N',
        reportsRecords:'Showing xxx to xxx of records',
        reportPaginationText:'Mostrando {from} a {to} de {count} registros | {count} registros | 1 registro',
    
    
        originalSARData: 'Los datos originales SAR es la siguiente',
    
        NavigationHome: 'Casa',
        NavigationReports: 'Informes',
    
        requestStatus: 'Estado de la solicitud',
        assignTo :'Atribuir a',
        requestArrivalType:'Tipo de solicitud de llegada',
        SARSubmittedDate:'Fecha en la que se envió la solicitud SAR',
        actualStartDate:'Fecha de inicio',
        expectedCompletionDate:'Fecha prevista de terminación',
        actualCompletionDate:'Fecha actual de terminación',
        notes:'Notas',
        historicalNotes:'Notas históricas',
    
        Submitted:'Enviado',
        InProgress:'En progreso',
        OnHold:'En espera',
        ResponseSent:'La respuesta ha sido enviada',
        Complete:'Complete',
        Open: 'Abierto',
    
        Web:'Web',
        Phone:'Teléfono',
        SocialMedia:'Redes sociales',
        Post:'Comentario',
        Email:'Correo electrónico',
        
        doctype: 'Tipo de documento',
        selectUpload: 'Seleccione y suba un fichero',
        selectUploadV2: 'Selecciona y sube un archivo',
        noFile : 'Ningún fichero seleccionado',
    
        jan :	'enero',
        feb :	'febrero', 
        mar :	'marzo',
        apr :	'abril',
        may :	'Mayo',
        jun :	'junio',
        jul :	'julio',
        aug :	'agosto',
        sep :	'septiembre',
        oct :	'octubre',
        nov :	'noviembre',
        dec :	'diciembre',
        sun :	'domingo',
        mon :	'lunes',
        tue :	'martes',
        wed :	'miércoles',
        thu :	'jueves',
        fri :	'viernes',
        sat :	'sábado',
        
        tooltipAccessRequest: "Solicitud de acceso: Salvo en algunos supuestos, usted tiene derecho a obtener una copia de sus datos personales recabados por {brandPlcName1} Con tal de preservar la privacidad del resto de personas, la información se editará para que no se pueda identificar a nadie más, salvo que tengamos el consentimiento de estas personas o que, dadas las circunstancias particulares, sea razonable compartir esta información con usted.",
        tooltipDataDeletion: "Solicitud para la supresión de datos personales: Puede ser que usted tenga derecho a que se supriman sus datos personales que {brandPlcName1} recabó. De todas formas {brandPlcName2} no podrá suprimir sus datos cuando esté obligado por ley a conservarlos o cuando los datos sean necesarios en relación a alguna reclamación legal, entre otros supuestos previstos en la ley.",
        tooltipDataObject: "Solicitud para oponerse al tratamiento de datos: usted tiene derecho a oponerse al tratamiento de sus datos personales por {brandPlcName1} para fines relacionados con marketing. Puede ser que tenga derecho a oponerse al tratamiento de sus datos personales para otros fines, aunque existen supuestos en los que no se podrá dar cumplimiento a su solicitud, por ejemplo si {brandPlcName2} necesita los datos personales en relación a alguna reclamación legal u otros supuestos previstos en la ley.",
        tooltipDataRectification: "Solicitud para la rectificación de los datos personales: Usted tiene derecho a que se hagan las correcciones oportunas en los datos personales que trate {brandPlcName1} si son incorrectos. Además, también puede solicitar que se completen los datos en la base de datos de {brandPlcName2} en el caso que estén incompletos.",
        tooltipAccessRequestV2: "La política de protección de datos le ayuda a comprender cómo se utilizan sus datos.",
        tooltipDataDeletionV2: "Si desea realizar nuevas compras después de haber sido eliminado, deberá crear una nueva cuenta.",
        tooltipDataRectificationV2: "Te has mudado o cambiado tu número de teléfono, actualiza tu información",
        tooltipDataObjectV2: "En varios casos será necesario resaltar los motivos de su situación particular que justifican su oposición (excepto en materia de prospección donde no se requiere justificación)",
        dataAccessRequestHelpfulInfoV2: 'El derecho a acceder a los datos te permite recibirlos y saber si se están utilizando. <br />Es posible que se restrinja el acceso a cierta información en casos concretos con el fin de evitar que esto afecte a los derechos de otras personas, a los secretos comerciales o a la propiedad intelectual.<br /><strong>Si quieres consultar la información relacionada con tus compras anteriores o los detalles de tu cuenta, puedes hacerlo en cualquier momento. Para ello, solo tienes que iniciar sesión en tu cuenta en nuestro sitio web y dirigirte a la sección "Mi cuenta".</strong>',
        dataDeleteRequestHelpfulInfoV2: 'Tienes derecho a pedirnos que eliminemos tus datos personales. La eliminación puede ser parcial o rechazarse en casos concretos.<br />Por ejemplo, si tenemos la obligación legal de conservar la información o con el fin de preservar nuestra capacidad de ejercer nuestros derechos ante un tribunal.<br /><strong>Si quieres eliminar la información de pago o actualizar tus preferencias de marketing, puedes hacerlo en cualquier momento. Para ello, solo tienes que iniciar sesión en tu cuenta en nuestro sitio web y dirigirte a la sección "Mi cuenta".</strong>',
        dataRectifyRequestHelpfulInfoV2:'El derecho de rectificación te permite corregir o actualizar tus datos personales.<br /><strong>Si quieres actualizar tu información personal, por ejemplo, con una nueva dirección, por un cambio de nombre o para incluir nueva información de pago, puedes hacerlo en cualquier momento. Para ello, solo tienes que iniciar sesión en tu cuenta en nuestro sitio web y dirigirte a la sección "Mi cuenta".</strong>',
        dataProcessRequestHelpfulInfoV2:'El derecho de objeción te permite oponerte a que se utilicen tus datos para un propósito específico (por ejemplo, marketing directo, creación de perfiles, etc.).<br /><strong>Puedes optar por no recibir comunicaciones de marketing en cualquier momento. Para ello, solo tienes que iniciar sesión en tu cuenta en nuestro sitio web y dirigirte a la sección "Mi cuenta", o hacer clic en el enlace que aparece al final de cada correo electrónico o mensaje SMS para cancelar tu suscripción.</strong>',
        sarmgmtAddNote: "Añadir notas",
        sarmgmtAddNotesButton: "Añadir",
        sarmgmtCancelNotes: "Cancelar",
        sarmgmtDocTitle: 'Titulo del documento',
        sarmgmtUploadDoc: 'Cargar un documento',
        sarmgmtattachmentUploadedBy: 'Subida por {uploadedby} en {timestamp}',
    
        sarmgmtAddTask: "Añadir Tareas",
        sarmgmtViewTask: "vista de tareas",	
        sarmgmtCancelTasks: "Ocultar tareas",
        TaskID:"Tarea #",
        Description:"Descripción",
        Assignee:"Cesionario",
        CreatedDT:"Creado",
        CompletedDT:"Terminado",
        Comment:"Comentario",
        taskStatus: "Estado",
        
        taskDeleteMessage: "Por favor confirme que desea eliminar la tarea",
        taskDelete: "Borrar",
        taskSave: 'Salvar',
        sarmgmtAddNotes: 'añadido un comentario',
    
        consentCustomerId: "Identificación del cliente",
        consentMarketingTextHeader: "El consentimiento del cliente de marketing: ",
        consentTooltipCustRef: "Identificación del cliente: Se refiere a la identificación contra la cual el consentimiento del cliente se almacena en el repositorio GDPR Consentimiento. Este id puede ser un número de referencia, número de lealtad, correo electrónico o número de teléfono único. Por favor refiérase a la orientación sobre la identificación del cliente para GDPR repositorio para su respectivo mercado.",
    
        consentHstryCustomerId : 'Identificación del cliente',
        consentHstryOpcoName: 'Nombre de la marca',
        consentHstryChannel: 'Canal',
        consentHstryPrivacyPolicyVerId: 'Política de privacidad Ver Id',
        consentHstryMarketingChannelVersion: 'Versión de marketing de canal',
        consentHstryOptIn: 'Optar en',
        consentHstrySubmitedDate: 'Inscrito en la Fecha',
        consentHstrySource: 'Fuente',
        consentHstrySubmittedBy: 'Transcrito por',
        consentHstryfileDownloadName: 'El consentimiento del cliente Report.csv',
        consentHistoryButton: "Obtener el historial de consentimiento",
        taskEdit: 'Editar'
    },    
}