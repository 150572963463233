import api, { AxiosResponse } from "axios";

export class AuthService {
    public async getCustomerAuthToken() : Promise<AxiosResponse<string>> {
        const baseUrl: string | undefined = process.env.VUE_APP_AUTH_URL;
        return api.get<string>(`${baseUrl}api/token`);
    }

    public async getPermanentToken(tempToken: string) : Promise<AxiosResponse<string>> {
        const baseUrl: string | undefined = process.env.VUE_APP_AUTH_URL;
        return api.get<string>(`${baseUrl}api/token/get?queryParam=${tempToken}`);
    }
}