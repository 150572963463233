import api, { AxiosResponse } from "axios";
import { BaseService } from './base.service';
import { IReportInitModel, IReportViewDetails } from "@/models/service-request-response-model";
import { SARReportModel } from "@/models/SARReportModel";

export class GSARReportViewService extends BaseService {
  public getReportInitialisation() : Promise<AxiosResponse<IReportInitModel>> {
      return api.get<IReportInitModel>(`ReportInit`, this.getLegalAuthenticationHeader());
  }
  public getReportViewDetails(body: SARReportModel) : Promise<AxiosResponse<IReportViewDetails>> {
    return api.post<IReportViewDetails>('ReportViewDetails', body, this.getLegalAuthenticationHeader());
  }
}