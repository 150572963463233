<template>
  <div class="gd-attachment mb-gutter">
    <div class="mb-gutter">
      <h3>{{ $t("labeltext.partDHeadingText") }}</h3>
    </div>
    <div class="gd-file-list font-small">
      <ul class="list-styled">
        <li v-for="(doc, index) in newUser.DocumentList" :key="index">
          <img
            src="./../../../assets/images/attachment.png"
            class="legal__attachment"
          />
          <a
            @click="downloadAttachments(doc.Guid, doc.FileLocation)"
            class="legal__attachmentlist"
          >
            <span class="legal__attachmentlist"
              >{{ getSelectedDocName(doc.DocumentTypeId) }} -
            </span>
            {{ doc.FileLocation }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SARAttachmentsMixin from "@/mixins/SARAttachments.mixin";

export default defineComponent({
  name: "SARAttachments",
  mixins: [SARAttachmentsMixin],
});
</script>