export default {
    validationtext: {
        validationHeading: 'Please correct the following error(s):',
        validationReqType: 'Please provide at least one Request Type',
        validationReqTypeV2: 'Please choose at least one type of request from the choices provided',
        validationTitle: 'Please provide a value for Title',
        validationTitleV2: 'Please complete your “Title”',
        validationOtherTitle: 'Please provide a value for Other Title',
        validationForename: 'Please provide a value for Forename',
        validationForenameV2: 'Please complete your “First Name”',
        validationSurname: 'Please provide a value for Surname/Family Name',
        validationSurnameV2: 'Please complete your “Name”',
        validationEmailAddress: 'Please provide an Email Address',
        validationEmailAddressV2: 'Please enter your email address',
        validationCCTV: 'Please confirm if CCTV footage is required',
        validationValidEmailAdd: 'Please provide a valid email address',
        validationDocType: 'Please select a Document Type before uploading',
        validationSelectFile: 'Please select a File before uploading',
        validationLoadingForm: 'An error has occurred whilst loading this form. Please try again later',
        validationNotAuth: 'You are not authorized to view this page',
        validationSubmitForm: 'Some errors occurred while submitting the form. Please try again later',
        validationRetreiveDetails: 'Some errors occurred while retrieving the details. Please try again later',
        validationDownloadFile: 'Some error occurred while downloading the file. Please try again later',
        validation404: "The resource you requested could not be found",
        validationMaxFileSize: 'File size exceeds maximum allowable limit of {filesize}',
        validationFileUploadFailed: 'Some errors occurred while uploading the file. Please try again later',
        validationAllowedFileTypes: 'Allowable file types for upload are {allowedFileExtensions}. Please upload a file with compatible file extension',
        validationStartDate: "Start Date cannot be greater than today's date",
        validationEndDate: "End Date cannot be greater than today's date",
        validationStoreVisitDate: "Date of visit cannot be greater than today's date",
        validationEndDateLessThanStartDate: "End Date Cannot be less than Start Date",

        validationNoRecordsFound: "No records found for the entered search criteria",
        validationSearchFailed: 'Some errors occurred. Please try again later',

        validationAddNotes: "Please enter a note",
        validationAddNotesError: "Some errors occurred while adding Notes. Please try again later",

        validationDocTitle: "Please enter a Document Title",

        validationAddTaskDescription: "Description cannot be blank",
        validationAddTaskAssignee: "Assignee cannot be blank",
        validationAddTaskError: "Some errors occurred while adding Task. Please try again later",

        validationconsenthistory: "Please enter a consent id to retreive details",
        validationConsentHistoryGrid: "Some errors occured while retreiving consent history. Please try again later.",
        validationNoRecordsFoundConsentHstry: 'No records found for the entered customer reference id'
    },
    labeltext: {
        dataProtectionAct: ' ',
        dataProtectionActV2: 'General Regulations for the Protection of Personal Data',
        dataSAR: 'Data Subject Request Form',
        dataSARV2: 'Data access request form',
        completedByWarning: 'THIS FORM MUST BE COMPLETED BY THE PERSON WHO IS THE SUBJECT OF THE PERSONAL DATA',
        completedByWarningV2: 'This form must be completed by the person who wishes to access their own personal data',
        typeOfRequest: '*Type of request',
        typeOfRequestV2: 'Type of request',
        requestDataAccess: 'Request to access data',
        requestDataAccessV2: 'Right of access to data',
        requestDataDelete: 'Request for data deletion/erasure',
        requestDataDeleteV2: 'Right to erasure (right to be forgotten)',
        requestDataRect: 'Request for data rectification',
        requestDataRectV2: 'Right to rectification',
        requestDataObject: 'Request for right to object to data processing',
        requestDataObjectV2: 'Right to object',
        partAHeadingText: 'Part A: Your details',
        partAHeadingTextV2: 'Part A: Contact information',
        partABodyText: 'Please complete the form below. The information you provide will be used by {brandPlcName1} in order to process and administer this request. {brandPlcName2} may use a third party to process your request and, as a result, may need to disclose your personal data to that third party.',
        partABodyTextV2: 'Please fill out this form. The information you provide will be used by {brandPlcName1} to process your request. {brandPlcName2} may need to use a subcontractor for this request and thus communicate your personal data to said subcontractor in order to fully complete your request. Once your request is closed, your data will be retained for a period of one year, after which it will be deleted.',
        title: '*Title',
        titleDefaultValue: 'Please select a title',
        titleDefaultValueV2: 'Please select your "Title"',
        otherTitleLabel: '*If Other, please specify',
        otherTitlePlc: 'Other Title',
        surname: '*Surname/Family Name',
        surnameV2: '*Last name',
        forename: '*Forename',
        forenameV2: '*First name',
        currentAddress: 'Current Address',
        currentAddressV2: 'Address',
        addline1Plc: 'Address Line 1',
        addLine2Plc: 'Address Line 2',
        addLine3Plc: 'Address Line 3',
        townPlc: 'Town',
        countyPlc: 'County',
        countyPlcV2: 'Department',
        countryDefaultValue: 'Please select a Country',
        postCode: 'Post Code',
        postCodeV2: 'Postal code',
        eireCode: 'Eire Code',
        prevAddressWarning: 'Would you like to include any previous address under which any other personal data may be held?',
        prevAddressWarningV2: 'Would you like to add another address under which your personal data may have been collected?',
        prevAddress: 'Previous Address',
        emailAddress: '*Email Address',
        contactNumber: 'Contact Number',
        contactNumberV2: 'Phone number',
        custEmpRel: 'Please indicate your relationship',
        relationshipDetailsText: 'Please indicate the relationship the subject of the personal data has with {brandPlcName1} and provide the further information requested.',
        relationshipDetailsTextV2: 'Please indicate the relationship between the personal data subject and {brandPlcName1}, and provide the additional information requested.',
        customer: 'Customer',
        employee: 'Employee (Includes Current & Ex-employee)',
        employeeV2: 'employee (if you have been, or are, an employee of {brandPlcName})',
        custNo: 'Customer Number',
        custNoV2: 'If customer: Customer number',
        orderNo: 'Order Number',
        complainRef: ' Complaint Ref',
        complainRefV2: ' Claim reference',
        empNo: 'Employee Number',
        empNoV2: 'If employee: Registration number',
        startDate: 'Start Date',
        startDateV2: 'Date of entry',
        endDate: 'End date (if applicable)',
        endDateV2: 'Release date (if relevant)',
        storeHeadOff: 'Store/Head Office function',
        storeHeadOffV2: 'Last position held',
        partBHeaderText: 'Part B: Please provide details of your request below (for CCTV please complete Part C)',
        reqDetailsText: 'Please provide any further relevant details regarding your request:',
        reqDetailsTextV2: 'Please provide any other relevant details regarding your request:',
        reqDetailsPlc: 'Details of request',
        reqDetailsPlcV2: 'Request details',
        partCHeaderText: 'Part C: CCTV Footage',
        cctvfootageReq: '*CCTV footage required',
        yesText: 'Yes',
        noText: 'No',
        yesStoreVehicle: 'If yes, this is related to ',
        store: 'Store',
        vehicle: 'Vehicle',
        carPark: 'Car Park',
        storeConcerned: 'Store Concerned',
        storePostcode: 'Store Postcode',
        storePostCodeTooltip: 'To look up the store postcode please use “Find a store” by visiting our website.',
        storeEirecode: 'Store Eirecode',
        storeEireCodeTooltip: 'To look up the store eirecode please use “Find a store” by visiting our website.',
        dateOfVisit: 'Date of visit',
        timeOfVisit: 'Time of visit',
        startTimeOfVisit: 'Start Time of Visit',
        endTimeOfVisit: 'End Time of Visit',
        hour: 'Hour',
        minute: 'Minute',
        clothAtVisit: 'Clothing worn at time of visit',
        additionalInfoReqd: 'Additional Information Required',
        additionalInfoReqdPlaceholder: 'Please provide as much detail as possible, such as clothing worn, number of people in your party etc.',
        vehicleMake: 'Vehicle Make',
        model: 'Model',
        colour: 'Colour',
        regNo: 'Registration Number',
        attachments: 'Attachments',
        partDHeadingText: 'Part D: Completing the form',
        partDBodyText: 'Unless we agree otherwise, your request must be accompanied by copies of two official documents that clearly show your name and current address in order to satisfy {brandPlcName1} as to your identity; one of these documents must include a photo of you. Examples of suitable identification: copies of current driving licence, passport and copies of council tax or utilities statements dated within the three months prior to the date of your request',
        authThirdParty: 'If you have authorised a third party to make a request or receive your personal data on your behalf (e.g. a solicitor), please also provide a copy of the document granting that authority.',
        authThirdPartyV2: 'If you authorize a third party (e.g. an attorney) to make the request on your behalf, they must first send a copy of the authorization.',
        docTypeDefaultValue: 'Please select a document type',
        docTypeDefaultValueV2: 'Please select the document type',
        idProof: 'ID Proof',
        idProofV2: 'Proof of identity',
        addProof: 'Address Proof',
        addProofV2: '',
        authDoc: 'Authorisation Document',
        authDocV2: 'Authorization',
        otherDoc: 'Other',
        otherDocV2: 'Other',
        chooseFile: 'Choose File',
        upload: 'Upload',
        declaration: 'Declaration',
        declarationText: 'THE INFORMATION SUPPLIED WITHIN THIS APPLICATION FORM IS CORRECT AND I AM THE PERSON TO WHOM IT RELATES. I UNDERSTAND THAT IF I DO NOT COMPLETE THIS APPLICATION FORM CORRECTLY MY REQUEST MAY BE DELAYED',
        declarationTextV2: 'The Information Supplied Within This Application Form Is Correct and I Am the Person to Whom It Relates. I Understand That if I Do Not Complete This Application Form Correctly My Request May Be Delayed.',
        declarationTextV3: 'I certify that the information provided in this form is accurate and that I am the subject of the data to which the request relates. I have been informed that there may be a delay in processing the request if the form is incomplete or incorrect.',
        declarationHeader1: 'Declaration: ',
        declarationHeader2: 'Please select the statement which is true of your application:',
        declarationHeader2V2: 'Please select the declaration that corresponds to your request:',
        declarationSubHeader1: 'For Internal Use Only: ',
        declarationType1: 'If you are the person who is the subject of the personal data: ',
        declarationType1V2: 'If you are the person to whom the personal data relates:',
        declarationType2: 'If you are an internal colleague of {brandPlcName1}, completing on behalf of the person who is the subject of the personal data: ',
        declarationType2V2: 'If you are an internal {brandPlcName1} colleague, please fill out on behalf of the person to whom the personal data relates:',
        declarationtext2: 'The Information Supplied Within This Application Form Is Correct, As per the Information Provided by the Person to Whom It Relates. The Person to Whom This Form Relates Understands That if the Information Supplied Is Not Sufficient To Complete This Application Form Correctly Their Request May Be Delayed.',
        declarationtext2V2: 'The information provided in this request form is accurate and matches the information provided by the person to whom it relates. The person to whom this form relates understands that if the information provided is not sufficient to fill out this request form correctly, the request may be delayed.',
        cancel: 'Cancel',
        print: 'Print',
        submit: 'Submit',
        submitV2: 'Send',
        ackText: 'Your Request has been submitted successfully.',
        reqID: 'Request ID#:',
        makeNewReq: 'Make Another Request',
        fileUploadSuccessful: 'File Uploaded Successfully',
        selectDatePlc: 'Select Date',
        selectDatePlcV2: 'Select a date',
        addtionalRequestTypeText: 'For any other request types please provide the details of the request in Part B of the form',
        listUploadedFiles: 'Uploaded Files:',
        Mr: 'Mr',
        Mrs: 'Mrs',
        Miss: 'Miss',
        Ms: 'Ms',
        MsV2: '',
        OtherTitle: 'Other',
        OtherTitleV2: '',
        partA: 'Part A: ',
        partB: 'Part B: ',
        partBV2: 'Part B: ',
        partC: 'Part C: ',
        partD: 'Part D: ',
        cctvHeader: 'CCTV Footage',
        cctvHelpfulText: 'To assist with your request, please provide as much information as possible.',
        formCompletionHeader: 'Completing the form',
        formCompletionHeaderV2: 'Add an attachment if it is pertinent to your request',
        formCompletionBody: 'On occasion, we may ask that your request is accompanied by copies of official documents that clearly show your current name and address, in order that we may be satisfied as to your identity. If this is required, the team processing your request will contact you directly.',
        formCompletionBodyV2: 'In order to ensure the security of your data, and depending on the nature and content of your request, we may ask you for additional information to confirm your identification.',

        reportRequestID: 'Request ID',
        reportEmailID: 'Email Address',
        reportLastName: 'Customer Last Name',
        reportBrandName: 'Brand Name',
        reportStartDate: 'Start Date',
        reportEndDate: 'End Date',
        reportStatus: 'Status',
        reportBrandNameDefaultValue: 'Select Brand',
        reportStatusDefaultValue: 'Select Status',
        reportHeaderName: 'NAME',
        reportHeaderReceivedType: 'RECEIVED TYPE',
        reportHeaderReceivedDate: 'RECEIVED DT',
        reportHeaderAccessData: 'ACCESS DATA',
        reportHeaderDataRect: 'DATA RECTIFICATION',
        reportHeaderDataDel: 'DATA DELETION',
        reportHeaderObject: 'RIGHT TO OBJECT',
        reportHeaderCompletedDate: 'COMPLETED DT',


        reportSearch: 'Search',
        reportDownload: 'Download',
        reportRecords: 'Records:',
        reportsYes: 'Y',
        reportsNo: 'N',
        reportPaginationText: 'Showing {from} to {to} of {count} records|{count} records|1 record',
        originalSARData: 'Original SAR Data is as below',

        NavigationHome: 'Home',
        NavigationReports: 'Reports',


        requestStatus: 'Request Status',
        assignTo: 'AssignTo',
        requestArrivalType: 'Request Arrival Type',
        SARSubmittedDate: 'SAR Submitted Date',
        actualStartDate: 'Actual Start Date',
        expectedCompletionDate: 'Expected Completion Date',
        actualCompletionDate: 'Actual Completion Date',
        notes: 'Notes',
        historicalNotes: 'Historical Notes',

        Submitted: 'Submitted',
        InProgress: 'In Progress',
        OnHold: 'On Hold',
        ResponseSent: 'Response Sent',
        Complete: 'Complete',
        Open: 'Open',

        Web: 'Web',
        Phone: 'Phone',
        SocialMedia: 'Social Media',
        Post: 'Post',
        Email: 'Email',

        doctype: 'Document type',
        selectUpload: 'Select and upload a file',
        noFile: 'No File Chosen',

        jan: 'January',
        feb: 'February',
        mar: 'March',
        apr: 'April',
        may: 'May',
        jun: 'June',
        jul: 'July',
        aug: 'August',
        sep: 'September',
        oct: 'October',
        nov: 'November',
        dec: 'December',
        sun: 'Sunday',
        mon: 'Monday',
        tue: 'Tuesday',
        wed: 'Wednesday',
        thu: 'Thursday',
        fri: 'Friday',
        sat: 'Saturday',


        tooltipAccessRequest: "Subject Access Requests: You are entitled, subject to certain exceptions, to obtain a copy of your personal data held by {brandPlcName1}. Any information provided to you will have the personal data of third parties redacted, in order to protect the privacy of those third parties, unless we have consent from those third parties or it is reasonable in the circumstances to provide the third parties' personal data to you.",
        tooltipDataDeletion: "Requests for Data Deletion: You may be entitled to have your personal data held by {brandPlcName1} deleted.  Please note that this does not apply in all cases, such as where we are required by law to retain your data or where the data is required for us to bring or defend a legal claim.",
        tooltipDataObject: "Requests for right to object to data processing: You are entitled to object to the processing of your personal data by {brandPlcName1} for direct marketing purposes.  You may also be entitled to object to the processing of your personal data by {brandPlcName2} for other purposes, but this does not apply in all cases, such as where the data is required for us to bring or defend a legal claim.",
        tooltipDataRectification: "Requests for Data Rectification: You are entitled to have your personal data held by {brandPlcName1} corrected if it is inaccurate.  You can also ask us to complete your personal data if it is incomplete.",

        tooltipAccessRequestV2: 'You have the right, subject to certain exceptions, to ask us where we are using or storing your personal data and to obtain a copy. Where data provided contains personal data of third parties, this will be redacted to protect their privacy, unless we have their consent to provide, or it is reasonable in the circumstances to provide their data to you. This is known as the ‘right of access’ and is commonly known as making a subject access request or SAR.',
        tooltipAccessRequestV3: "The data protection policy helps you understand how your data is being used",
        tooltipDataDeletionV2: 'You can ask us to delete your personal data that we are storing. Please note in some cases this may not apply, such as where we are required by law to retain your data or where the data is required for us to bring or defend a legal claim.This is known as the ‘right to erasure’ and is commonly known as the ‘right to be forgotten’.',
        tooltipDataDeletionV3: "If you want to make new purchases after being deleted, you will need to create a new account.",
        tooltipDataRectificationV2: 'You can challenge the accuracy or completeness of your personal data held by us, and ask us to correct it. This is known as the ‘right to rectification’.',
        tooltipDataRectificationV3: 'If you have moved or changed your phone number, please update your information.',
        tooltipDataObjectV2: 'You have the right to object to us processing your personal data at any time for direct marketing purposes. You may also object to us processing your data for other purposes, but please note in some cases this may not apply, such as where the data is required for us to bring or defend a legal claim.',
        tooltipDataObjectV3: 'In several cases, it will be necessary to highlight the reasons for your particular situation which justify your opposition (except in matters of prospecting where no justification is required).',
        dataAccessRequestHelpfulInfo: 'If you ask to access your data, we have one calendar month (or longer if your request is complex) to provide it to you.<br/>There are laws which mean we can only provide your personal data to you and so you will not be able to see information about others.<br/><strong>If you want to see information about your past purchases or account details – you can access that straightaway without having to wait by logging into your account on our website and viewing the ‘My Account’ section.</strong>',
        dataDeleteRequestHelpfulInfo: 'Your legal rights give you a “right to be forgotten”.  If you exercise this right we will remove all the information we are able to about you from our records (apart from the fact that you have exercised this right).<br/>This can be inconvenient for you if you later wish to purchase items or create an account.<br/>We are required by law to retain certain information about you. <br/>As above, we have one month to arrange this for you and longer if the request is complex.<br/><strong>If you want to remove payment details or close an online account please log in to your account on our website and navigate to the ‘My Account’ section. Here you can amend your card details, close your account and manage your marketing preferences.</strong>',
        dataRectifyRequestHelpfulInfo: '<strong>If you want to update personal details about yourself such as a new address, a name change, new payment details then you can do this via the My Account section when logging in to your online account.</strong><br/>If you think we are processing inaccurate data about you which isn’t linked to the above details, then you can request for us to review and amend it as a request for rectification.',
        dataProcessRequestHelpfulInfo: 'If you don’t want us to process your data you can object and we will review that and where possible we will stop.<br/>You can ask us to stop sending marketing to you at any time. <strong>If you would like to amend your marketing preferences please do either by clicking the ‘unsubscribe’ button at the bottom of our email communications to you, or alternatively within the ‘My Account’ section of your online account.</strong><br/>If you ask us to stop processing your data using this request we will be unlikely to be able to provide our services to you. For example, you won’t be able to make online purchases.',

        dataAccessRequestHelpfulInfoV2: 'If you ask to access your data, we have one calendar month (or longer if your request is complex) to provide it to you.<br/>There are laws which mean we can only provide your personal data to you and so you will not be able to see information about others.<br/><strong>If you want to see information about your past purchases or account details – you can access that straightaway without having to wait by logging into your account on our website and viewing the ‘Your Account’ section.</strong>',
        dataAccessRequestHelpfulInfoV3: 'Exercising the right of access allows you to know if data concerning you is being used and to obtain communication of it.<br />Access to certain information may be limited in specific cases so as not to infringe the rights of other individuals, business secrets or even intellectual property.<br /><strong> If you would like to see information about your past purchases or your account details, you can access them immediately without waiting by logging into your account on our website and visiting the "Your account" section.</strong>',
        dataDeleteRequestHelpfulInfoV2: 'Your legal rights give you a “right to be forgotten”.  If you exercise this right we will remove all the information we are able to about you from our records (apart from the fact that you have exercised this right).<br/>This can be inconvenient for you if you later wish to purchase items or create an account.<br/>We are required by law to retain certain information about you. <br/>As above, we have one month to arrange this for you and longer if the request is complex.<br/><strong>If you want to remove payment details or or update your marketing preferences, you can do this straightaway without having to wait by logging into your account on our website and viewing the ‘Your Account’ section.</strong>',
        dataDeleteRequestHelpfulInfoV3: 'You have the right to ask us to delete personal data about you. <br />Deletion may be partial or refused in certain specific cases, particularly if we have a legal obligation to retain the information or to preserve the exercise of our legal rights.<br /><strong>If you wish to delete payment information or update your marketing preferences, you can do so immediately by logging into your account on our website and going to the "My account" section.</strong>',
        dataRectifyRequestHelpfulInfoV2: '<strong>If you want to update personal details about yourself such as a new address, a name change or new payment details then you can do this straightaway without having to wait by logging into your account on our website and viewing the ‘Your Account’ section.</strong><br/>If you think we are processing inaccurate data about you which isn’t linked to the above details, then you can request for us to review and amend it as a request for rectification.',
        dataRectifyRequestHelpfulInfoV3: 'The right to rectification allows you to correct or update your personal data.<br /><strong>If you wish to update your personal information, such as with a new address, a name change or new payment information, you can do so immediately by logging into your account on our website and going to the "My account" section.<strong/>',
        dataProcessRequestHelpfulInfoV2: 'If you don’t want us to process your data you can object and we will review that and where possible we will stop.<br/>You can ask us to stop sending marketing to you at any time. <strong>If you would like to amend your marketing preferences, you can do this straightaway without having to wait by either logging into your account on our website and viewing the ‘Your Account’ section, or by clicking the ‘unsubscribe’ button at the bottom of our email communications to you.</strong><br/>If you ask us to stop processing your data using this request we will be unlikely to be able to provide our services to you. For example, you won’t be able to make online purchases.',
        dataProcessRequestHelpfulInfoV3: 'The right to object allows you to object to your data being used for a specific purpose (e.g. direct marketing, profiling, etc.).<br /><strong>You may opt out of receiving marketing communications at any time by logging into your account on our website and going to the "My account" section or by clicking on the link to unsubscribe at the bottom of every email or SMS message.</strong>',


        sarmgmtAddNote: "Add Notes",
        sarmgmtAddNotesButton: "Add",
        sarmgmtCancelNotes: "Cancel",
        sarmgmtDocTitle: 'Document Title',
        sarmgmtUploadDoc: 'Upload a Document:',
        sarmgmtattachmentUploadedBy: 'Uploaded by {uploadedby} on {timestamp}',

        sarmgmtAddTask: "Add Tasks",
        sarmgmtViewTask: "View Task",
        sarmgmtCancelTasks: "Hide Task",
        TaskID: "Task #",
        Description: "Description",
        Assignee: "Assignee",
        CreatedDT: "Created",
        CompletedDT: "Completed",
        Comment: "Comment",
        taskStatus: "Status",

        taskDeleteMessage: "Please confirm you want to delete the task",
        taskDelete: "Delete",
        taskSave: 'Save',
        sarmgmtAddNotes: 'added a comment',

        consentCustomerId: "Customer Id",
        consentMarketingTextHeader: "Customer Marketing Consent : ",
        consentTooltipCustRef: "Customer id: Refers to the id against which the customer consent is stored in the GDPR Consent Repository. This id can be a unique reference number, loyalty number, email or phone number. Please refer to guidance on the Customer id for GDPR Repository for your respective market.",

        consentHstryCustomerId: 'Customer Id',
        consentHstryOpcoName: 'Brand Name',
        consentHstryChannel: 'Channel',
        consentHstryPrivacyPolicyVerId: 'Privacy Policy Ver Id',
        consentHstryMarketingChannelVersion: 'Marketing Channel Version',
        consentHstryOptIn: 'Opt In',
        consentHstrySubmitedDate: 'Submitted Date',
        consentHstrySource: 'Source',
        consentHstrySubmittedBy: 'Submitted By',
        consentHstryfileDownloadName: 'Customer Consent Report.csv',
        consentHistoryButton: "Get Consent History",
        taskEdit: 'Edit'
    }
}