export class SARLegalMgmtTaskModel {
    SARId: string;
    Description: string;
    Assignee: string;
    Comment: string;
    CreatedDT: string;
    IsUpdateRequest: string | number;
    CompletedDT: string;
    TaskStatusID: string;
    SARManagementTaskID: string;
    SARManagementID: string;
    ModifiedDT: string;

    constructor() {
        this.SARId = "";
        this.Description = "";
        this.Assignee = "";
        this.Comment = "";
        this.CreatedDT = "";
        this.IsUpdateRequest = "";
        this.CompletedDT = "";
        this.TaskStatusID = "";
        this.SARManagementTaskID = "";
        this.SARManagementID = "";
        this.ModifiedDT = "";
    }
}