import { defineComponent } from "vue";
import { SARLegalInitModel } from "@/models/SARLegalInitModel";
import CommonMixin from "@/mixins/common.mixin";
import { STYLEVERSIONMAP, AttachmentFileDetail } from "@/common/common.model";

export default defineComponent({
    name: 'SARAttachmentsMixin',
    mixins: [CommonMixin],
    props: {
        newUser: {
            type: Object,
            required: true,
        },
        initModel: {
            type: SARLegalInitModel,
            required: true,
        },
        styleCode: {
            type: String,
            required: false,
        },
    },
    methods: {
        downloadAttachments(guidFileName: string, originalFileName: string) {
            var attachmentFileDetails = new AttachmentFileDetail();
            attachmentFileDetails.guidFileName = guidFileName;
            attachmentFileDetails.originalFileName = originalFileName;
            this.$emit("emmitedFileName", attachmentFileDetails);
        },

        getSelectedDocName(docID: any) {
            let dropdownKey = this.initModel.DocumenTypeList.filter((x) => {
                return x.value == docID;
            })[0].resourceKeyVal;
            return this.translate("labeltext." + dropdownKey);
        },

        // Provide a get translated method specific for SARAttachmentV8
        getTranslatedValueEx(label: string) {
            let version = this.styleCode && !!STYLEVERSIONMAP.data[this.styleCode.toLowerCase()]
                ? STYLEVERSIONMAP.data[this.styleCode.toLowerCase()]
                : "";

            let isNewerScrewfix = version && Array.isArray(version);

            let value = null;
            if (isNewerScrewfix) {
                for (let i = 0; i < version.length; i++) {
                    try {
                        let newLabel = `${label}${version[i]}`;   
                        value = this.$t(newLabel);
                        if (newLabel !== value) {
                            break;
                        } else {
                            value = null;
                        }
                    } catch (error) { }
                }
            }

            let defaultValue = '';
            try {
                if (value === null || value === undefined) {
                    defaultValue = this.$t(label);
                } else {
                    defaultValue = value;
                }
            } catch (error) { }

            return defaultValue;
        },

        getTranslatedValueWithParams(label: string, params: {}) {
            let version = this.styleCode && !!STYLEVERSIONMAP.data[this.styleCode.toLowerCase()]
                ? STYLEVERSIONMAP.data[this.styleCode.toLowerCase()]
                : "";

            let isNewerScrewfix = version && Array.isArray(version);

            let value = null;
            if (isNewerScrewfix) {
                for (let i = 0; i < version.length; i++) {
                    try {
                        let newLabel = `${label}${version[i]}`;
                        value = this.$t(newLabel, params);
                        if (newLabel !== value) {
                            break;
                        } else {
                            value = null;
                        }
                    } catch (error) { }
                }
            }

            let defaultValue = '';
            try {
                if (value === null || value === undefined) {
                    defaultValue = this.$t(label, params);
                } else {
                    defaultValue = value;
                }
            } catch (error) { }

            return defaultValue;
        },
    }
});