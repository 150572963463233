import api, { AxiosResponse } from "axios";
import { BaseService } from "./base.service";
import { SARLegalAttachmentModel } from "@/models/SARLegalAttachmentModel";
import { ICustomerConsentInit, ISARLegalAttachment } from "@/models/service-request-response-model";
import { SARMgmt, SARMgmtInitModel } from "@/models/SARMgmtInitModel";
import { SARLegalMgmtTaskModel } from "@/models/SARLegalMgmtTaskModel";
import { SARLegalMgmtNotesModel } from "@/models/SARLegalMgmtNotesModel";
import { SARLegalMgmtModel } from "@/models/SARLegalMgmtModel";

export class GSARManagementService extends BaseService {
    public fileUpload(files: any): Promise<AxiosResponse<string>> {
        return api.post<string>('FileUpload', files, this.getLegalAuthenticationHeaderForUpload());
    }

    public saveAttachments(attachment: SARLegalAttachmentModel) : Promise<AxiosResponse<ISARLegalAttachment[]>> {
        return api.post<ISARLegalAttachment[]>('SaveAttachments', attachment, this.getLegalAuthenticationHeader());
    }

    public initialise(sarGuid: string) : Promise<AxiosResponse<SARMgmtInitModel>> {
        return api.get<SARMgmtInitModel>(`GSARMgmtInit/${sarGuid}`, this.getLegalAuthenticationHeader());
    }

    public SARTaskActivity(model: SARLegalMgmtTaskModel) : Promise<AxiosResponse<any>> {
        return api.post<any>('GSARMgmtTask', model, this.getLegalAuthenticationHeader());
    }

    public getConsentHistory(consent: ICustomerConsentInit): Promise<AxiosResponse<any>> {
        return api.post<any>('GSARConsentHistory', consent, this.getLegalAuthenticationHeader());
    }

    public postSARMgmtNotes(notes: SARLegalMgmtNotesModel): Promise<AxiosResponse<any>> {
        return api.post<any>('GSARMgmtNotes', notes, this.getLegalAuthenticationHeader());
    }

    public post(model: SARLegalMgmtModel): Promise<AxiosResponse<any>> {
        return api.post<any>('GSARMgmt', model, this.getLegalAuthenticationHeader());
    }
}