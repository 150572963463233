import { defineComponent } from "vue";
import { SARLegalInitModel } from "@/models/SARLegalInitModel";
import CommonMixin from "@/mixins/common.mixin";

export default defineComponent({
    name: 'userCCTVDetailsMixin',
    mixins: [CommonMixin],
    props: {
        initModel: {
            type: SARLegalInitModel,
            required: true,
        },
        newUser: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            dateFormat: "dd/MM/yyyy",
        };
    }
})