<template>
  <div class="gd-documents mb-gutter">
    <div class="gd-form-part">
      <h3>
        {{
          `${getTranslatedValueEx("labeltext.partB")} ${getTranslatedValueEx(
            "labeltext.formCompletionHeader"
          )}`
        }}
      </h3>

      <p class="mb-medium">
        {{ getTranslatedValueEx("labeltext.formCompletionBody") }}
      </p>

      <p class="mb-medium">
        {{ getTranslatedValueEx("labeltext.authThirdParty") }}
      </p>
    </div>

    <div class="form-field form-field--gd">
      <label class="field-row">
        <span
          class="label label--text label--inline"
          data-auto="documentproof-lbl"
          >{{ $t("labeltext.doctype") }}</span
        >
        <select v-model="initModel.selectedDocument" name="documentproof">
          <option value="">
            {{ $t("labeltext.docTypeDefaultValueV2") }}
          </option>
          <option
            v-for="(docType, index) in getDropdownValues"
            :value="docType.value"
            :key="index"
          >
            {{ getTranslatedValueEx(`labeltext.${docType.resourceKeyVal}`) }}
          </option>
        </select>
      </label>
    </div>

    <div class="form-field form-field--gd">
      <div class="field-row">
        <span
          class="label label--text label--inline"
          data-auto="choosefile-lbl"
          >{{ getTranslatedValueEx("labeltext.selectUpload") }}</span
        >
        <div class="gd-file-upload">
          <label class="gd-file-selector">
            <input
              type="file"
              name="choosefile"
              ref="fileupload"
              @change="fileChange($event.target?.files)"
            />
            <br />
            <span class="font-small" data-auto="choosefiletext-lbl">
              (<span v-for="(ext, index) in allowedFileTypes" :key="index"
                >.{{ ext
                }}<span v-if="index !== allowedFileTypes.length - 1"
                  >,
                </span></span
              >)
            </span>
          </label>
          <button
            class="button button--alt"
            id="uploadfile"
            type="button"
            @click="upload"
          >
            {{ getTranslatedValueEx("labeltext.upload") }}
          </button>
        </div>
      </div>
    </div>
    <div class="gd-form-part top-padding">
      <h3>
        <span v-if="showFileUploadSuccessMessage" class="success-text">
          {{ $t("labeltext.fileUploadSuccessful") }}
        </span>
      </h3>
    </div>

    <div class="gd-file-list font-small" v-if="newUser.DocumentList.length > 0">
      <p>{{ $t("labeltext.listUploadedFiles") }}</p>

      <ul class="list-styled">
        <li v-for="(item, index) in newUser.DocumentList" :key="index">
          {{ index + 1 }}. {{ getSelectedDocNameEx(item.DocumentTypeId) }} -
          {{ item.FileLocation }}
        </li>
      </ul>
    </div>

    <div>
      <h3>
        {{
          `${getTranslatedValueEx(
            "labeltext.declarationHeader1"
          )} ${getTranslatedValueEx("labeltext.declarationHeader2")}`
        }}
      </h3>
    </div>

    <div class="form-field form-field--gd"></div>

    <div v-if="showDeclaration()">
      <div class="form-field form-field--gd">
        <label class="field-row gd-form-declaration">{{
          getTranslatedValueEx("labeltext.declarationType1")
        }}</label>
      </div>

      <div class="form-field form-field--gd">
        <label class="field-row gd-form-declaration">
          <span class="gd-field__option">
            <input
              type="checkbox"
              id="Declaration"
              value="Declaration"
              v-model="initModel.Declaration"
              name="tncdeclaration"
            />
            <span class="css-checkbox"></span>
          </span>
          &nbsp;
          <span
            class="label label--text label--inline label--multi-line no-padding"
            data-auto="Declaration-lbl"
            >{{ getTranslatedValueEx("labeltext.declarationText") }}</span
          >
        </label>
      </div>
    </div>

    <div v-if="showColleagueDeclaration()">
      <div class="form-field form-field--gd">
        <label class="field-row gd-form-declaration">
          {{ $t("labeltext.declarationSubHeader1") }}
        </label>
      </div>

      <div class="form-field form-field--gd">
        <label class="field-row gd-form-declaration">
          {{
            getTranslatedValueWithParams("labeltext.declarationType2", {
              brandPlcName1: initModel.brandPlcName,
            })
          }}
        </label>
      </div>

      <div class="form-field form-field--gd">
        <label class="field-row gd-form-declaration">
          <span class="gd-field__option">
            <input
              type="checkbox"
              id="ColleagueDeclaration"
              value="ColleagueDeclaration"
              v-model="initModel.ColleagueDeclaration"
              name="tnccolleaguedeclaration"
            />
            <span class="css-checkbox"></span>
          </span>
          &nbsp;
          <span
            class="label label--text label--inline label--multi-line no-padding"
            data-auto="ColleagueDeclaration-lbl"
            >{{ getTranslatedValueEx("labeltext.declarationtext2") }}</span
          >
        </label>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { STYLEVERSIONMAP } from "@/common/common.model";
import UserDocumentsMixin from "@/mixins/userDocuments.mixin";

export default defineComponent({
  name: "UserDocumentsV8",
  mixins: [UserDocumentsMixin],
  props: {
    styleCode: {
      type: String,
      required: false,
    },
  },
  computed: {
    getDropdownValues(): any {
      return (
        this.initModel.DocumenTypeList != null &&
        this.initModel.DocumenTypeList.filter(
          (docType) => this.getDDLTranslatedValue(docType.resourceKeyVal) != ""
        )
      );
    },
  },
  methods: {
    getSelectedDocNameEx(docID: string) {
      const dropdownKey = this.initModel.DocumenTypeList.find((x) => {
        return x.value == docID;
      })?.resourceKeyVal;
      return this.translate("labeltext." + dropdownKey);
    },

    // Provide a get translated method for this specific version
    getTranslatedValueEx(label: string) {
      let version =
        this.styleCode && STYLEVERSIONMAP.data[this.styleCode.toLowerCase()]
          ? STYLEVERSIONMAP.data[this.styleCode.toLowerCase()]
          : "";

      let isNewerScrewfix = version && Array.isArray(version);

      let value = null;
      if (isNewerScrewfix) {
        for (let i = 0; i < version.length; i++) {
          try {
            let newLabel = `${label}${version[i] ? version[i] : ""}`;
            value = this.$t(newLabel);
            if (newLabel !== value) {
              break;
            } else {
              value = null;
            }
          } catch (error) {}
        }
      }

      let defaultValue = "";
      try {
        if (value === null || value === undefined) {
          defaultValue = this.$t(label);
        } else {
          defaultValue = value;
        }
      } catch (error) {}

      return defaultValue;
    },

    getTranslatedValueWithParams(label: string, params: {}) {
      let version =
        this.styleCode && STYLEVERSIONMAP.data[this.styleCode.toLowerCase()]
          ? STYLEVERSIONMAP.data[this.styleCode.toLowerCase()]
          : "";

      let isNewerScrewfix = version && Array.isArray(version);

      let value = null;
      if (isNewerScrewfix) {
        for (let i = 0; i < version.length; i++) {
          try {
            let newLabel = `${label}${version[i] ? version[i] : ""}`;
            value = this.$t(newLabel, params);
            if (newLabel !== value) {
              break;
            } else {
              value = null;
            }
          } catch (error) {}
        }
      }

      let defaultValue = "";
      try {
        if (value === null || value === undefined) {
          defaultValue = this.$t(label);
        } else {
          defaultValue = value;
        }
      } catch (error) {}

      return defaultValue;
    },
  },
});
</script>

<style scoped>
.no-padding {
  padding-top: 0px !important;
}
</style>