<template>
  <div>
    <div class="grid">
      <div class="form-field form-field--gd grid__item">
        <label class="field-row">
          <span
            data-auto="reportrequestID-lbl"
            class="label label--text label--inline"
          >
            {{ $t("labeltext.reportRequestID") }}
          </span>
          <input
            type="text"
            v-model="reportModel.SARID"
            name="reportRequestID"
          />
        </label>
      </div>
      <div class="form-field form-field--gd grid__item">
        <label class="field-row">
          <span
            data-auto="reportemailID-lbl"
            class="label label--text label--inline"
          >
            {{ $t("labeltext.reportEmailID") }}
          </span>
          <input
            type="text"
            v-model="reportModel.EmailAddress"
            name="reportEmailAddress"
          />
        </label>
      </div>
      <div class="form-field form-field--gd grid__item">
        <label class="field-row">
          <span
            data-auto="reportlastname-lbl"
            class="label label--text label--inline"
          >
            {{ $t("labeltext.reportLastName") }}
          </span>
          <input
            type="text"
            v-model="reportModel.LastName"
            name="reportLastName"
          />
        </label>
      </div>
      <div class="form-field form-field--gd grid__item">
        <label class="field-row">
          <span
            data-auto="reportbrandname-lbl"
            class="label label--text label--inline"
          >
            {{ $t("labeltext.reportBrandName") }}
          </span>
          <select
            v-model="reportModel.StyleId"
            name="reportBrandName"
            class="fixedWidth"
          >
            <option value="">
              {{ $t("labeltext.reportBrandNameDefaultValue") }}
            </option>
            <option
              v-for="(brandName, index) in reportModel.BrandNames"
              :value="brandName.StyleID"
              :key="index"
            >
              {{ brandName.Website }}
            </option>
          </select>
        </label>
      </div>
      
      <div class="form-field form-field--gd grid__item">
        <div class="field-row">
          <span
            data-auto="reportstartdate-lbl"
            class="label label--text label--inline"
          >
            {{ $t("labeltext.reportStartDate") }}
          </span>
          <date-picker
            v-model="reportModel.StartDT"        
            :locale="reportModel.reportdatepickerLang"
            :enable-time-picker="false"
            :format='dateFormat'
            auto-apply
            hide-input-icon             
          >
          </date-picker>
        </div>
      </div>

      <div class="form-field form-field--gd grid__item">
        <div class="field-row">
          <span
            data-auto="reportenddate-lbl"
            class="label label--text label--inline"
          >
            {{ $t("labeltext.reportEndDate") }}
          </span>
          <date-picker
            v-model="reportModel.EndDT"
            :locale="reportModel.reportdatepickerLang"
            :enable-time-picker="false"
            :format='dateFormat'
            auto-apply
            hide-input-icon                 
          >
          </date-picker>
        </div>
      </div>
      
      <div class="form-field form-field--gd grid__item">
        <label class="field-row">
          <span
            data-auto="reportstatus-lbl"
            class="label label--text label--inline"
          >
            {{ $t("labeltext.reportStatus") }}
          </span>
          <select
            v-model="reportModel.StatusID"
            name="reportStatus"
            class="fixedWidth"
          >
            <option value="">
              {{ $t("labeltext.reportStatusDefaultValue") }}
            </option>
            <option
              v-for="(stat, index) in reportModel.Status"
              :value="stat.StatusID"
              :key="index"
            >
              {{ getDDLTranslatedValue(stat.ResourceKey) }}
            </option>
          </select>
        </label>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { SARReportModel } from "@/models/SARReportModel";
import { defineComponent } from "vue";
import CommonMixin from "@/mixins/common.mixin";

export default defineComponent({
  name: "reportForm",
  mixins: [CommonMixin],
  props: {
    reportModel: {
      type: SARReportModel,
      required: true,
    },
  },
  data() {
    return {
      dateFormat: 'dd/MM/yyyy'
    }
  }
});
</script>

<style scoped>
/* Scoped style to constrain the width of the date picker */
.dp__main {
    font-family: var(--dp-font-family);
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    box-sizing: border-box;
    position: relative;
    width: 213px;
}
</style>
