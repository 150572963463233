export interface IReportInitModel {
    ReportStatus: IReportStatus[];
    ReportStyle: IReportStyle[];
    CultureInfo: ISARCultureInfo;
}

export interface IReportStatus {
    StatusID: number;
    Description: string;
    ResourceKey: string;
}

export interface IReportStyle {
    StyleID: number;
    Website: string;
}

export interface ISARCultureInfo {
    CultureInfo: string;
}

export interface IReportViewDetails extends IErrorDTO {
    ReportView: IReportView[];
    TotalRecordCount: ITotalCount;
}

export interface ITotalCount {
    TotalRecordCount: number;
}

export interface IReportView {
    SARID: number | null;
    RequestorName: string;
    ReceivedType: string;
    ReceivedDate: string;
    RequestToAccessData: string;
    RequestToDataDeletion: string;
    RequestToDataRectification: string;
    RequestForRightToObjectToDataProcessing: string;
    CompleteDate: string;
    EmailAddress: string;
    LastName: string;
    StatusID: number | null;
    CreatedDT: string;
    ActualStartDT: string;
    ExpectedCompletionDT: string;
    StartDT: string;
    EndDT: string;
    StyleId: number | null;
    GUID: string;
    Brandname: string;
    StatusText: string;
}

export interface IErrorDTO {
    SubCode: string;
}

export interface ISARLegalAttachment {
    SarID: number;
    DocumentTitle: string;
    FileName: string;
    FileGuid: string;
    UploadedBy: string;
    UploadedDate: string;
}

export class Address {
    AddressLine1: string;
    AddressLine2: string;
    AddressLine3: string;
    Town: string;
    County: string;
    Country: string;
    PostCode: string;

    constructor() {
        this.AddressLine1 = '';
        this.AddressLine2 = '';
        this.AddressLine3 = '';
        this.Town = '';
        this.County = '';
        this.Country = '';
        this.PostCode = '';
    }
}

export interface ICustomerConsentHistory {
    CustomerId: string;
    OpcoName: string;
    Channel: string;
    PrivacyPolicyVerId: string;
    MarketingChannelVersion: string;
    OptIn: string;
    ConsentHstrySubmitedDate: string;
    Source: string;
    SubmittedBy: string;
}

export interface ICustomerConsentInit {
    ConsentId: string;
    TenantId: string;
    OpcoName: string;
}