import { AuthModel } from "@/common/common.model";
import { AuthService } from "@/services/auth.service";
import { useUserModule } from "@/store/user-module";
import { defineComponent } from "vue";
import { STYLEVERSIONMAP } from "@/common/common.model";

export default defineComponent({
    name: 'CommonMixin',
    data() {
        return {
            styleId:
                this.$route.query.Session?.toString() ||
                this.$route.query.session?.toString(),
        }
    },
    computed: {
        isNewerScrewfix(): boolean {
            return this.version && Array.isArray(this.version);
        },
        version(): any {
            return this.styleId && !!STYLEVERSIONMAP.data[this.styleId]
                ? STYLEVERSIONMAP.data[this.styleId]
                : "";
        },
    },
    methods: {
        getDDLTranslatedValue(dropdownValue: string) {
            let label = dropdownValue.startsWith('labeltext') ? dropdownValue : `labeltext.${dropdownValue}`;
            return this.translate(label).trim();
        },

        authorizeUser(sarIDParam: string, tempToken: string | undefined, returnUrlPage: string, authModel: AuthModel) {
            if (sarIDParam && tempToken === undefined) {
                this.getTempToken(returnUrlPage + sarIDParam);
            }
            else if (sarIDParam && tempToken) {
                this.getPermanentToken(tempToken, authModel);

            }
            else
                authModel.isRequestAuthorized = false;
        },

        getTempToken(returnUrl: string) {
            var baseUrl = process.env.VUE_APP_API_URL;
            window.location.href = `${baseUrl}/Home/Signin/?returnURL=/${returnUrl}`;
        },

        async getPermanentToken(tempToken: string, authModel: AuthModel) {
            try {
                const service = new AuthService();
                var response = await service.getPermanentToken(tempToken)

                const store = useUserModule();
                store.state.jwtTokenAuthLegal = response.data;

                authModel.isRequestAuthorized = true;
            } catch (error) {
                authModel.isRequestAuthorized = false;
            }
        },

        formatDate(date: any) {
            if (date instanceof Date) {
                var year = date.getFullYear();
                var month = (1 + date.getMonth()).toString();
                month = month.length > 1 ? month : '0' + month;
                var day = date.getDate().toString();
                day = day.length > 1 ? day : '0' + day;
                var hour = date.getHours().toString();
                hour = hour.length > 1 ? hour : '0' + hour;
                var minutes = date.getMinutes().toString();
                minutes = minutes.length > 1 ? minutes : '0' + minutes;
                var seconds = date.getSeconds().toString();
                seconds = seconds.length > 1 ? seconds : '0' + seconds;
                return year + '/' + month + '/' + day + " " + hour + ":" + minutes + ":" + seconds;
            }
            else
                return date;
        },

        getDateFromDateTime(date: any) {
            if (date instanceof Date) {
                var year = date.getFullYear();
                var month = (1 + date.getMonth()).toString();
                month = month.length > 1 ? month : '0' + month;
                var day = date.getDate().toString();
                day = day.length > 1 ? day : '0' + day;
                return year + '/' + month + '/' + day;
            }
            else
                return date;
        },

        checkIfDateGreaterThanPresentDate(date: any) {
            let returnValue = true;
            if (date instanceof Date) {
                if (date > new Date())
                    returnValue = false;
            }
            return returnValue;
        },

        getTranslatedValue(label: string) {
            let value = null;
            if (this.isNewerScrewfix) {
                for (let i = 0; i < this.version.length; i++) {
                    try {
                        let newLabel = `${label}${this.version[i]}`;   
                        value = this.$t(newLabel);                        
                        if (newLabel !== value) {
                            break;
                        } else {
                            value = null;
                        }
                    } catch (error) {
                    }
                }
            }

            let defaultValue = '';
            try {
                if (value === null || value === undefined) {
                    defaultValue = this.$t(label);
                } else {
                    defaultValue = value;
                }
            } catch (error) { }

            return defaultValue;
        },

        translate(label: string) {
            return this.getTranslatedValue(label);
        },
    }
});