<template>
  <div class="gd-attachment mb-gutter">
    <div class="mb-gutter">
      <h3>
        {{
          `${translate("labeltext.partB")}${translate(
            "labeltext.formCompletionHeader"
          )}`
        }}
      </h3>
    </div>
    <div class="gd-file-list font-small">
      <ul class="list-styled">
        <li v-for="(doc, index) in newUser.DocumentList" :key="index">
          <img
            src="./../../../assets/images/attachment.png"
            class="legal__attachment"
          />
          <a
            @click="downloadAttachments(doc.Guid, doc.FileLocation)"
            class="legal__attachmentlist"
          >
            <span class="legal__attachmentlist"
              >{{ getSelectedDocName(doc.DocumentTypeId) }} -
            </span>
            {{ doc.FileLocation }}
          </a>
        </li>
      </ul>
    </div>

    <div>
      <h3>
        {{
          `${translate("labeltext.declarationHeader1")}${translate(
            "labeltext.declarationHeader2"
          )}`
        }}
      </h3>
    </div>

    <div class="form-field form-field--gd"></div>

    <div class="form-field form-field--gd">
      <label class="field-row gd-form-declaration">
        {{ $t("labeltext.declarationType1") }}
      </label>
    </div>

    <div class="form-field form-field--gd">
      <label class="field-row gd-form-declaration">
        <span class="gd-field__option">
          <input
            type="checkbox"
            id="Declaration"
            value="Declaration"
            v-model="initModel.Declaration"
            name="tncdeclaration"
            :disabled="initModel.isDisabled"
          />
          <span class="css-checkbox"></span>
        </span>
        &nbsp;
        <span
          class="label label--text label--inline label--multi-line no-padding"
          data-auto="Declaration-lbl"
        >
          {{ $t("labeltext.declarationText") }}
        </span>
      </label>
    </div>

    <div class="form-field form-field--gd">
      <label class="field-row gd-form-declaration">
        {{ $t("labeltext.declarationSubHeader1") }}
      </label>
    </div>

    <div class="form-field form-field--gd">
      <label class="field-row gd-form-declaration">
        {{
          $t("labeltext.declarationType2", {
            brandPlcName1: initModel.brandPlcName,
          })
        }}
      </label>
    </div>

    <div class="form-field form-field--gd">
      <label class="field-row gd-form-declaration">
        <span class="gd-field__option">
          <input
            type="checkbox"
            id="ColleagueDeclaration"
            value="ColleagueDeclaration"
            v-model="initModel.ColleagueDeclaration"
            name="tnccolleaguedeclaration"
            :disabled="initModel.isDisabled"
          />
          <span class="css-checkbox"></span>
        </span>
        &nbsp;
        <span
          class="label label--text label--inline label--multi-line no-padding"
          data-auto="ColleagueDeclaration-lbl"
        >
          {{ $t("labeltext.declarationtext2") }}
        </span>
      </label>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SARAttachmentsMixin from "@/mixins/SARAttachments.mixin";

export default defineComponent({
  name: "SARAttachmentsV6",
  mixins: [SARAttachmentsMixin],
});
</script>

<style scoped>
.no-padding {
  padding-top: 0px !important;
}
</style>