<template>
  <div class="gd-user-details pt-gutter pb-gutter" id="userDetails">
    <form @submit.prevent="addUserDetails">
      <div class="mb-gutter" v-if="initModel.modelErrors.length">
        <div v-if="isRequestAuthorized" class="alert alert--error">
          <span class="icon icon-alert"></span>
          <p>
            <strong>{{
              getLocalTranslatedValue("validationtext.validationHeading")
            }}</strong>
          </p>
          <ul class="list-styled">
            <li v-for="(error, index) in initModel.modelErrors" :key="index">
              {{ error }}
            </li>
          </ul>
        </div>

        <div v-else class="alert alert--error">
          <span class="icon icon-alert"></span>
          <div v-for="(error, index) in initModel.modelErrors" :key="index">
            {{ error }}
          </div>
        </div>
      </div>

      <div v-if="isRequestAuthorized">
        <p>
          <strong>{{
            getLocalTranslatedValue("labeltext.completedByWarning")
          }}</strong>
        </p>

        <v-usercontactdetails
          :initModel="initModel"
          :newUser="newUser"
          v-show="
            !isComponentVersionUpdateRequired(childComponentSection.PartA)
          "
        >
        </v-usercontactdetails>

        <v-usercontactdetailsv2
          :initModel="initModel"
          :newUser="newUser"
          v-show="
            isComponentVersionUpdateRequired(childComponentSection.PartA) &&
            userContactDetailsVersion == 2
          "
        ></v-usercontactdetailsv2>

        <v-usercontactdetailsv3
          :initModel="initModel"
          :newUser="newUser"
          v-show="
            isComponentVersionUpdateRequired(childComponentSection.PartA) &&
            userContactDetailsVersion == 3
          "
        ></v-usercontactdetailsv3>

        <v-usercontactdetailsv4
          :initModel="initModel"
          :newUser="newUser"
          v-show="
            isComponentVersionUpdateRequired(childComponentSection.PartA) &&
            userContactDetailsVersion == 4
          "
        ></v-usercontactdetailsv4>

        <v-usercontactdetailsv5
          :initModel="initModel"
          :newUser="newUser"
          v-show="
            isComponentVersionUpdateRequired(childComponentSection.PartA) &&
            userContactDetailsVersion == 5
          "
        ></v-usercontactdetailsv5>

        <v-usercontactdetailsv6
          :initModel="initModel"
          :newUser="newUser"
          v-show="
            isComponentVersionUpdateRequired(childComponentSection.PartA) &&
            userContactDetailsVersion == 6
          "
        ></v-usercontactdetailsv6>

        <v-usercontactdetailsv7
          :initModel="initModel"
          :newUser="newUser"
          :styleCode="
            $route.query.Session?.toString() || $route.query.session?.toString()
          "
          v-show="
            isComponentVersionUpdateRequired(childComponentSection.PartA) &&
            userContactDetailsVersion == 7
          "
        />

        <v-userrelationship
          :initModel="initModel"
          :newUser="newUser"
          v-show="
            !isComponentVersionUpdateRequired(childComponentSection.PartB)
          "
        />

        <v-userrelationshipv2
          :initModel="initModel"
          :newUser="newUser"
          v-show="
            isComponentVersionUpdateRequired(childComponentSection.PartB) &&
            userCustEmpRelationshipVersion == 2
          "
        />

        <v-userrelationshipv4
          :initModel="initModel"
          :newUser="newUser"
          v-show="
            isComponentVersionUpdateRequired(childComponentSection.PartB) &&
            userCustEmpRelationshipVersion == 4
          "
        />

        <v-userrelationshipv5
          :initModel="initModel"
          :newUser="newUser"
          :styleCode="
            $route.query.Session?.toString() || $route.query.session?.toString()
          "
          v-show="
            isComponentVersionUpdateRequired(childComponentSection.PartB) &&
            userCustEmpRelationshipVersion == 5
          "
        />

        <v-usercctvdetails
          :initModel="initModel"
          :newUser="newUser"
          v-show="
            !isComponentVersionUpdateRequired(childComponentSection.PartC)
          "
        >
        </v-usercctvdetails>

        <v-usercctvdetailsv2
          :initModel="initModel"
          :newUser="newUser"
          v-show="
            isComponentVersionUpdateRequired(childComponentSection.PartC) &&
            userCCTVDetailsVersion == 2
          "
        >
        </v-usercctvdetailsv2>

        <v-usercctvdetailsv3
          :initModel="initModel"
          :newUser="newUser"
          v-show="
            isComponentVersionUpdateRequired(childComponentSection.PartC) &&
            userCCTVDetailsVersion == 3
          "
        >
        </v-usercctvdetailsv3>

        <v-usercctvdetailsv4
          :initModel="initModel"
          :newUser="newUser"
          v-show="
            isComponentVersionUpdateRequired(childComponentSection.PartC) &&
            userCCTVDetailsVersion == 4
          "
        >
        </v-usercctvdetailsv4>

        <v-usercctvdetailsv5
          :initModel="initModel"
          :newUser="newUser"
          v-show="
            isComponentVersionUpdateRequired(childComponentSection.PartC) &&
            userCCTVDetailsVersion == 5
          "
        >
        </v-usercctvdetailsv5>

        <v-userdocuments
          @showValidationMessage="showErrorMessageForFileUpload($event)"
          :initModel="initModel"
          :newUser="newUser"
          v-show="
            !isComponentVersionUpdateRequired(childComponentSection.PartD)
          "
        >
        </v-userdocuments>

        <v-userdocumentsv2
          @showValidationMessage="showErrorMessageForFileUpload($event)"
          :initModel="initModel"
          :newUser="newUser"
          v-show="
            isComponentVersionUpdateRequired(childComponentSection.PartD) &&
            userDocumentsVersion == 2
          "
        ></v-userdocumentsv2>

        <v-userdocumentsv4
          @showValidationMessage="showErrorMessageForFileUpload($event)"
          :initModel="initModel"
          :newUser="newUser"
          v-show="
            isComponentVersionUpdateRequired(childComponentSection.PartD) &&
            userDocumentsVersion == 4
          "
        ></v-userdocumentsv4>

        <v-userdocumentsv6
          @showValidationMessage="showErrorMessageForFileUpload($event)"
          :initModel="initModel"
          :newUser="newUser"
          v-show="
            isComponentVersionUpdateRequired(childComponentSection.PartD) &&
            userDocumentsVersion == 6
          "
        ></v-userdocumentsv6>

        <v-userdocumentsv7
          @showValidationMessage="showErrorMessageForFileUpload($event)"
          :initModel="initModel"
          :newUser="newUser"
          v-show="
            isComponentVersionUpdateRequired(childComponentSection.PartD) &&
            userDocumentsVersion == 7
          "
        ></v-userdocumentsv7>

        <v-userdocumentsv8
          @showValidationMessage="showErrorMessageForFileUpload($event)"
          :initModel="initModel"
          :newUser="newUser"
          :styleCode="
            $route.query.Session?.toString() || $route.query.session?.toString()
          "
          v-show="
            isComponentVersionUpdateRequired(childComponentSection.PartD) &&
            userDocumentsVersion == 8
          "
        />
        <button
          class="button button--block"
          id="submitSAR"
          type="submit"
          :disabled="isDisabled"
        >
          {{ getLocalTranslatedValue("labeltext.submit") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ChildComponentSection, STYLEVERSIONMAP } from "@/common/common.model";
import { SARLegalInitModel } from "@/models/SARLegalInitModel";
import { SARLegalUserModel } from "@/models/SARLegalUserModel";

import UserContactDetails from "@/components/body/UserDetails/UserContactDetails.vue";
import UserContactDetailsV2 from "@/components/body/UserDetails/UserContactDetailsV2.vue";
import UserContactDetailsV3 from "@/components/body/UserDetails/UserContactDetailsV3.vue";
import UserContactDetailsV4 from "@/components/body/UserDetails/UserContactDetailsV4.vue";
import UserContactDetailsV5 from "@/components/body/UserDetails/UserContactDetailsV5.vue";
import UserContactDetailsV6 from "@/components/body/UserDetails/UserContactDetailsV6.vue";
import UserContactDetailsV7 from "@/components/body/UserDetails/UserContactDetailsV7.vue";
import UserCustEmpRelationship from "@/components/body/UserDetails/UserCustEmpRelationship.vue";
import UserCustEmpRelationshipV2 from "@/components/body/UserDetails/UserCustEmpRelationshipV2.vue";
import UserCustEmpRelationshipV4 from "@/components/body/UserDetails/UserCustEmpRelationshipV4.vue";
import UserCustEmpRelationshipV5 from "@/components/body/UserDetails/UserCustEmpRelationshipV5.vue";
import UserCCTVDetails from "@/components/body/UserDetails/UserCCTVDetails.vue";
import UserCCTVDetailsV2 from "@/components/body/UserDetails/UserCCTVDetailsV2.vue";
import UserCCTVDetailsV3 from "@/components/body/UserDetails/UserCCTVDetailsV3.vue";
import UserCCTVDetailsV4 from "@/components/body/UserDetails/UserCCTVDetailsV4.vue";
import UserCCTVDetailsV5 from "@/components/body/UserDetails/UserCCTVDetailsV5.vue";
import UserDocuments from "@/components/body/UserDetails/UserDocuments.vue";
import UserDocumentsV2 from "@/components/body/UserDetails/UserDocumentsV2.vue";
import UserDocumentsV4 from "@/components/body/UserDetails/UserDocumentsV4.vue";
import UserDocumentsV6 from "@/components/body/UserDetails/UserDocumentsV6.vue";
import UserDocumentsV7 from "@/components/body/UserDetails/UserDocumentsV7.vue";
import UserDocumentsV8 from "@/components/body/UserDetails/UserDocumentsV8.vue";

import { AuthService } from "@/services/auth.service";
import { useUserModule } from "@/store/user-module";
import { GSARService } from "@/services/gasr.service";
import { IShowAcknowledgement } from "@/common/common.model";

import CommonMixin from "@/mixins/common.mixin";
import VueScrollTo from "vue-scrollto";
import { DateTime } from "luxon";

export default defineComponent({
  name: "UserDetails",
  mixins: [CommonMixin],
  components: {
    "v-usercontactdetails": UserContactDetails,
    "v-usercontactdetailsv2": UserContactDetailsV2,
    "v-usercontactdetailsv3": UserContactDetailsV3,
    "v-usercontactdetailsv4": UserContactDetailsV4,
    "v-usercontactdetailsv5": UserContactDetailsV5,
    "v-usercontactdetailsv6": UserContactDetailsV6,
    "v-usercontactdetailsv7": UserContactDetailsV7,
    "v-usercctvdetails": UserCCTVDetails,
    "v-usercctvdetailsv2": UserCCTVDetailsV2,
    "v-usercctvdetailsv3": UserCCTVDetailsV3,
    "v-usercctvdetailsv4": UserCCTVDetailsV4,
    "v-usercctvdetailsv5": UserCCTVDetailsV5,
    "v-userrelationship": UserCustEmpRelationship,
    "v-userrelationshipv2": UserCustEmpRelationshipV2,
    "v-userrelationshipv4": UserCustEmpRelationshipV4,
    "v-userrelationshipv5": UserCustEmpRelationshipV5,
    "v-userdocuments": UserDocuments,
    "v-userdocumentsv2": UserDocumentsV2,
    "v-userdocumentsv4": UserDocumentsV4,
    "v-userdocumentsv6": UserDocumentsV6,
    "v-userdocumentsv7": UserDocumentsV7,
    "v-userdocumentsv8": UserDocumentsV8,
  },

  computed: {
    isDisabled(): boolean {
      if (
        this.isFormVersioningRequired &&
        this.isMappingExistsForCurrentBanner &&
        this.initModel.ColleagueDeclaration !== null &&
        this.initModel.ColleagueDeclaration !== undefined
      ) {
        return !(
          this.initModel.Declaration || this.initModel.ColleagueDeclaration
        );
      }

      return !this.initModel.Declaration;
    },

    isFormVersioningRequired(): boolean {
      return this.initModel.formVersion > 1;
    },

    isMappingExistsForCurrentBanner(): boolean {
      return (
        this.initModel.ComponentMappings != null &&
        this.initModel.ComponentMappings.length > 0
      );
    },

    isCCTVSectionLoaded(): boolean {
      if (
        this.$refs.CCTVOption === undefined ||
        this.$refs.CCTVOption === null
      ) {
        return false;
      }
      return true;
    },
  },

  watch: {
    isRequestAuthorized: {
      handler(val) {
        if (val) {
          let styleCode =
            this.$route.query.Session?.toString() ||
            this.$route.query.session?.toString();
          if (styleCode === undefined) {
            this.isRequestAuthorized = false;
            return;
          }
          const service = new GSARService();
          service
            .initialise(styleCode)
            .then((response) => {
              if (
                response.data !== undefined &&
                response.data !== null &&
                response.data.Countries.length === 0
              ) {
                this.isRequestAuthorized = false;
                this.logError(
                  this.translate("validationtext.validationLoadingForm")
                );
                this.scrollToTop();
                return;
              }

              this.initModel.CountriesList = response.data.Countries;
              this.initModel.locale = response.data.Countries[0].LanguageCode;
              this.initModel.brandPlcName = response.data.Countries[0].OpcoName;
              let cultureInfo = response.data.Countries[0].CultureInfo;
              let language = cultureInfo.substring(0, cultureInfo.indexOf("-"));

              // Change the environment language to match that from the service result
              this.$i18n.locale = language;

              this.initModel.formVersion = response.data.FormVersion;
              this.initModel.languageVersion = response.data.LanguageVersion;
              this.initModel.ComponentMappings =
                response.data.ComponentMappings;

              this.initModel.datepickerLang = "en";
              if (!!language) {
                this.initModel.datepickerLang = language;
              }
            })
            .catch((error) => {
              if (error.response.status == 401) {
                this.isRequestAuthorized = false;
                this.logError(
                  this.translate("validationtext.validationNotAuth")
                );
              } else {
                this.logError(
                  this.translate("validationtext.validationSubmitForm")
                );
              }
            });
        }
      },
      deep: true,
    },
  },

  data() {
    return {
      isRequestAuthorized: false,
      initModel: new SARLegalInitModel(),
      newUser: new SARLegalUserModel(),
      userContactDetailsVersion: 1,
      userCustEmpRelationshipVersion: 1,
      userCCTVDetailsVersion: 1,
      userDocumentsVersion: 1,
      childComponentSection: ChildComponentSection,
      styleCode: {} as string | undefined,
    };
  },

  created() {
    this.styleCode =
      this.$route.query.Session?.toString() ||
      this.$route.query.session?.toString();

    this.initModel.isDisabled = false;
    this.initModel.modelErrors = [];
    this.getToken();
  },

  methods: {
    getToken() {
      const service = new AuthService();
      service
        .getCustomerAuthToken()
        .then((response) => {
          const store = useUserModule();
          store.state.jwtTokenAuthCustomer = response.data;
          this.isRequestAuthorized = true;
        })
        .catch((error) => {
          this.isRequestAuthorized = false;
          this.logError(this.translate("validationtext.validationLoadingForm"));
          this.scrollToTop();
        });
    },

    logError: function (errorText: any) {
      this.initModel.modelErrors = [];
      this.initModel.modelErrors.push(errorText);
    },

    addUserDetails() {
      this.initModel.modelErrors = this.validateUserInput();
      if (!this.initModel.modelErrors.length) {
        this.newUser.StyleCode =
          this.$route.query.Session?.toString() ||
          this.$route.query.session?.toString();
        this.setNewUserStoreDateTimeOfVisit();
        this.setNewUserTitle();
        this.setNewUserStartEndDate();
        this.newUser.FormVersion = this.initModel.formVersion;
        this.newUser.LanguageVersion = this.initModel.languageVersion;
        this.newUser.SubmittedOnBehalfOfCustomer =
          this.initModel.ColleagueDeclaration;

        const service = new GSARService();
        service
          .post(this.newUser)
          .then((response) => {
            if (
              response.data.SubCode != 4011 &&
              response.data.SubCode != 4012
            ) {
              this.initModel.SARID = response.data.SARID;
              this.initModel.modelErrors = [];
              this.resetForm();

              if (response.data.SARID) {
                let eventData: IShowAcknowledgement = {
                  isAcknowledgementVisible: true,
                  ackSARId: response.data.SARID,
                };
                this.$emit("showAcknowledgementComponent", eventData);
              } else
                this.logError(
                  this.translate("validationtext.validationSubmitForm")
                );
            } else {
              this.logError(
                this.translate("validationtext.validationSubmitForm")
              );
            }

            this.scrollToTop();
          })
          .catch((error) => {
            if (error.response !== undefined && error.response.status === 401) {
              this.logError(this.translate("validationtext.validationNotAuth"));
            } else
              this.logError(
                this.translate("validationtext.validationSubmitForm")
              );
            this.scrollToTop();
          });
      } else {
        this.scrollToTop();
      }
    },

    setNewUserTitle() {
      if (
        this.initModel.selectedTitleValue != "" &&
        this.initModel.selectedTitleValue != -1 &&
        this.initModel.selectedTitleValue != 5
      ) {
        let dropdownKey = this.initModel.TitleList.filter((x) => {
          return x.value == this.initModel.selectedTitleValue;
        })[0].resourceKeyVal;
        let label: string = dropdownKey.startsWith("labeltext")
          ? this.translate(dropdownKey)
          : this.translate(`labeltext.${dropdownKey}`);
        this.newUser.Title = label;
      } else if (this.initModel.selectedTitleValue == 5)
        this.newUser.Title = this.initModel.selectedOtherTitle;
    },

    setNewUserStoreDateTimeOfVisit() {
      if (this.newUser.CCTVDetailsRequired.toString() === "true") {
        let startDateTimeOfVisit = this.getVisitStartDateTime();
        let endDateTimeOfVisit = this.getVisitEndDateTime();

        this.initModel.StoreDateofvisit = startDateTimeOfVisit;
        this.newUser.VisitDateTime = this.initModel.StoreDateofvisit;

        if (this.isFormVersioningRequired) {
          this.initModel.StoreStartDateofvisit = startDateTimeOfVisit;
          this.newUser.VisitStartDateTime = startDateTimeOfVisit;

          this.initModel.StoreEndDateofvisit = endDateTimeOfVisit;
          this.newUser.VisitEndDateTime = endDateTimeOfVisit;
        }
      }
    },

    getVisitStartDateTime(): string {
      let date = !this.initModel.StoreDateofvisit
        ? DateTime.fromJSDate(new Date(1900, 0, 1))
        : DateTime.fromJSDate(new Date(this.initModel.StoreDateofvisit));

      let hour = !this.initModel.storeStartHourOfVisit
        ? "00"
        : this.initModel.storeStartHourOfVisit;
      let minute = !this.initModel.storeStartMinuteOfVisit
        ? "00"
        : this.initModel.storeStartMinuteOfVisit;

      let startDateTime = `${date.toFormat("yyyy/MM/dd")} ${hour}:${minute}:00`;

      return startDateTime;
    },

    getVisitEndDateTime(): string {
      let date = !this.initModel.StoreDateofvisit
        ? DateTime.fromJSDate(new Date(1900, 0, 1))
        : DateTime.fromJSDate(new Date(this.initModel.StoreDateofvisit));

      let hour = !this.initModel.storeEndHourOfVisit
        ? "00"
        : this.initModel.storeEndHourOfVisit;
      let minute = !this.initModel.storeEndMinuteOfVisit
        ? "00"
        : this.initModel.storeEndMinuteOfVisit;

      let endDateTime = `${date.toFormat("yyyy/MM/dd")} ${hour}:${minute}:00`;

      return endDateTime;
    },

    setNewUserStartEndDate() {
      if (
        this.newUser.StartDT !== null ||
        this.newUser.StartDT !== undefined ||
        this.newUser.StartDT !== ""
      )
        this.newUser.StartDT = this.formatDate(this.newUser.StartDT);
      if (
        this.newUser.EndDT !== null ||
        this.newUser.EndDT !== undefined ||
        this.newUser.EndDT !== ""
      )
        this.newUser.EndDT = this.formatDate(this.newUser.EndDT);
    },

    validateUserInput(): string[] {
      let errors: string[] = [];

      if (this.newUser.RequestType.length === 0)
        errors.push(
          this.getLocalTranslatedValue("validationtext.validationReqType")
        );
      if (!this.initModel.selectedTitleValue)
        errors.push(
          this.getLocalTranslatedValue("validationtext.validationTitle")
        );
      else if (
        this.initModel.selectedTitleValue == "5" &&
        !this.initModel.selectedOtherTitle
      )
        errors.push(this.translate("validationtext.validationOtherTitle"));
      if (!this.newUser.FirstName.trim())
        errors.push(
          this.getLocalTranslatedValue("validationtext.validationForename")
        );
      if (!this.newUser.LastName.trim())
        errors.push(
          this.getLocalTranslatedValue("validationtext.validationSurname")
        );
      if (!this.newUser.EmailAddress.trim())
        errors.push(
          this.getLocalTranslatedValue("validationtext.validationEmailAddress")
        );
      else if (!this.validateEmail(this.newUser.EmailAddress))
        errors.push(
          this.getLocalTranslatedValue("validationtext.validationValidEmailAdd")
        );
      if (!this.newUser.CCTVDetailsRequired && this.isCCTVSectionLoaded)
        errors.push(this.translate("validationtext.validationCCTV"));
      if (
        this.newUser.StartDT &&
        this.newUser.EndDT &&
        this.newUser.EndDT < this.newUser.StartDT
      )
        errors.push(
          this.getLocalTranslatedValue(
            "validationtext.validationEndDateLessThanStartDate"
          )
        );
      if (
        !this.checkIfDateGreaterThanPresentDate(this.initModel.StoreDateofvisit)
      )
        errors.push(
          this.getLocalTranslatedValue(
            "validationtext.validationStoreVisitDate"
          )
        );

      return errors;
    },

    validateEmail(email: string) {
      var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    showErrorMessageForFileUpload(errorData: any[]) {
      this.initModel.modelErrors = errorData;
    },

    resetForm() {
      this.newUser = new SARLegalUserModel();
      this.resetCurrentAddress();
      this.resetPreviousAddress();
      this.newUser.RequestType = [];
      this.resetInitModel();
    },

    resetCurrentAddress: function () {
      this.newUser.CurrentAddress = {
        AddressLine1: "",
        AddressLine2: "",
        AddressLine3: "",
        Town: "",
        County: "",
        Country: "",
        PostCode: "",
      };
    },

    resetPreviousAddress: function () {
      this.newUser.PreviousAddress = {
        AddressLine1: "",
        AddressLine2: "",
        AddressLine3: "",
        Town: "",
        County: "",
        Country: "",
        PostCode: "",
      };
    },

    resetInitModel: function () {
      this.initModel.storeTime = "";
      this.initModel.employeeStartDate = "";
      this.initModel.locale = "";
      this.newUser.CCTVDetailsRequired = "";

      this.newUser.IsCCTVStore = "";
      this.newUser.IsCCTVVehicle = "";
      this.newUser.IsEmployeeCheckbox = "";
      this.newUser.IsCustomerCheckbox = "";
      this.initModel.StoreDateofvisit = "";
      this.initModel.StoreTimeofvisit = "";
      this.initModel.Declaration = false;
      this.newUser.PrevAddress = "";
      this.initModel.storeStartHourOfVisit = "";
      this.initModel.storeStartMinuteOfVisit = "";
      // TODO: These properties do not seem to exist on this object
      //this.initModel.sessionLastActivity = "";
      //this.initModel.sessionIntervalID = "";
      this.initModel.selectedTitleValue = "";
      this.initModel.selectedOtherTitle = "";
      this.newUser.IsCCTVCarPark = "";
      this.initModel.storeStartHourOfVisit = "";
      this.initModel.storeStartMinuteOfVisit = "";
      this.initModel.storeEndHourOfVisit = "";
      this.initModel.storeEndMinuteOfVisit = "";
    },

    isComponentVersionUpdateRequired: function (componentName: any) {
      let updateRequired = false;
      let componentVersion = 1;

      if (
        this.isFormVersioningRequired &&
        this.isMappingExistsForCurrentBanner
      ) {
        this.initModel.ComponentMappings.forEach((value, index, array) => {
          if (
            value.ComponentName === componentName &&
            value.ComponentVersion > 1
          ) {
            updateRequired = true;
            componentVersion = value.ComponentVersion;
          }
        });

        if (updateRequired) {
          switch (componentName) {
            case ChildComponentSection.PartA:
              this.userContactDetailsVersion = componentVersion;
              break;
            case ChildComponentSection.PartB:
              this.userCustEmpRelationshipVersion = componentVersion;
              break;
            case ChildComponentSection.PartC:
              this.userCCTVDetailsVersion = componentVersion;
              break;
            case ChildComponentSection.PartD:
              this.userDocumentsVersion = componentVersion;
              break;
          }
        }
      }

      return updateRequired;
    },

    getLocalTranslatedValue(label: string, param: string = "") {
      let value = null;

      let localVersion =
        this.styleCode && !!STYLEVERSIONMAP.data[this.styleCode.toLowerCase()]
          ? STYLEVERSIONMAP.data[this.styleCode.toLowerCase()]
          : "";

      let isLocalNewerSFX = localVersion && Array.isArray(localVersion);
      if (isLocalNewerSFX) {
        for (let i = 0; i < localVersion.length; i++) {
          try {
            let newLabel = `${label}${localVersion[i] ? localVersion[i] : ""}`;
            value = this.$t(newLabel);
            if (newLabel !== value) {
              break;
            } else {
              value = null;
            }
          } catch (error) {}
        }
      }

      let defaultValue = "";
      try {
        if (value === null || value === undefined) {
          defaultValue = this.$t(label);
          if (param.length > 0) {
            defaultValue = defaultValue.replace(defaultValue, param);
          }
          defaultValue = defaultValue?.trim();
        } else {
          defaultValue = value;
        }
      } catch (error) {}

      return defaultValue;
    },

    scrollToTop() {
      VueScrollTo.scrollTo("#top", 0);
    },
  },
});
</script>
