export default {
    validationtext: {
        validationHeading:'Bitte korrigieren Sie die folgende Fehler:',
        validationReqType: 'Bitte geben Sie mindestens einen Anfragetyp an',
        validationTitle: 'Bitte geben Sie eine Anrede an',
        validationOtherTitle: 'Bitte geben Sie einen Wert in das Feld "Andere Anrede" ein',
        validationForename: 'Bitte geben Sie Vorname an',
        validationSurname: 'Bitte geben Sie einen Nachnamen an',
        validationEmailAddress: 'Bitte geben Sie eine E-Mail-Adresse an',
        validationCCTV: 'Bitte bestätigen Sie, ob CCTV-Aufnahmen benötigt werden',
        validationValidEmailAdd: 'Bitte geben Sie eine gültige E-Mail-Adresse an',
        validationDocType:'Bitte wählen Sie einen Dokumenttyp vor dem Hochladen',
        validationSelectFile: 'Bitte wählen Sie eine Datei vor dem Hochladen',
        validationLoadingForm: 'Beim Laden des Formulars ist ein Fehler aufgetreten. Bitte versuchen Sie es nach einiger Zeit erneut',
        validationNotAuth: 'Sie sind nicht berechtigt, diese Seite anzuzeigen',
        validationSubmitForm: 'Beim Senden des Formulars sind einige Fehler aufgetreten. Bitte versuche es später erneut',
        validationRetreiveDetails: 'Beim Wiederherstellen der Daten sind einige Fehler aufgetreten. Bitte versuche es später erneut',
        validationDownloadFile: 'Beim Herunterladen der Datei ist ein Fehler aufgetreten. Bitte versuche es später erneut',
        validation404: "Die angeforderte Quelle konnte nicht gefunden werden",
        validationMaxFileSize: 'Dateigröße überschreitet das maximal zulässige Limit von {filesize}',
        validationFileUploadFailed: 'Beim Hochladen der Datei sind einige Fehler aufgetreten. Bitte versuche es später erneut',
        validationAllowedFileTypes: 'Zulässige Dateitypen für den Upload sind {allowedFileExtensions}. Bitte lade eine Datei mit der kompatiblen Dateierweiterung hoch',
        validationStartDate: "Das Anfangsdatum darf nicht nach dem heutige Datum liegen",
        validationEndDate: "Enddatum darf nicht nach dem heutige Datum liegen",
        validationStoreVisitDate: "Das Datum des Besuchs darf nicht nach dem heutige Datum liegen",
        validationEndDateLessThanStartDate: "Enddatum darf nicht vor dem Anfangsdatum liegen",
    
        validationNoRecordsFound: "Für die eingegebenen Suchkriterien wurden keine Datensätze gefunden",
        validationSearchFailed: 'Einige Fehler sind aufgetreten. Bitte versuche es später nochmal',
    
        validationAddNotes: "Bitte geben Sie eine Notiz",
        validationAddNotesError:"Einige Fehler ist aufgetreten, während Notizen hinzufügen. Bitte versuchen Sie es später noch einmal",
        validationDocTitle: "Bitte geben Sie einen Dokumenttitel",
    
        validationAddTaskDescription: "Beschreibung darf nicht leer sein",
        validationAddTaskAssignee: "Abtretungsempfänger darf nicht leer sein",
        validationAddTaskError:"Einige Fehler aufgetreten Aufgabe hinzufügen. Bitte versuchen Sie es später noch einmal",
    
        
        validationconsenthistory: "Bitte geben Sie eine Einwilligung id Details retreive",
        validationConsentHistoryGrid: "Einige Fehler aufgetreten, während Zustimmung Geschichte retreiving. Bitte versuchen Sie es später noch einmal.",
        validationNoRecordsFoundConsentHstry: 'Keine Aufzeichnungen für die eingegebene Kundenreferenz ID gefunden'
    },
    labeltext: {
        dataProtectionAct: 'Datenschutzgesetz',
        dataSAR: 'Anfrageformular für betroffene Personen',
        completedByWarning:'Dieses Formular muss von der Person ausgefüllt werden, vor der die angefragten Daten stammen',
        typeOfRequest: '*Art der Anfrage',
        requestDataAccess: 'Anfrage auf den Zugriff auf Daten',
        requestDataDelete: 'Anfrage auf Löschung/Entfernung von Daten',
        requestDataRect: 'Anfrage auf Datenberichtigung',
        requestDataObject: 'Antrag auf Widerspruch gegen die Datenverarbeitung',
        partAHeadingText: 'Teil A: Ihre Daten',
        partABodyText: 'Bitte füllen Sie das folgende Formular aus. Die von Ihnen zur Verfügung gestellten Informationen werden von {brandPlcName1} verwendet, um diese Zugriffsanfrage zu bearbeiten und zu verwalten. {brandPlcName2} kann eine dritte Partei zur Bearbeitung Ihrer Anfrage verwenden und muss gegebenenfalls Ihre persönlichen Daten an diese Drittpartei weitergeben',
        title: '*Anrede',
        titleDefaultValue: 'Bitte wählen Sie eine Anrede',
        otherTitleLabel:'*Wenn Andere, bitte angeben',
        otherTitlePlc: 'Andere Anrede',
        surname: '*Nachname / Familienname',
        surnameV2: '*Nachname / Familienname',
        forename: '*Vorname',
        forenameV2: '*Vorname',
        currentAddress: 'Aktuelle Adresse',
        addline1Plc:'Adresszeile 1',
        addLine2Plc: 'Adresszeile 2',
        addLine3Plc: 'Adresszeile 3',
        townPlc: 'Stadt',
        countyPlc: 'Landkreis',
        countryDefaultValue: 'Bitte wählen Sie ein Land',
        postCode: 'Postleitzahl',
        postCodeV2: 'Postleitzahl',
        prevAddressWarning: 'Möchten Sie eine frühere Adresse angeben, unter der weitere personenbezogene Daten gespeichert sind?',
        prevAddress: 'Vorherige Adresse',
        emailAddress: '*E-Mail-Adresse',
        contactNumber: 'Telefonnummer',
        custEmpRel:'Bitte geben Sie Ihre Beziehung an',
        relationshipDetailsText: 'Please indicate the relationship the subject of the personal data has with {brandPlcName1} and provide the further information requested.',
        relationshipDetailsTextV2: 'Please indicate the relationship the subject of the personal data has with {brandPlcName1} and provide the further information requested.',
        customer:'Kunde',
        employee:'Mitarbeiter (Beinhaltet aktuelle und ehemalige Mitarbeiter)',
        custNo:'Kundennummer',
        orderNo: 'Bestellnummer',
        complainRef:' Beschwerde-Referenz',
        empNo: 'Mitarbeiternummer',
        startDate: 'Anfangsdatum',
        endDate:'Austrittsdatum (falls zutreffend)',
        storeHeadOff: 'Funktion in Geschäft / Zentrale',
        partBHeaderText:'Teil B: Bitte geben Sie unten Details Ihrer Anfrage an (für CCTV bitte Teil C ausfüllen)',
        reqDetailsText:'Please provide any further relevant details regarding your request:',
        reqDetailsPlc: 'Details der Anfrage',
        partCHeaderText: 'Teil C: CCTV-Aufnahmen',
        cctvfootageReq: '*CCTV-Aufnahmen erforderlich',
        yesText: 'Ja',
        noText: 'Nein',
        yesStoreVehicle: 'Wenn ja, hängt das zusammen mit ',
        store: 'Geschäft',
        vehicle: 'Fahrzeug',
        storeConcerned: 'betroffenes Geschäft',
        dateOfVisit:'Datum des Besuchs',
        timeOfVisit: 'Zeitpunkt des Besuchs',
        hour: 'Stunde',
        minute: 'Minute',
        clothAtVisit: 'getragene Kleidung zum Zeitpunkt des Besuchs',
        vehicleMake:'Fahrzeugmarke',
        model: 'Modell',
        colour: 'Farbe',
        regNo: 'Kennzeichen',
        attachments: 'Anhänge',
        partDHeadingText: 'Teil D: Ausfüllen des Formulars',
        partDBodyText: 'Sofern wir nicht anderem zustimmen, müssen Ihrem Antrag Kopien von zwei offiziellen Dokumenten beiliegen, aus denen eindeutig Ihr Name und Ihre aktuelle Adresse hervorgehen, um {brandPlcName1} hinsichtlich Ihrer Identität zu entsprechen. Eines dieser Dokumente muss ein Foto von Ihnen enthalten. Beispiele für eine geeignete Identifizierung: Kopien des aktuellen Führerscheins, des Reisepasses und der Kopien von Steuererklärungen oder Erklärungen der Gemeindeverwaltung, die innerhalb der letzten drei Monate vor dem Datum Ihrer Anfrage erstellt wurden',
        authThirdParty:'Wenn Sie eine dritte Partei dazu ermächtigt haben, eine Anfrage zu stellen oder Ihre persönlichen Daten in Ihrem Namen zu erhalten (z. B. ein Rechtsanwalt), legen Sie bitte auch eine Kopie des Dokuments vor, das diese Ermächtigung nachweist',
        docTypeDefaultValue: 'Bitte wählen Sie einen Dokumententyp',
        idProof: 'Identitäsnachweis',
        addProof: 'Adressnachweis',
        authDoc:'Ermächtigung',
        otherDoc:'Andere',
        chooseFile: 'Wählen Sie Datei',
        upload: 'Hochladen',
        declaration: 'Erklärung',
        declarationText: 'Die in diesem Antragsformular zur Verfügung gestellten Informationen sind richtig und ich bindie Person, welche sie betreffen. Ich verstehe, dass sich meine Anfrage verzögert, wenn ich dieses Bewerbungsformular nicht korrekt ausfülle',
        cancel: 'Abbrechen',
        print: 'Drucken',
        submit: 'Absenden',
        ackText: 'Ihre Anfrage wurde erfolgreich gesendet',
        reqID: 'Anfrage-ID#:',
        makeNewReq: 'Eine weitere Anfrage machen',
        fileUploadSuccessful: 'Datei wurde erfolgreich hochgeladen',
        selectDatePlc: 'Wählen Sie ein Datum',
        addtionalRequestTypeText: 'Für andere Anfragetypen geben Sie bitte die Details der Anfrage in Teil B des Formulars an',
        listUploadedFiles: 'Hochgeladene Dateien:',
        Mr: 'Herr',
        Mrs:'Frau',
        Miss: 'Fräulein',
        Ms: '',
        OtherTitle: 'Andere',
        partA: 'Teil A: ',
        partB: 'Teil B: ',
        partBV2: 'Teil B: ',
        partC: 'Teil C: ',
        partD: 'Teil D: ',
        cctvHeader:'CCTV-Aufnahmen',
        formCompletionHeader: 'Ausfüllen des Formulars',
            
        reportRequestID: 'Anfrage-ID',
        reportEmailID: 'E-Mail Adresse',
        reportLastName: 'Nachname des Kunden',
        reportBrandName: 'Markenname',
        reportStartDate: 'Anfangsdatum',
        reportEndDate: 'Enddatum',
        reportStatus: 'Status',
        reportBrandNameDefaultValue: 'Wählen Sie einen Marken',
        reportStatusDefaultValue: 'Wählen Status',
    
        reportHeaderName: 'NAME',
        reportHeaderReceivedType: 'erhalten TYPE',
        reportHeaderReceivedDate: 'eRHALTEN DT',
        reportHeaderAccessData: 'ZUGANGSDATEN',
        reportHeaderDataRect: 'Datenkorrektur',
        reportHeaderDataDel: 'DATA STREICHUNG',
        reportHeaderObject: 'RIGHT TO OBJECT',
        reportHeaderCompletedDate: 'aBGESCHLOSSEN DT',
        
        reportSearch: 'Suche',
        reportDownload: 'Herunterladen',
        reportRecords: 'Aufzeichnungen',
        reportsYes:'Y',
        reportsNo:'N',
        reportsRecords:'Showing xxx to xxx of records',
        reportPaginationText:"Zeige {from} bis {to} von {count} Aufzeichnungen | {count} Datensätze | 1 Datensatz",
    
        NavigationHome: 'Zuhause',
        NavigationReports: 'Berichte',
    
        requestStatus: 'Status der Anfrage',
        assignTo :'Zuweisen zu',
        requestArrivalType:'Anfrage Ankunftsart',
        SARSubmittedDate:'SAR Übermitteltes Datum',
        actualStartDate:'Tatsächliches Anfangsdatum',
        expectedCompletionDate:'Voraussichtlicher Fertigstellungstermin',
        actualCompletionDate:'Tatsächliches Fertigstellungstermin',
        notes:'Notizen',
        historicalNotes:'Historische Notizen',
        originalSARData: 'Original SAR-Daten sind als unten',
    
        Submitted:'Eingereicht',
        InProgress:'In Bearbeitung',
        OnHold:'Wartet',
        ResponseSent:'Antwort gesendet',
        Complete:'Fertig',
        Open: 'Öffnen',
    
        Web:'Web',
        Phone:'Telefon',
        SocialMedia:'Soziale Medien',
        Post:'Posten',
        Email:'E-Mail',
    
        
        doctype: 'Dokumenttyp',
        selectUpload : 'Datei auswählen und hochladen',
        noFile : 'Keine Datei ausgewählt',
    
        jan :	'Januar',
        feb :	'Februar', 
        mar :	'März',
        apr :	'April',
        may :	'Kann',
        jun :	'Juni',
        jul :	'Juli',
        aug :	'August',
        sep :	'September',
        oct :	'Oktober',
        nov :	'November',
        dec :	'Dezember',
        sun :	'Sonntag',
        mon :	'Montag',
        tue :	'Dienstag',
        wed :	'Mittwoch',
        thu :	'Donnerstag',
        fri :	'Freitag',
        sat :	'Samstag',
        
        tooltipAccessRequest: "Zugriffsanfragen: Sie sind berechtigt, vorbehaltlich bestimmter Ausnahmen eine Kopie Ihrer bei {brandPlcName1} gespeicherten persönlichen Daten zu erhalten. Alle Informationen, die Ihnen zur Verfügung gestellt werden, sind hinsichtlich der persönlichen Daten von Dritten geschwärzt, um die Privatsphäre des Dritter zu schützen, wenn wir nicht eine Zustimmung von diesen Dritten haben oder ist es unter den gegebenen Umständen angemessen ist, die personenbezogene Daten des Dritten zur Verfügung zu stellen.",
        tooltipDataDeletion: "Anfragen auf Löschung von Daten: Sie haben möglicherweise das Recht, dass Ihre bei {brandPlcName1} gespeicherten persönlichen Daten gelöscht werden. Bitte beachten Sie, dass dies nicht in allen Fällen gilt, beispielsweise dann wenn wir rechtlich dazu verpflichtet sind, Ihre Daten aufzubewahren, oder wenn die Daten im Rahmen einer rechtlichen Auseinandersetzung benötigt werden.",
        tooltipDataObject: "Anträge auf Widerspruch gegen die Datenverarbeitung: Sie haben das Recht der Verarbeitung Ihrer persönlichen Daten durch {brandPlcName1} für Direktmarketingzwecke zu widersprechen. Sie können auch der Verarbeitung Ihrer persönlichen Daten durch {brandPlcName2} für andere Zwecke widersprechen, wobei dies nicht gilt, sofern wir rechtlich dazu verpflichtet sind, Ihre Daten aufzubewahren, oder wenn die Daten im Rahmen einer rechtlichen Auseinandersetzung benötigt werden.",
        tooltipDataRectification: "Anfragen auf Datenberichtigung: Sie haben das Recht, Ihre persönlichen Daten welche von {brandPlcName1} gespeichert werden, im Falle eines Fehlers, zu korregieren. Sie können uns auch bitten, Ihre persönlichen Daten zu vervollständigen, wenn diese unvollständig sind.",
    
        sarmgmtAddNote: "Notizen hinzufügen",
        sarmgmtAddNotesButton: "Hinzufügen",
        sarmgmtCancelNotes: "Stornieren",
        sarmgmtDocTitle: 'Dokumenttitel',
        sarmgmtUploadDoc: 'Laden eines Dokuments',
        sarmgmtattachmentUploadedBy: "Hochgeladen von {uploadedby} auf {timestamp}",
    
        sarmgmtAddTask: "In Tasks",
        sarmgmtViewTask: "anzeigen von Aufgaben",	
        sarmgmtCancelTasks: "ausblenden Aufgabe",
        TaskID:"Aufgabe #",
        Description:"Beschreibung",
        Assignee:"Zessionar",
        CreatedDT:"Erstellt",
        CompletedDT:"Abgeschlossen",
        Comment:"Kommentar",
        taskStatus: "Status",
        
        taskDeleteMessage: "Bitte bestätigen Sie die Aufgabe löschen",
        taskDelete: "Löschen",
        taskSave: 'sparen',
        sarmgmtAddNotes: 'hat einen Kommentar',
        
        consentCustomerId: "Kundennummer",
        consentMarketingTextHeader: "Customer Marketing Zustimmung: ",
        consentTooltipCustRef: "Kunden-ID: Bezieht sich auf die ID, gegen die der Zustimmung des Kunden in der BIPR Zustimmung Repository gespeichert ist. Diese ID kann eine eindeutige Referenznummer, Loyalität Nummer, E-Mail oder Telefonnummer sein. Bitte beachten Sie Leitlinien für die Kunden-ID für BIPR Repository für Ihren jeweiligen Markt.",
    
        consentHstryCustomerId : 'Kundennummer',
        consentHstryOpcoName: 'Markenname',
        consentHstryChannel: 'Kanal',
        consentHstryPrivacyPolicyVerId: 'Datenschutz Ver Id',
        consentHstryMarketingChannelVersion: 'Marketing-Kanal Version',
        consentHstryOptIn: 'Opt In',
        consentHstrySubmitedDate: 'Eingesetzte Datum',
        consentHstrySource: 'Quelle',
        consentHstrySubmittedBy: 'Eingereicht von',
        consentHstryfileDownloadName: 'Kunden Zustimmung report.csv',
        consentHistoryButton: "Erhalten Sie Einwilligung Geschichte",
        taskEdit: 'Bearbeiten'
    },    
}