import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-23082fd1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "form-field form-field--gd grid__item" }
const _hoisted_3 = { class: "field-row" }
const _hoisted_4 = {
  "data-auto": "reportrequestID-lbl",
  class: "label label--text label--inline"
}
const _hoisted_5 = { class: "form-field form-field--gd grid__item" }
const _hoisted_6 = { class: "field-row" }
const _hoisted_7 = {
  "data-auto": "reportemailID-lbl",
  class: "label label--text label--inline"
}
const _hoisted_8 = { class: "form-field form-field--gd grid__item" }
const _hoisted_9 = { class: "field-row" }
const _hoisted_10 = {
  "data-auto": "reportlastname-lbl",
  class: "label label--text label--inline"
}
const _hoisted_11 = { class: "form-field form-field--gd grid__item" }
const _hoisted_12 = { class: "field-row" }
const _hoisted_13 = {
  "data-auto": "reportbrandname-lbl",
  class: "label label--text label--inline"
}
const _hoisted_14 = { value: "" }
const _hoisted_15 = ["value"]
const _hoisted_16 = { class: "form-field form-field--gd grid__item" }
const _hoisted_17 = { class: "field-row" }
const _hoisted_18 = {
  "data-auto": "reportstartdate-lbl",
  class: "label label--text label--inline"
}
const _hoisted_19 = { class: "form-field form-field--gd grid__item" }
const _hoisted_20 = { class: "field-row" }
const _hoisted_21 = {
  "data-auto": "reportenddate-lbl",
  class: "label label--text label--inline"
}
const _hoisted_22 = { class: "form-field form-field--gd grid__item" }
const _hoisted_23 = { class: "field-row" }
const _hoisted_24 = {
  "data-auto": "reportstatus-lbl",
  class: "label label--text label--inline"
}
const _hoisted_25 = { value: "" }
const _hoisted_26 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_date_picker = _resolveComponent("date-picker")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("labeltext.reportRequestID")), 1),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.reportModel.SARID) = $event)),
            name: "reportRequestID"
          }, null, 512), [
            [_vModelText, _ctx.reportModel.SARID]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("label", _hoisted_6, [
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("labeltext.reportEmailID")), 1),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.reportModel.EmailAddress) = $event)),
            name: "reportEmailAddress"
          }, null, 512), [
            [_vModelText, _ctx.reportModel.EmailAddress]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("label", _hoisted_9, [
          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t("labeltext.reportLastName")), 1),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.reportModel.LastName) = $event)),
            name: "reportLastName"
          }, null, 512), [
            [_vModelText, _ctx.reportModel.LastName]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("label", _hoisted_12, [
          _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t("labeltext.reportBrandName")), 1),
          _withDirectives(_createElementVNode("select", {
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.reportModel.StyleId) = $event)),
            name: "reportBrandName",
            class: "fixedWidth"
          }, [
            _createElementVNode("option", _hoisted_14, _toDisplayString(_ctx.$t("labeltext.reportBrandNameDefaultValue")), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reportModel.BrandNames, (brandName, index) => {
              return (_openBlock(), _createElementBlock("option", {
                value: brandName.StyleID,
                key: index
              }, _toDisplayString(brandName.Website), 9, _hoisted_15))
            }), 128))
          ], 512), [
            [_vModelSelect, _ctx.reportModel.StyleId]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.$t("labeltext.reportStartDate")), 1),
          _createVNode(_component_date_picker, {
            modelValue: _ctx.reportModel.StartDT,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.reportModel.StartDT) = $event)),
            locale: _ctx.reportModel.reportdatepickerLang,
            "enable-time-picker": false,
            format: _ctx.dateFormat,
            "auto-apply": "",
            "hide-input-icon": ""
          }, null, 8, ["modelValue", "locale", "format"])
        ])
      ]),
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.$t("labeltext.reportEndDate")), 1),
          _createVNode(_component_date_picker, {
            modelValue: _ctx.reportModel.EndDT,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.reportModel.EndDT) = $event)),
            locale: _ctx.reportModel.reportdatepickerLang,
            "enable-time-picker": false,
            format: _ctx.dateFormat,
            "auto-apply": "",
            "hide-input-icon": ""
          }, null, 8, ["modelValue", "locale", "format"])
        ])
      ]),
      _createElementVNode("div", _hoisted_22, [
        _createElementVNode("label", _hoisted_23, [
          _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.$t("labeltext.reportStatus")), 1),
          _withDirectives(_createElementVNode("select", {
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.reportModel.StatusID) = $event)),
            name: "reportStatus",
            class: "fixedWidth"
          }, [
            _createElementVNode("option", _hoisted_25, _toDisplayString(_ctx.$t("labeltext.reportStatusDefaultValue")), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reportModel.Status, (stat, index) => {
              return (_openBlock(), _createElementBlock("option", {
                value: stat.StatusID,
                key: index
              }, _toDisplayString(_ctx.getDDLTranslatedValue(stat.ResourceKey)), 9, _hoisted_26))
            }), 128))
          ], 512), [
            [_vModelSelect, _ctx.reportModel.StatusID]
          ])
        ])
      ])
    ])
  ]))
}