<template>
  <div class="gd-user-relationship mb-gutter">
    <div class="form-field form-field--gd">
      <div class="field-row">
        <span class="label label--inline label--multi-line">
          {{
            getTranslatedValueWithParams(
              "labeltext.relationshipDetailsText",
              {
                brandPlcName1: initModel.brandPlcName,
              }
            )
          }}
        </span>
      </div>
      <div class="field-row">
        <span class="label label--text label--inline label--multi-line"></span>
        <div class="gd-field-multi-select form-field--gd__options">
          <label>
            <input
              type="checkbox"
              id="IsCustomer"
              value="true"
              name="custemprelation"
              :disabled="initModel.isDisabled"
              v-model="newUser.IsCustomerCheckbox"
              @change="clearInputFieldsCustomerEmployee"
            />
            <span class="css-checkbox"></span>
            <span
              class="label label--inline label-padding"
              data-auto="custemprelation-IsCustomer-lbl"
            >
              {{ $t("labeltext.customer") }}
            </span>
          </label>

          <label class="label--fixedwidth">
            <input
              type="checkbox"
              id="IsEmployee"
              value="true"
              name="custemprelation"
              :disabled="initModel.isDisabled"
              v-model="newUser.IsEmployeeCheckbox"
              @change="clearInputFieldsCustomerEmployee"
            />
            <span class="css-checkbox top-align"></span>
            <span
              class="label label--inline span--inherit label-padding"
              data-auto="custemprelation-IsEmployee-lbl"
            >
              {{
                getTranslatedValueWithParams("labeltext.employee", {
                  brandPlcName: initModel.brandPlcName
                })
              }}
            </span>
          </label>
        </div>
      </div>
    </div>

    <div
      class="mb-gutter"
      v-if="newUser.IsCustomerCheckbox.toString() === 'true'"
    >
      <div class="form-field form-field--gd">
        <label class="field-row">
          <span
            class="label label--text label--inline"
            data-auto="customernumber-lbl"
          >
            {{ $t("labeltext.custNo") }}
          </span>
          <input
            type="text"
            v-model="newUser.CustomerNumber"
            :disabled="initModel.isDisabled"
            :placeholder="
              initModel.isDisabled == true
                ? ''
                : getTranslatedValueEx('labeltext.custNo')
            "
            name="customernumber"
            maxlength="25"
          />
        </label>
      </div>

      <div class="form-field form-field--gd">
        <label class="field-row">
          <span
            class="label label--text label--inline"
            data-auto="ordernumber-lbl"
          >
            {{ $t("labeltext.orderNo") }}
          </span>
          <input
            type="text"
            v-model="newUser.OrderNumber"
            :disabled="initModel.isDisabled"
            :placeholder="
              initModel.isDisabled == true
                ? ''
                : getTranslatedValueEx('labeltext.orderNo')
            "
            name="ordernumber"
            maxlength="25"
          />
        </label>
      </div>

      <div class="form-field form-field--gd">
        <label class="field-row">
          <span
            class="label label--text label--inline"
            data-auto="complaintref-lbl"
          >
            {{ $t("labeltext.complainRef") }}
          </span>
          <input
            type="text"
            v-model="newUser.ComplaintRefNumber"
            :disabled="initModel.isDisabled"
            :placeholder="
              initModel.isDisabled == true
                ? ''
                : getTranslatedValueEx('labeltext.complainRef')
            "
            name="complaintref"
            maxlength="25"
          />
        </label>
      </div>
    </div>

    <div
      class="mb-gutter"
      v-if="newUser.IsEmployeeCheckbox.toString() === 'true'"
    >
      <div class="form-field form-field--gd">
        <label class="field-row">
          <span
            class="label label--text label--inline"
            data-auto="employeenumber-lbl"
          >
            {{ $t("labeltext.empNo") }}
          </span>
          <input
            type="text"
            v-model="newUser.EmployeeNumber"
            :disabled="initModel.isDisabled"
            :placeholder="
              initModel.isDisabled == true
                ? ''
                : getTranslatedValueEx('labeltext.empNo')
            "
            name="employeenumber"
            maxlength="25"
          />
        </label>
      </div>

      <div class="form-field form-field--gd">
        <div class="field-row">
          <span
            class="label label--text label--inline"
            data-auto="empstartdate-lbl"
          >
            {{ $t("labeltext.startDate") }}
          </span>
          <date-picker
            :placeholder="
              newUser.StartDT == null && initModel.isDisabled
                ? '  '
                : getTranslatedValueEx('labeltext.selectDatePlc')
            "
            v-model="newUser.StartDT"
            :disabled="initModel.isDisabled"
            :locale="initModel.datepickerLang"
            :enable-time-picker="false"
            :format="dateFormat"
            auto-apply
            hide-input-icon
            name="empstartdate"
          >
          </date-picker>
        </div>
      </div>

      <div class="form-field form-field--gd">
        <div class="field-row">
          <span
            class="label label--text label--inline"
            data-auto="empenddate-lbl"
          >
            {{ $t("labeltext.endDate") }}
          </span>
          <date-picker
            :placeholder="
              newUser.EndDT == null && initModel.isDisabled
                ? '  '
                : getTranslatedValueEx('labeltext.selectDatePlc')
            "
            v-model="newUser.EndDT"
            :disabled="initModel.isDisabled"
            :locale="initModel.datepickerLang"
            :enable-time-picker="false"
            :format="dateFormat"
            auto-apply
            hide-input-icon
            name="empenddate"
          >
          </date-picker>
        </div>
      </div>

      <div class="form-field form-field--gd">
        <label class="field-row">
          <span
            class="label label--text label--inline"
            data-auto="storehofunction-lbl"
          >
            {{ $t("labeltext.storeHeadOff") }}
          </span>
          <input
            type="text"
            v-model="newUser.OfficeFunction"
            :disabled="initModel.isDisabled"
            :placeholder="
              initModel.isDisabled == true
                ? ''
                : getTranslatedValueEx('labeltext.storeHeadOff')
            "
            name="storehofunction"
            maxlength="100"
          />
        </label>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import UserCustEmpRelationshipMixin from "@/mixins/userCustEmpRelationship.mixin";

export default defineComponent({
  name: "UserCustEmpRelationshipV5",
  mixins: [UserCustEmpRelationshipMixin],
});
</script>

<style scoped>
/* Scoped style to constrain the width of the date picker */
.dp__main {
    font-family: var(--dp-font-family);
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    box-sizing: border-box;
    position: relative;
    max-width: 49% !important;
}
</style>