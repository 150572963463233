import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import { RouteName } from '@/common/common.model'
import SARCustomer from '@/views/SARCustomer.vue'
import SARMgmt from '@/views/SARMgmt.vue'
import SARLegalTeamReports from '@/views/SARLegalTeamReports.vue'
import HomeComponent from '@/views/Home.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/sar',
        name: RouteName.SARCustomer,
        component: SARCustomer
    },
    {
        path: '/review/:sarid',
        name: RouteName.SARManagement,
        component: SARMgmt
    },
    {
        path: '/report',
        name: RouteName.SARLegalTeamReports,
        component: SARLegalTeamReports
    },
    {
        path: '/home',
        name: RouteName.SARHome,
        component: HomeComponent
    },
    {
        path: '/',
        name: RouteName.SARReportHome,
        component: SARLegalTeamReports
    }
];

const router = createRouter({
    history: createWebHashHistory(process.env.VUE_APP_PUBLIC_PATH),
    routes
});

export default router;