<template>
  <div id="viewSARReport" class="gd-user-details pt-gutter pb-gutter">
    <form>
      <div class="mb-gutter" v-if="initModel.modelErrors.length">
        <div v-if="authModel.isRequestAuthorized" class="alert alert--error">
          <span class="icon icon-alert"></span>
          <p>
            <strong>{{ $t("validationtext.validationHeading") }}</strong>
          </p>
          <ul class="list-styled">
            <li v-for="(error, index) in initModel.modelErrors" :key="index">
              {{ error }}
            </li>
          </ul>
        </div>

        <div v-else class="alert alert--error">
          <span class="icon icon-alert"></span>
          <div v-for="(error, index) in initModel.modelErrors" :key="index">
            {{ error }}
          </div>
        </div>
      </div>

      <br v-if="initModel.modelErrors.length" />

      <div v-if="authModel.isRequestAuthorized && showReportForm">
        <v-reportform v-model:reportModel="reportModel"></v-reportform>
        <button
          type="button"
          @click="getSearchResults"
          class="button button--block"
        >
          {{ $t("labeltext.reportSearch") }}
        </button>
      </div>

      <div id="resultTable" class="result-table" v-if="showResults">
        <hr class="wrap-v-big" />
        <v-data-table
          :headers="headers"
          :items="searchResult"
          :items-per-page="itemsPerPage"
          :items-per-page-options="footerProps.itemsPerPageOptions"
          :footer-props="footerProps"
          item-key="SARID"
          fixed-header
          dense
          class="table-styled table-styled-report"
        >
          <template v-slot:[`item.SARID`]="{ item }">
            <a target="_blank" :href="navigateToDetailsPage(item.GUID)">{{
              item.SARID
            }}</a>
          </template>

          <template v-slot:top>
            <div style="padding-bottom: 8px">
              {{ $t("labeltext.reportRecords") }}
            </div>
            <div style="padding-bottom: 12px">
              <select
                v-model="itemsPerPage"
                class="fixedWidth"
                @update:model-value="itemsPerPage = parseInt($event, 10)"
              >
                <option
                  v-for="(item, index) in footerProps.itemsPerPageOptions"
                  :value="item"
                  :key="index"
                >
                  {{ item }}
                </option>
              </select>
            </div>
          </template>
        </v-data-table>
        <export-excel
          :data="searchResult"
          :fields="exportColumns"
          type="csv"
          name="SAR Reports.csv"
        >
          <button type="button" class="button button--alt button--block">
            {{ $t("labeltext.reportDownload") }}
          </button>
        </export-excel>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { AttachmentFileDetail, AuthModel } from "@/common/common.model";
import { SARReportModel } from "@/models/SARReportModel";
import { SARLegalInitModel } from "@/models/SARLegalInitModel";
import { eventBus } from "@/plugins/mitt-helper";
import { GSARReportViewService } from "@/services/gsar-report-view.service";
import { GSARService } from "@/services/gasr.service";
import { FooterProps, GridDefault } from "@/common/common.model";
import { IReportView } from "@/models/service-request-response-model";

import CommonMixin from "@/mixins/common.mixin";
import SARReportForm from "@/components/body/SARReportView/SARReportForm.vue";
import VueScrollTo from "vue-scrollto";

export default defineComponent({
  name: "SARReports",
  mixins: [CommonMixin],
  components: {
    "v-reportform": SARReportForm,
  },
  watch: {
    authModel: {
      handler(val: AuthModel) {
        eventBus.emit("showHeaderFooterEvent", true);

        if (val.isRequestAuthorized) {
          const service = new GSARReportViewService();
          service
            .getReportInitialisation()
            .then((response) => {
              this.reportModel.Status = response.data.ReportStatus;
              this.reportModel.BrandNames = response.data.ReportStyle;

              let cultureInfo = response.data.CultureInfo.CultureInfo;
              let language = cultureInfo.substring(0, cultureInfo.indexOf("-"));

              // Change the environment language to match that from the service result
              this.$i18n.locale = language;

              // Build the grid headings based on the environment language
              this.buildGridHeadings();

              this.reportModel.reportdatepickerLang = "en";
              if (language == "fr") {
                this.reportModel.reportdatepickerLang = language;
              }
              this.showReportForm = true;
            })
            .catch((error) => {
              if (error.response.status == 401)
                this.unAuthorizedUser(
                  this.translate("validationtext.validationNotAuth")
                );
              else
                this.logError(
                  this.translate("validationtext.validationRetreiveDetails")
                );
              this.scrollToTop();
            });
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      page: 1,
      authModel: new AuthModel(),
      initModel: new SARLegalInitModel(),
      reportModel: new SARReportModel(),
      searchResult: Array<IReportView>(),
      showResults: false,
      exportColumns: {},
      showReportForm: false,
      itemsPerPage: GridDefault.PagingSize,
      footerProps: new FooterProps(),
      headers: [] as any,
    };
  },
  created() {
    eventBus.emit("showHeaderFooterEvent", false);

    let tempToken = this.$route.query.token?.toString();
    if (tempToken === undefined) {
      this.getTempToken("report/");
    } else {
      this.getPermanentToken(tempToken, this.authModel);
    }
  },
  methods: {
    unAuthorizedUser(errorText: string) {
      this.authModel.isRequestAuthorized = false;
      this.$emit("showErrorEvent", errorText);
      this.logError(errorText);
    },

    logError(errorText: string) {
      this.initModel.modelErrors = [];
      this.initModel.isPostSuccessful = false;
      this.initModel.modelErrors.push(errorText);
    },

    getSearchResults() {
      this.initModel.modelErrors = [];
      this.modifyDates();

      const service = new GSARReportViewService();
      service
        .getReportViewDetails(this.reportModel)
        .then((response) => {
          if (
            response.data.SubCode != "4011" &&
            response.data.SubCode != "4012"
          ) {
            this.initDownloadOptions();
            this.searchResult = response.data.ReportView;
            if (this.searchResult.length > 0) {
              this.modifySearchResult();
              this.showResults = true;
            } else {
              this.showResults = false;
              this.logError(
                this.translate("validationtext.validationNoRecordsFound")
              );
            }
          } else {
            this.logError(
              this.translate("validationtext.validationSearchFailed")
            );
          }
          this.scrollToTop();
        })
        .catch((error) => {
          if (error.response !== undefined && error.response.status === 401) {
            this.logError(this.translate("validationtext.validationNotAuth"));
          } else
            this.logError(
              this.translate("validationtext.validationSearchFailed")
            );
          this.scrollToTop();
        });
    },

    modifySearchResult() {
      let self = this;

      this.searchResult.forEach(function (arrayItem) {
        arrayItem.ReceivedType = self.translate(
          "labeltext." + arrayItem.ReceivedType
        );
        arrayItem.RequestToAccessData =
          arrayItem.RequestToAccessData === null || undefined
            ? self.translate("labeltext.reportsNo")
            : self.translate("labeltext.reportsYes");
        arrayItem.RequestToDataRectification =
          arrayItem.RequestToDataRectification === null || undefined
            ? self.translate("labeltext.reportsNo")
            : self.translate("labeltext.reportsYes");
        arrayItem.RequestToDataDeletion =
          arrayItem.RequestToDataDeletion === null || undefined
            ? self.translate("labeltext.reportsNo")
            : self.translate("labeltext.reportsYes");
        arrayItem.RequestForRightToObjectToDataProcessing =
          arrayItem.RequestForRightToObjectToDataProcessing === null ||
          undefined
            ? self.translate("labeltext.reportsNo")
            : self.translate("labeltext.reportsYes");
        arrayItem.ReceivedDate =
          arrayItem.ReceivedDate === null ? "" : arrayItem.ReceivedDate;
        arrayItem.CompleteDate =
          arrayItem.CompleteDate === null ? "" : arrayItem.CompleteDate;
        arrayItem.StatusText = self.translate(
          "labeltext." + arrayItem.StatusText
        );
      });
    },

    buildGridHeadings() {
      this.headers = [];

      this.headers.push({
        title: this.translate("labeltext.reportRequestID"),
        key: "SARID",
        sortable: false,
      });

      this.headers.push({
        title: this.translate("labeltext.reportBrandName"),
        key: "Brandname",
      });

      this.headers.push({
        title: this.translate("labeltext.reportStatus"),
        key: "StatusText",
      });

      this.headers.push({
        title: this.translate("labeltext.reportHeaderName"),
        key: "RequestorName",
      });

      this.headers.push({
        title: this.translate("labeltext.reportHeaderReceivedType"),
        key: "ReceivedType",
      });

      this.headers.push({
        title: this.translate("labeltext.reportHeaderReceivedDate"),
        key: "ReceivedDate",
        maxWidth: "80",
      });

      this.headers.push({
        title: this.translate("labeltext.reportHeaderAccessData"),
        key: "RequestToAccessData",
        maxWidth: "20",
      });

      this.headers.push({
        title: this.translate("labeltext.reportHeaderDataRect"),
        key: "RequestToDataRectification",
        maxWidth: "20",
      });

      this.headers.push({
        title: this.translate("labeltext.reportHeaderDataDel"),
        key: "RequestToDataDeletion",
        maxWidth: "80",
      });

      this.headers.push({
        title: this.translate("labeltext.reportHeaderObject"),
        key: "RequestForRightToObjectToDataProcessing",
        maxWidth: "20",
      });

      this.headers.push({
        title: this.translate("labeltext.reportHeaderCompletedDate"),
        key: "CompleteDate",
        maxWidth: "20",
      });

      this.headers.push({
        title: this.translate("labeltext.reportEmailID"),
        key: "EmailAddress",
      });
    },

    initDownloadOptions() {
      this.exportColumns = {
          [this.translate("labeltext.reportRequestID")]: "SARID",
          [this.translate("labeltext.reportBrandName")]: "Brandname",
          [this.translate("labeltext.reportStatus")]: "StatusText",
          [this.translate("labeltext.reportHeaderName")]: "RequestorName",
          [this.translate("labeltext.reportHeaderReceivedType")]:
            "ReceivedType",
          [this.translate("labeltext.reportHeaderReceivedDate")]:
            "ReceivedDate",
          [this.translate("labeltext.reportHeaderAccessData")]:
            "RequestToAccessData",
          [this.translate("labeltext.reportHeaderDataRect")]:
            "RequestToDataRectification",
          [this.translate("labeltext.reportHeaderDataDel")]:
            "RequestToDataDeletion",
          [this.translate("labeltext.reportHeaderObject")]:
            "RequestForRightToObjectToDataProcessing",
          [this.translate("labeltext.reportHeaderCompletedDate")]:
            "CompleteDate",
          [this.translate("labeltext.reportEmailID")]: "EmailAddress",
        };      
    },

    navigateToDetailsPage(guid: any) {
      return `${process.env.VUE_APP_PUBLIC_PATH}#/review/${guid}`;
    },

    downloadAttachments(attachmentDetails: AttachmentFileDetail) {
      let guidFileName = attachmentDetails.guidFileName;
      let originalFileName = attachmentDetails.originalFileName;

      const service = new GSARService();
      service
        .download(guidFileName, originalFileName)
        .then((response) => {
          var headers = response.headers;
          var blob = new Blob([response.data], {
            type: headers["content-type"],
          });
          var navigator: any = window.navigator;
          if (navigator && navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(blob, originalFileName);
          } else {
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = originalFileName;
            link.click();
          }
        })
        .catch((error) => {
          if (error.response.status == 401)
            this.unAuthorizedUser(
              this.translate("validationtext.validationNotAuth")
            );
          else if (error.response.status == 404)
            this.logError(
              this.translate("validationtext.validationDownloadFile")
            );
          else
            this.logError(
              this.translate("validationtext.validationRetreiveDetails")
            );

          this.scrollToTop();
        });
    },

    modifyDates() {
      if (
        this.reportModel.StartDT !== null ||
        this.reportModel.StartDT !== undefined ||
        this.reportModel.StartDT !== ""
      )
        this.reportModel.StartDT = this.formatDate(this.reportModel.StartDT);
      if (
        this.reportModel.EndDT !== null ||
        this.reportModel.EndDT !== undefined ||
        this.reportModel.EndDT !== ""
      )
        this.reportModel.EndDT = this.formatDate(this.reportModel.EndDT);
    },

    scrollToTop() {
      VueScrollTo.scrollTo("#top", 0);
    },
  },
});
</script>