export default {
    validationtext: {
        validationHeading:'Corrija os seguintes erros:',
        validationReqType: 'Especifique pelo menos um Tipo de Solicitude',
        validationTitle: 'Especifique o Título',
        validationOtherTitle: 'Defina "Outro Título"',
        validationForename: 'Indique o seu nome',
        validationSurname: 'Indique o seu sobrenome',
        validationEmailAddress: 'Indique o seu endereço eletrônico',
        validationCCTV: 'Confirme se solicita filmação de CFTV',
        validationValidEmailAdd: 'Indique um endereço de correio eletrônico válido',
        validationDocType:'Selecione um Tipo de Documento antes de carregar os documentos',
        validationSelectFile: 'Selecione uma Pasta antes de carregar os arquivos',
        validationLoadingForm: 'Ocorreu um erro na carga do formulário. Por favor, tente de novo em uns minutos',
        validationNotAuth: 'Não tem a autorização necessária para poder visualizar esta página',
        validationSubmitForm: 'Ocorreu um erro no envio do formulário. Por favor, tente de novo em uns minutos',
        validationRetreiveDetails: 'Ocorreu um erro ao recuperar os detalhes. Por favor, tente de novo em uns minutos',
        validationDownloadFile: 'Ocorreu um erro na descarga. Por favor, tente de novo em uns minutos',
        validation404: "O recurso solicitado não pode ser encontrado",
        validationMaxFileSize: 'O tamanho do arquivo excede o limite máximo {filesize}',
        validationFileUploadFailed: 'Ocorreu um erro ao carregar os arquivos. Por favor, tente novamente mais tarde',
        validationAllowedFileTypes: 'Os tipos de arquivos que podem ser carregados são {allowedFileExtensions}. Por favor carregue um arquivo que seja compatível',
        validationStartDate: "A data de início não pode ser maior que a atual",
        validationEndDate: "A data do fim não pode ser maior à atual",
        validationStoreVisitDate: "A data de visita não pode ser maior",
        validationEndDateLessThanStartDate: "A data final não pode ser maior",
    
        validationNoRecordsFound: "Nenhum registro encontrado para os criterios de pesquisa inseridos",
        validationSearchFailed: 'Houve alguns erros. Por favor, tente novamente mais tarde',
        
        validationAddNotes: "Por favor, indique uma nota",
        validationAddNotesError:"Ocorreram alguns erros ao adicionar Notes. Por favor, tente novamente mais tarde",
        validationDocTitle: "Por favor, indique um título de documento",
    
        validationAddTaskDescription: "A descrição não pode estar em branco",
        validationAddTaskAssignee: "Cessionário não pode estar em branco",
        validationAddTaskError:"Ocorreram alguns erros ao adicionar tarefas. Por favor, tente novamente mais tarde",
    
        
        validationconsenthistory: "Insira um código de autorização para recuperar detalhes",
        validationConsentHistoryGrid: "Alguns erros ocorreram ao recuperar a história consentimento. Por favor, tente novamente mais tarde.",
        validationNoRecordsFoundConsentHstry: 'Nenhum registro encontrado para o ID de referência do cliente entrou'
    },
    labeltext: {
        dataProtectionAct: ' ',
        dataSAR: 'Formulário para apresentar uma solicitude sobre dados pessoais',
        completedByWarning:'ESTE FORMULÁRIO DEVE CUMPRIMENTAR-SE PELO TITULAR DOS DADOS PESSOAIS OBJETO DA SOLICITUDE',
        typeOfRequest: '*Tipo de Solicitude',
        requestDataAccess: 'Solicitude de aceso',
        requestDataDelete: 'Solicitude de eliminação/supressão',
        requestDataRect: 'Solicitude de modificação',
        requestDataObject: 'Solicitude para opor-se ao tratamento',
        partAHeadingText: 'Parte A: Detalhes do interessado',
        partABodyText: 'Cumprimente o seguinte formulário. As informações que proporcione serão usadas por {brandPlcName1} para tramitar e gerir sua solicitude. Pode ser que {brandPlcName2} resolva sua solicitude com ajuda de um terceiro, em cujo caso pode ser que sejam revelados os dados imprescindíveis para este efeito',
        title: '*Título',
        titleDefaultValue: 'Por favor selecione um título',
        otherTitleLabel:'*Se selecionou “Outro”, especifique',
        otherTitlePlc: 'Outro título',
        surname: '*Sobrenome',
        surnameV2: '*Sobrenome',
        forename: '*Nome',
        forenameV2: '*Nome',
        currentAddress: 'Endereço atual',
        addline1Plc:'Endereço 1',
        addLine2Plc: 'Endereço 2',
        addLine3Plc: 'Endereço 3',
        townPlc: 'Cidade',
        countyPlc: 'Município',
        countryDefaultValue: 'Por favor selecione um País',
        postCode: 'Código postal',
        postCodeV2: 'Código postal',
        prevAddressWarning: 'Quer incluir algum outro endereço que contenha outros dados pessoais?',
        prevAddress: 'Endereço anterior',
        emailAddress: '*Endereço Email',
        contactNumber: 'Número de contato',
        custEmpRel:'Indique sua relação com Brico Depôt',
        relationshipDetailsText: 'Please indicate the relationship the subject of the personal data has with {brandPlcName1} and provide the further information requested.',
        relationshipDetailsTextV2: 'Please indicate the relationship the subject of the personal data has with {brandPlcName1} and provide the further information requested.',
        customer:'Cliente',
        employee:'Trabalhador (Atual ou Ex-trabalhador)',
        custNo:'Número Cliente',
        orderNo: 'Número de Pedido',
        complainRef:' Referência da Reclamação',
        empNo: 'Número trabalhador',
        startDate: 'Data início',
        endDate:'Data final (se aplicável)',
        storeHeadOff: 'Loja',
        partBHeaderText:'Parte B: Detalhes da solicitude (para solicitudes relacionadas com câmaras de videovigilância cumprimente a Parte C)',
        reqDetailsText:'Please provide any further relevant details regarding your request:',
        reqDetailsTextV2:'Please provide any further relevant details regarding your request:',
        reqDetailsPlc: 'Detalhes da solicitude',
        partCHeaderText: 'Parte C: Solicitudes relacionadas com câmaras de videovigilância',
        cctvfootageReq: '*Filmação de CCTV',
        yesText: 'sim',
        noText: 'Não',
        yesStoreVehicle: 'Se respondeu "sim", indique com que está relacionada a filmação ',
        store: 'Loja',
        vehicle: 'Veículo',
        storeConcerned: 'Tienda implicada',
        dateOfVisit:'Data em que ocorreu a',
        timeOfVisit: 'Tempo de visita (24 hrs)',
        hour: 'Hora',
        minute: 'Minuto',
        clothAtVisit: 'Roupa que levava no momento da visita',
        vehicleMake:'Marca do veículo',
        model: 'Modelo',
        colour: 'Cor',
        regNo: 'Número de registro',
        attachments: 'Anexos',
        partDHeadingText: 'Parte D: Completando o formulário',
        partDBodyText: 'Salvo que seja acordada outra cosa, junto com sua solicitude deve apresentar cópia de ao menos dois documentos oficiais dos que resulte claramente o seu nome e o seu endereço atual para que {brandPlcName1} possa assegurar-se da sua identidade. Ao menos um desses documentos deverá incluir uma fotografia sua. Exemplos de documentos aptos para demonstrar sua identidade: Documento de Identidade, carta de condução, passaporte',
        authThirdParty:'Autorizou-se a uma terceira pessoa para interpor a solicitude no seu nome (um representante) deverá proporcionar também, junto com o resto de documentos, a cópia do documento no que é concedida a autorização',
        docTypeDefaultValue: 'Por favor selecione um tipo de documento',
        idProof: 'Identificação',
        addProof: 'Evidências do endereço físico',
        authDoc:'Documento de autorização',
        otherDoc:'Outro',
        chooseFile: 'Selecione o arquivo',
        upload: 'Carregue o arquivo',
        declaration: 'Declaração',
        declarationText: 'DECLARO QUE AS INFORMAÇÕES QUE PROPORCIONO NESTE FORMULÁRIO SÃO CORRETAS E QUE SOU O TITULAR DOS DADOS PESSOAIS AFETADOS. ENTENDO QUE SE NÃO CUMPRIMENTO ESTE FORMULÁRIO DEVIDAMENTE PODE SER QUE MINHA SOLICITUDE SE DEMORE',
        cancel: 'Cancelar',
        print: 'Imprimir',
        submit: 'Enviar',
        ackText: 'Sua solicitude foi enviada com sucesso',
        reqID: 'Solicitude de ID#:',
        makeNewReq: 'Fazer outra solicitude',
        fileUploadSuccessful: 'O arquivo foi carregado corretamente',
        selectDatePlc: 'Selecione a data',
        addtionalRequestTypeText: 'Para qualquer outra solicitude, por favor descreva a solicitude na Parte B do formulário',
        listUploadedFiles: 'Carregar arquivos:',
        Mr: 'Sr.',
        Mrs:'Sra.',
        Miss: '',
        Ms: '',
        OtherTitle: 'Outro',
        partA: 'Parte A: ',
        partB: 'Parte B: ',
        partBV2: 'Parte B: ',
        partC: 'Parte C: ',
        partD: 'Parte D: ',
        cctvHeader:'Solicitudes relacionadas com câmaras de videovigilância',
        formCompletionHeader: 'Completando o formulário',	
        
        reportRequestID: 'Solicitude de ID',
        reportEmailID: 'Correio eletrônico',
        reportLastName: 'Sobrenome do consumidor',
        reportBrandName: 'Nome da marca',
        reportStartDate: 'Data de início',
        reportEndDate: 'Data de finalização',
        reportStatus: 'Estado',
        reportBrandNameDefaultValue: 'Selecione de marca',
        reportStatusDefaultValue: 'Selecione Estado',
        reportHeaderName: 'NOME',
        reportHeaderReceivedType: 'TIPO RECEBIDO',
        reportHeaderReceivedDate: 'RECEBIDO DT',
        reportHeaderAccessData: 'Data Access',
        reportHeaderDataRect: 'RETIFICAÇÃO DE DADOS',
        reportHeaderDataDel: 'eliminação de dados',
        reportHeaderObject: 'Direito de se opor',
        reportHeaderCompletedDate: 'concluído DT',
        
        reportSearch: 'Pesquisa',
        reportDownload: 'baixar',
        reportRecords: 'registros',
        reportsYes:'Y',
        reportsNo:'N',
        reportsRecords:'Mostrando xxx a xxx de registros',
        reportPaginationText:'Mostrando {from} para {to} de {count} registros | {count} registros | 1 registro',
    
        NavigationHome: 'Casa',
        NavigationReports: 'Relatórios',
        originalSARData: 'Dados SAR Original é como abaixo',
    
        requestStatus: 'Estado da solicitude',
        assignTo :'Atribuir a',
        requestArrivalType:'Tipo de solicitude de chegada',
        SARSubmittedDate:'Data na que foi enviada a solicitude SAR',
        actualStartDate:'Data de início',
        expectedCompletionDate:'Data prevista de finalização',
        actualCompletionDate:'Data real de finalização',
        notes:'Notas',
        historicalNotes:'Notas históricas',
    
        Submitted:'Enviado',
        InProgress:'Em progresso',
        OnHold:'Em espera',
        ResponseSent:'A resposta foi enviada',
        Complete:'Completado',
        Open: 'Aberto',
    
        Web:'Web',
        Phone:'Telefone',
        SocialMedia:'Redes sociais',
        Post:'Comentário',
        Email:'E-mail',
        
        doctype: 'Tipo de documento',
        selectUpload : 'Selecione e envie um ficheiro',
        noFile : 'Nenhum ficheiro selecionado',
    
        jan :	'janeiro',
        feb :	'fevereiro', 
        mar :	'marcha',
        apr :	'abril',
        may :	'Pode',
        jun :	'Junho',
        jul :	'Julho',
        aug :	'agosto',
        sep :	'setembro',
        oct :	'Outubro',
        nov :	'novembro',
        dec :	'dezembro',
        sun :	'domingo',
        mon :	'Segunda-feira',
        tue :	'terça',
        wed :	'Quarta-feira',
        thu :	'Quinta-feira',
        fri :	'Sexta-feira',
        sat :	'sábado',
    
        
        tooltipAccessRequest: "Solicitude de acesso: Exceto em alguns casos, você tem direito a obter uma cópia dos seus dados pessoais recolhidos por {brandPlcName1} Toda a informação que, no seu caso, façamos disponível terá apagados os dados pessoais de terceiras pessoas para proteger sua privacidade, exceto se temos o consentimento dessas pessoas ou que seja razoável segundo a situação compartilhar essa informação com você.",
        tooltipDataDeletion: "Solicitude para a supressão de dados: Pode ser que você tenha direito a solicitar seus dados pessoais, recolhidos por {brandPlcName1}. Leve em conta que não lhe poderemos conceder este direito em todos os supostos, por exemplo quando a lei nos exija conservar os seus dados ou quando estes sejam requeridos por motivos de uma reclamação legal.",
        tooltipDataObject: "Solicitude para opor-se ao tratamento de dados: você tem direito a opor-se ao tratamento dos seus dados pessoais por {brandPlcName1} para fins relacionados com marketing. Pode ser que tenha direito a opor-se ao tratamento dos seus dados pessoais por {brandPlcName2} para outros fins, no entanto existem supostos nos que não poderá cumprir-se sua solicitude, por exemplo, quando sejam precisos os dados pessoais em relação a alguma reclamação judicial.",
        tooltipDataRectification: "Solicitude para a correção dos dados pessoais: Você tem o direito a que as correções em posse de {brandPlcName1} sejam corregidos se são incorretos. Além disso, também pode solicitar que sejam completados no caso de que estiverem incompletos.",
        
        sarmgmtAddNote: "Adicionar Notes",
        sarmgmtAddNotesButton: "Adicionar",
        sarmgmtCancelNotes: "Cancelar",
        sarmgmtDocTitle: 'Título do documento',
        sarmgmtUploadDoc: 'Fazer upload de um documento',
        sarmgmtattachmentUploadedBy: 'Enviado por {uploadedby} em {timestamp}',  
    
        sarmgmtAddTask: "Adicionar Tarefas",
        sarmgmtViewTask: "Ver Task",	
        sarmgmtCancelTasks: "Esconder Task",
        TaskID:"tarefa #",
        Description:"Descrição",
        Assignee:"cessionário",
        CreatedDT:"Criada",
        CompletedDT:"Concluído",
        Comment:"Comente",
        taskStatus: "Estado",
    
        taskDeleteMessage: "Por favor, confirme que você deseja excluir a tarefa",
        taskDelete: "Excluir",
        taskSave: 'Salve',
        sarmgmtAddNotes: 'adicionou um comentário',
    
        consentCustomerId: "Identificação do Cliente",
        consentMarketingTextHeader: "Cliente Consentimento Marketing: ",
        consentTooltipCustRef: "ID do cliente: Refere-se ao id contra o qual o consentimento do cliente é armazenada no Consentimento Repository PIBR. Este ID pode ser um número de referência, número de lealdade, e-mail ou número de telefone único. Consulte a orientação sobre o ID do cliente para PIBR Repository para o seu respectivo mercado.",
    
        consentHstryCustomerId : 'Identificação do Cliente',
        consentHstryOpcoName: 'Marca',
        consentHstryChannel: 'Canal',
        consentHstryPrivacyPolicyVerId: 'Política de Privacidade Ver Id',
        consentHstryMarketingChannelVersion: 'Marketing de Canal Versão',
        consentHstryOptIn: 'Opt In',
        consentHstrySubmitedDate: 'Data submetido',
        consentHstrySource: 'Fonte',
        consentHstrySubmittedBy: 'submetido por',
        consentHstryfileDownloadName: 'Cliente Consentimento Report.csv',
        consentHistoryButton: "Obter histórico de consentimento",
        taskEdit: 'Editar'
    },    
}