import { defineComponent } from "vue";
import { SARLegalInitModel } from "@/models/SARLegalInitModel";
import { STYLEVERSIONMAP } from "@/common/common.model";
import CommonMixin from "@/mixins/common.mixin";

export default defineComponent({
    name: 'userContactDetailsMixin',
    mixins: [CommonMixin],
    props: {
        initModel: {
            type: SARLegalInitModel,
            required: true,
        },
        newUser: {
            type: Object,
            required: true,
        },
        styleCode: {
            type: String,
            required: false,
        },
    },
    methods: {
        clearInputFieldsPrevAddress() {
            if (this.newUser.PrevAddress.toString() == "false") {
                this.newUser.PreviousAddress.AddressLine1 = "";
                this.newUser.PreviousAddress.AddressLine2 = "";
                this.newUser.PreviousAddress.AddressLine3 = "";
                this.newUser.PreviousAddress.Town = "";
                this.newUser.PreviousAddress.County = "";
                this.newUser.PreviousAddress.Country = "";
                this.newUser.PreviousAddress.PostCode = "";
            }
        },

        // Provide a get translated method for specific view
        getTranslatedValueEx(label: string) {
            let version = this.styleCode && !!STYLEVERSIONMAP.data[this.styleCode.toLowerCase()]
                ? STYLEVERSIONMAP.data[this.styleCode.toLowerCase()]
                : "";

            let isNewerScrewfix = version && Array.isArray(version);

            let value = null;
            if (isNewerScrewfix) {
                for (let i = 0; i < version.length; i++) {
                    try {
                        let newLabel = `${label}${version[i]}`;   
                        value = this.$t(newLabel);
                        if (newLabel !== value) {
                            break;
                        } else {
                            value = null;
                        }
                    } catch (error) { }
                }
            }

            let defaultValue = '';
            try {
                if (value === null || value === undefined) {
                    defaultValue = this.$t(label);
                } else {
                    defaultValue = value;
                }
            } catch (error) { }

            return defaultValue;
        },

        getTranslatedValueWithParams(label: string, params: {}) {
            let version = this.styleCode && !!STYLEVERSIONMAP.data[this.styleCode.toLowerCase()]
                ? STYLEVERSIONMAP.data[this.styleCode.toLowerCase()]
                : "";

            let isNewerScrewfix = version && Array.isArray(version);

            let value = null;
            if (isNewerScrewfix) {
                for (let i = 0; i < version.length; i++) {
                    try {
                        let newLabel = `${label}${version[i]}`;
                        value = this.$t(newLabel, params);
                        if (newLabel !== value) {
                            break;
                        } else {
                            value = null;
                        }
                    } catch (error) { }
                }
            }

            let defaultValue = '';
            try {
                if (value === null || value === undefined) {
                    defaultValue = this.$t(label, params);
                } else {
                    defaultValue = value;
                }
            } catch (error) { }

            return defaultValue;
        },

        getBrandTranslation(label: string): string {
            return this.getTranslatedValueWithParams(label, {
                brandPlcName1: this.initModel.brandPlcName,
                brandPlcName2: this.initModel.brandPlcName,
            });
        }
    },
});