export default {
    validationtext: {
        validationHeading:'Merci de corriger les anomalies suivantes :',
        validationReqType: 'Merci de choisir au moins un type de demande parmi les choix proposés',
        validationTitle: 'Merci de completer votre “Civilité”',
        validationOtherTitle: 'Merci de compléter "Autre Titre"',
        validationForename: 'Merci de compléter votre "Prénom"',
        validationSurname: 'Merci de compléter votre "Nom"',
        validationEmailAddress: "Merci de renseigner votre adresse email",
        validationCCTV: 'Merci de préciser si la demande porte sur de la vidéo surveillance',
        validationValidEmailAdd: 'Merci de renseigner une adresse email valide',
        validationDocType:'Merci de sélectionner un "Type de document" avant de procéder au téléchargement',
        validationSelectFile: 'Merci de sélectionner un fichier avant de procéder au téléchargement',
        validationLoadingForm: "Une erreur est survenue lors du chargement du formulaire. Merci de bien vouloir renouveller l'opération ultérieurement",
        validationNotAuth: "Vous n'êtes pas autorisé à consulter cette page",
        validationSubmitForm: "Certaines erreurs se sont produites lors de l'envoi de votre demande. Merci de bien vouloir soumettre votre demande ultérieurement",
        validationRetreiveDetails: 'Certaines erreurs se sont produites lors de la récupération des détails de votre demande. Merci de bien vouloir soumettre à nouveau votre demande ultérieurement',
        validationDownloadFile: 'Certaines erreurs se sont produites lors du téléchargement, merci de bien vouloir réessayer ultérieurement',
        validation404: "La ressource que vous avez demandée n'a pas pu être trouvée",
        validationMaxFileSize: 'La taille du document dépasse la limite maximale autorisée de {filesize}',
        validationFileUploadFailed: 'Certaines erreurs se sont produites lors du téléchargement, merci de bien vouloir réessayer ultérieurement',
        validationAllowedFileTypes: 'Les types de document admis pour le chargement sont les suivants {allowedFileExtensions} Merci de bien vouloir télécharger un document compatible',
        validationStartDate: "La date de début ne peut être supérieure à la date du jour",
        validationEndDate: "La date de fin ne peut être supérieure à la date du jour",
        validationStoreVisitDate: "La date de visite ne peut être supérieure à la date du jour",
        validationEndDateLessThanStartDate: "La date de fin ne peut être inférieure à la date de début",
    
        validationNoRecordsFound: "Nous n’avons pas d’éléments avec ces critères",
        validationSearchFailed: 'Une erreur a eu lieu. Pouvez vous recommencer votre recherche ultérieurement',
        
        validationAddNotes: "S'il vous plaît ajouter un commentaire",
        validationAddNotesError:"Des erreurs ont lieu lors de l’ajout de commentaires. Veuillez réessayer plus tard",
        validationDocTitle: "S'il vous plaît donner un titre de document",
    
        validationAddTaskDescription: "Description ne peut être vide",
        validationAddTaskAssignee: "Assigné ne peut pas être vide",
        validationAddTaskError:"Quelques erreurs se sont produites tout en ajoutant des tâches. Veuillez réessayer plus tard",
    
        validationconsenthistory: "S'il vous plaît entrer un identifiant de consentement à retreive détails",
        validationConsentHistoryGrid: "Quelques erreurs se sont produites alors que l'histoire retreiving de consentement. Veuillez réessayer plus tard.",
        validationNoRecordsFoundConsentHstry: "Aucun résultat pour l'ID de référence client entré"
    },
    labeltext: {
        dataProtectionAct: "Réglement Général pour la Protection des Données personnelles",
        dataSAR: "Formulaire de demande d'accès aux données",
        completedByWarning:"Ce formulaire doit être complété par la personne qui souhaite accéder à ses propres données personnelles",
        typeOfRequest: "*Type de demande",
        typeOfRequestV2: "Type de demande",
        requestDataAccess: "Droit d’accès aux données",
        requestDataDelete: "Droit à l'effacement (droit à l’oubli)",
        requestDataRect: "Droit de rectification",
        requestDataObject: "Droit d’opposition",
        partAHeadingText: "Partie A : Coordonnées",
        partABodyText: "Merci de bien vouloir compléter ce formulaire, les informations que vous communiquez seront utilisées par {brandPlcName1} afin de traiter votre demande. {brandPlcName2} peut être amené à faire appel à un sous-traitant pour cette demande et ainsi lui communiquer vos données personnelles afin de satisfaire pleinement votre requête. A partir de la clôture de votre demande, vos données seront conservées pour une durée de 1 an, à l'issue de laquelle elles seront supprimées.",
        yourDetailsAdditionalText:'A partir de la clôture de votre demande, vos données seront conservées pour une durée de 1 an, à l\'issue de laquelle elles seront supprimées.',
        title: "*Civilité",
        titleDefaultValue: 'Merci de sélectionner votre « Titre »',
        otherTitleLabel:"*Si, Autre, merci de préciser",
        otherTitlePlc: 'Autre Titre',
        surname: "*Nom",
        surnameV2: "*Nom",
        forename: "*Prénom",
        forenameV2: "*Prénom",
        currentAddress: "Adresse",
        addline1Plc:"Adresse 1",
        addLine2Plc: "Adresse 2",
        addLine3Plc: "Adresse 3",
        townPlc: "Ville",
        countyPlc: "Département",
        countryDefaultValue: 'Merci de sélectionner un pays',
        postCode: "Code Postal",
        postCodeV2: "Code Postal",
        prevAddressWarning: "Souhaitez-vous ajouter une autre adresse sous laquelle vos données personnelles ont pu être collectées ?",
        prevAddress: 'Autre adresse',
        emailAddress: "*Adresse email",
        contactNumber: "Numéro de téléphone",
        custEmpRel:"Vous êtes",
        relationshipDetailsText: 'Veuillez indiquer la relation entre le sujet des données personnelles et {brandPlcName1} et fournir les informations complémentaires demandées.',
        relationshipDetailsTextV2: 'Veuillez indiquer la relation entre le sujet des données personnelles et {brandPlcName1} et fournir les informations complémentaires demandées.',
        customer:"client",
        employee:"collaborateur (avez-vous été ou êtes-vous un collaborateur de {brandPlcName})",
        custNo:"Si client : N ° de client",
        orderNo: "N° de commande",
        complainRef:" Référence de réclamation",
        empNo: "Si collaborateur : N° de Matricule",
        startDate: "Date d’entrée",
        endDate: "Date de sortie (si pertinent)",
        storeHeadOff: "Dernière fonction occupée",
        partBHeaderText:" Partie B : Merci de détailler votre demande ci-dessous (si cela concerne la vidéo surveillance merci de compléter la Partie C)",
        reqDetailsText:'Veuillez fournir tout autre détail pertinent concernant votre demande:',
        reqDetailsTextV2:'Veuillez fournir tout autre détail pertinent concernant votre demande:',
        reqDetailsPlc: 'Détail de la demande',
        partCHeaderText: "Partie C : Vidéo Surveillance",
        cctvfootageReq: "*Demandez-vous une séquence vidéo ?",
        yesText: "Oui",
        noText: "Non",
        yesStoreVehicle: "Si oui, est-ce en lien avec ",
        store: "un magasin",
        vehicle: "un véhicule",
        storeConcerned: "Magasin concerné",
        dateOfVisit:"Date de la visite",
        timeOfVisit: "Horaire de visite",
        hour: 'Heure',
        minute: 'Minute',
        clothAtVisit: "Particularité vestimentaire",
        vehicleMake:"Type de véhicule",
        model: "Modèle",
        colour: "Couleur",
        regNo: "Immatriculation",
        attachments: 'Pièces jointes',
        partDHeadingText: "Partie D : Justificatif d’identité",
        partDBodyText: "Pour que votre demande puisse aboutir, merci de joindre une copie d’une pièce d’identité officielle ou titre équivalent (permis de conduire, passeport, CNI).",
        authThirdParty:"Si vous autorisez un tiers à effectuer la demande pour votre compte, celui-ci devra communiquer préalablement une copie de ce mandat (ex : avocat).",
        docTypeDefaultValue: 'Merci de sélectionner le type de document',
        idProof: "Justificatif d’identité",
        addProof: " ",
        authDoc:"Mandat",
        otherDoc:"Autre",
        chooseFile: "Choisir un fichier",
        upload: "Télécharger",
        declaration: "Déclaration sur l'honneur",
        declarationText: "J’atteste que les informations communiquées dans le présent formulaire sont exactes et que je suis la personne titulaire des données sur lesquelles porte la demande. Je suis informé que si le formulaire est incomplet ou incorrect, le traitement de la demande pourra être retardé.",
        declarationHeader1: 'Déclaration: ',
        declarationHeader2: 'Veuillez sélectionner la déclaration qui correspond à votre application:',
        declarationHeader2V2: 'Veuillez sélectionner la déclaration qui correspond à votre application:',
        declarationSubHeader1: 'Pour Usage Interne Uniquement: ',
        declarationType1: 'Si vous êtes la personne concernée par les données personnelles: ',
        declarationType1V2: 'Si vous êtes la personne concernée par les données personnelles: ',
        declarationType2: 'Si vous êtes un collègue interne de {brandPlcName1}, remplissez au nom de la personne qui fait l\'objet des données personnelles: ',
        declarationType2V2: 'Si vous êtes un collègue interne de {brandPlcName1}, remplissez au nom de la personne qui fait l\'objet des données personnelles: ',
        declarationtext2: 'Les informations fournies dans ce formulaire de demande sont correctes, conformément aux informations fournies par la personne à laquelle elles se rapportent. La personne à qui ce formulaire se rapporte comprend que si les informations fournies ne sont pas suffisantes pour remplir correctement ce formulaire de demande, sa demande peut être retardée',	
        declarationtext2V2: 'Les informations fournies dans ce formulaire de demande sont correctes, conformément aux informations fournies par la personne à laquelle elles se rapportent. La personne à qui ce formulaire se rapporte comprend que si les informations fournies ne sont pas suffisantes pour remplir correctement ce formulaire de demande, sa demande peut être retardée',	
        cancel: "Annuler",
        print: "Imprimer",
        submit: "Envoyer",
        ackText: "Votre demande a été transmise avec succès",
        reqID: "Numéro de la demande#:",
        makeNewReq: "Etablir une nouvelle demande",
        fileUploadSuccessful: "Téléchargement du fichier réussi",
        selectDatePlc: "Sélectionner une date",
        addtionalRequestTypeText: 'Pour tout autre type de demande, merci de détailler votre requête dans la partie B de ce formulaire.',
        listUploadedFiles: 'Fichiers chargés:',
        Mr: "Monsieur",
        Mrs:'Madame',
        Miss: "Mademoiselle",
        Ms: "",
        OtherTitle: "",
        partA: 'Partie A: ',
        partB: 'Partie B: ',
        partBV2: 'Partie B: ',
        partC: 'Partie C: ',
        partD: 'Partie D: ',
        cctvHeader:'Vidéo Surveillance',
        formCompletionHeader: 'Ajouter une pièce jointe si cela est utile pour votre demande',
        formCompletionBody: 'Afin d\'assurer la sécurité de vos données et selon la nature et le contenu de votre demande, nous pouvons être amenés à vous demander des informations complémentaires pour confirmer votre identification.',
        formCompletionBodyV2: 'Afin d\'assurer la sécurité de vos données et selon la nature et le contenu de votre demande, nous pouvons être amenés à vous demander des informations complémentaires pour confirmer votre identification.',
        
        reportRequestID: 'Numéro de la demande',
        reportEmailID: 'Courriel',
        reportLastName: 'Nom du client',
        reportBrandName: 'Enseigne',
        reportStartDate: 'Date de début',
        reportEndDate: 'Date de fin',
        reportStatus: 'Statut',
        reportBrandNameDefaultValue: 'Sélectionner une enseigne',
        reportStatusDefaultValue: 'Sélectionner statut',
        originalSARData: " La demande de droit d’accès est ",
        reportHeaderName: 'Nom',
        reportHeaderReceivedType: 'Type de droit demandé',
        reportHeaderReceivedDate: 'Date de réception',
        reportHeaderAccessData: "Droit d’accès",
        reportHeaderDataRect: 'Droit de rectification',
        reportHeaderDataDel: 'Droit de suppression',
        reportHeaderObject: "Droit d’opposition",
        reportHeaderCompletedDate: 'Date de traitement de la demande',
        
        reportSearch: 'Recherche',
        reportDownload: 'Télécharger',
        reportRecords: 'Enregistrement:',
        reportsYes:'Oui',
        reportsNo:'Non',
        reportsRecords:'Showing xxx to xxx of records',
        reportPaginationText:"Visualiser de {from} à {to} {count} enregistrements | {count} enregistrement | 1 enregistrement",
    
        NavigationHome: 'Accueil',
        NavigationReports: 'Rapports',
    
        requestStatus: 'Statut de la demande',
        assignTo :'Attribué à',
        requestArrivalType:'Origine de la demande',
        SARSubmittedDate:'Date de soumission de la demande',
        actualStartDate:'Date de début effective',
        expectedCompletionDate:'Date de fin attendue',
        actualCompletionDate:'Deta de fin effective',
        notes:'Notes',
        historicalNotes:'Notes précédentes',
    
        Submitted:"Envoyé",
        InProgress:"En cours",
        OnHold:"En attente",
        ResponseSent:"Réponse envoyée",
        Complete:"Terminée",
        Open: 'Ouvrir',
    
        Web:"Web",
        Phone:"Téléphone",
        SocialMedia:"Réseaux sociaux",
        Post:"Courrier",
        Email:"Courriel",
    
        doctype: 'Type de document',
        selectUpload : 'Sélectionnez et téléchargez un fichier',
        noFile : 'Pas de fichier sélectionné',
    
        jan :	'janvier',
        feb :	'février', 
        mar :	'Mars',
        apr :	'avril',
        may :	'Mai',
        jun :	'juin',
        jul :	'juillet',
        aug :	'août',
        sep :	'septembre',
        oct :	'octobre',
        nov :	'novembre',
        dec :	'décembre',
        sun :	'dimanche',
        mon :	'Lundi',
        tue :	'Mardi',
        wed :	'Mercredi',
        thu :	'Jeudi',
        fri :	'Vendredi',
        sat :	'samedi',
    
        tooltipAccessRequest: "Demande d'Accès aux Données : vous avez le droit, sous réserve de certaines exceptions, d'obtenir une copie de vos données personnelles détenues par {brandPlcName1}. Toutes les informations qui vous seront fournies feront l'objet de suppressions des données personnelles des tiers éventuelles, afin de protéger la vie privée de ces tiers, sauf si nous avons le consentement de ces tiers ou s'il est raisonnable dans les circonstances de vous fournir les données personnelles de ces tiers.",
        tooltipDataDeletion: "Demande de suppression de données : vous pouvez demander à faire supprimer vos données personnelles par {brandPlcName1}. Veuillez noter que cela n'est pas systématiquement applicable, notamment lorsque nous sommes tenus par la loi de conserver vos données ou lorsque les données nous sont nécessaires pour traiter une réclamation légale.",
        tooltipDataObject: "Exercice du droit d'opposition au traitement : vous avez le droit de vous opposer au traitement de vos données personnelles par {brandPlcName1} à des fins de marketing direct. Vous pouvez également être en droit de vous opposer au traitement de vos données personnelles par {brandPlcName2} à d'autres fins, mais cela n'est pas systématiquement applicable, notamment lorsque les données nous sont nécessaires pour traiter une réclamation légale.",
        tooltipDataRectification: "Demande de rectification des données : vous avez le droit de faire corriger vos données personnelles par {brandPlcName1} si elles sont inexactes. Vous pouvez également nous demander de compléter vos données personnelles si elles sont incomplètes.",
    
        tooltipAccessRequestV2: 'La politique de protection des données vous aide à comprendre comment vos données sont utilisées.',	
        tooltipDataDeletionV2: 'Si vous souhaitez faire de nouveaux achats après avoir été supprimé, vous devrez créer un nouveau compte.',	
        tooltipDataRectificationV2:'Vous avez déménagé ou changé de numéro de téléphone, mettez à jour vos informations',	
        tooltipDataObjectV2:'Dans plusieurs cas, il faudra mettre en avant les raisons de votre situation particulière qui justifient votre opposition (sauf en matière de prospection où aucune justification n’est requise)',
        
        
        dataAccessRequestHelpfulInfo: 'L\'exercice du droit d’accès permet de savoir si des données vous concernant sont utilisées et d’en obtenir la communication.<br/>L’accès à certaines informations peut être limité dans des cas spécifiques pour ne pas porter atteinte aux droits d’autres individus, au secret des affaires ou encore à la propriété intellectuelle.<br/><strong>Si vous souhaitez voir des informations sur vos achats passés ou les détails de votre compte, vous pouvez y accéder immédiatement sans attendre en vous connectant à votre compte sur notre site Web et en consultant le Rubrique "Votre compte".</strong>',	
        dataAccessRequestHelpfulInfoV2: 'L\'exercice du droit d’accès permet de savoir si des données vous concernant sont utilisées et d’en obtenir la communication.<br/>L’accès à certaines informations peut être limité dans des cas spécifiques pour ne pas porter atteinte aux droits d’autres individus, au secret des affaires ou encore à la propriété intellectuelle.<br/><strong>Si vous souhaitez voir des informations sur vos achats passés ou les détails de votre compte, vous pouvez y accéder immédiatement sans attendre en vous connectant à votre compte sur notre site Web et en consultant le Rubrique "Votre compte".</strong>',	
        dataDeleteRequestHelpfulInfoV2: 'Vous avez le droit de nous demander de supprimer les données personnelles vous concernant.<br/>La suppression peut être partielle ou refusée dans certains cas déterminés notamment si nous avons l’obligation légale de conserver l’information ou pour préserver l’exercice de nos droits en justice.<br/><strong>Si vous souhaitez supprimer les informations de paiement ou mettre à jour vos préférences marketing, vous pouvez le faire immédiatement sans avoir à attendre en vous connectant à votre compte sur notre site Web et en consultant la section "Votre compte".</strong>',	
        dataRectifyRequestHelpfulInfoV2:'Le droit de rectification vous permet de corriger ou mettre à jour vos données personnelles.<br/><strong>Si vous souhaitez mettre à jour vos informations personnelles telles qu’une nouvelle adresse, un changement de nom ou de nouvelles informations de paiement, vous pouvez le faire immédiatement sans attendre en vous connectant à votre compte sur notre site Web et en consultant la section « Votre compte ».</strong>',	
        dataProcessRequestHelpfulInfoV2:'Le droit d’opposition vous permet de vous opposer à ce que vos données soient utilisées pour un objectif précis (ex : prospection, profilage, etc.).<br/><strong>Vous pouvez décider à tout moment de ne plus recevoir de communication marketing en vous connectant à votre compte sur notre site Web et en consultant la section « Votre compte » ou en cliquant sur le lien de désabonnement en bas de chaque communication par voie électronique ou SMS.</strong>',
        
    
        sarmgmtAddNote: "Ajouter un commentaire",
        sarmgmtAddNotesButton: "Ajouter",
        sarmgmtCancelNotes: "Annuler",
        sarmgmtDocTitle: 'Intitulé du document',
        sarmgmtUploadDoc: 'Télécharger un document',
        sarmgmtattachmentUploadedBy: "Téléchargé par {uploadedby} sur {timestamp}",
        
        sarmgmtAddTask: "Ajouter des tâches",
        sarmgmtViewTask: "Voir la tâche",	
        sarmgmtCancelTasks: "Masquer la tâche",
        TaskID:"Tâche #",
        Description:"La description",
        Assignee:"Cessionnaire",
        CreatedDT:"Créé",
        CompletedDT:"Terminé",
        Comment:"Commentaire",
        taskStatus: "Statut",
        
        taskDeleteMessage: "Ajouter un titre au document",
        taskDelete: "Effacer",
        taskSave: 'sauvegarder',
        sarmgmtAddNotes: 'a ajouté un commentaire',
    
        consentCustomerId: "IdentifiantClient",
        consentMarketingTextHeader: "Marketing client consentement: ",
        consentTooltipCustRef: "Identifiant Client : Fait référence à l'identifiant sous lequel le consentement du client est stocké dans le référentiel de consentement GDPR. Cet identifiant peut être un numéro de référence unique, un numéro de fidélité, un email ou un numéro de téléphone. Veuillez vous reporter aux instructions relatives à l'identifiant client pour le référentiel GDPR pour votre marché",
    
        consentHstryCustomerId : 'IdentifiantClient',
        consentHstryOpcoName: 'Marque',
        consentHstryChannel: 'Canal',
        consentHstryPrivacyPolicyVerId: 'Politique de confidentialité Ver Id',
        consentHstryMarketingChannelVersion: 'Channel Marketing Version',
        consentHstryOptIn: 'Opt In',
        consentHstrySubmitedDate: 'date de submited',
        consentHstrySource: 'La source',
        consentHstrySubmittedBy: 'Soumis par',
        consentHstryfileDownloadName: 'Le consentement client Report.csv',
        consentHistoryButton: "Rechercher dans le referentiel des consentments",
        taskEdit: 'Modifier'
    },    
}