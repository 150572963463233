import api, { AxiosResponse } from "axios";
import { BaseService } from './base.service';
import { SARInitModel } from "@/models/SARInitModel";
import { SARApplicant } from "@/models/SARApplicant";
import { SARLegalUserModel } from "@/models/SARLegalUserModel";

export class GSARService extends BaseService {
  public download(guidFileName: string, originalFileName: string) : Promise<AxiosResponse<any>> {
      return api.get(`Download/${guidFileName}/?originalFileName=${originalFileName}`, this.getLegalAuthenticationHeaderForDownload());
  }

  public upload(files: any): Promise<AxiosResponse<string>> {
    return api.post('Upload', files, this.getCustomerAuthenticationHeaderForUpload());
  }

  public initialiseDetails(requestId: string): Promise<AxiosResponse<SARInitModel>> {
    return api.get(`InitDetails/${requestId}`, this.getLegalAuthenticationHeader());
  }

  public SARDetail(id: string): Promise<AxiosResponse<SARApplicant>> {
    return api.get(`SARDetails/${id}`, this.getLegalAuthenticationHeader());
  }

  public initialise(styleCode: string) : Promise<AxiosResponse<SARInitModel>> {
    return api.get(`Init/${styleCode}`, this.getCustomerAuthenticationHeader());
  }

  public post(model: SARLegalUserModel) : Promise<AxiosResponse<any>> {
    return api.post('SAR', model, this.getCustomerAuthenticationHeader());
  }
}