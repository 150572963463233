import { Address } from "./service-request-response-model";

export class SARLegalUserModel {
    StyleCode: string | undefined;
    Title: string;
    LastName: string;
    FirstName: string;
    CurrentAddress: Address;
    PreviousAddress: Address;
    EmailAddress: string;
    ContactNumber: string;
    CustomerNumber: string;
    OrderNumber: string;
    ComplaintRefNumber: string;
    EmployeeNumber: string;
    StartDT: string;
    EndDT: string;
    OfficeFunction: string;
    DetailsOfRequest: string;
    StoreConcerned: string;
    VisitDT: string;
    VisitDateTime: string;
    ClothingWorn: string;
    VehicleMake: string;
    Model: string;
    Colour: string;
    RegistrationNumber: string;
    RequestType: any[];
    DocumentList: any[];
    CreatedDT: string;
    CCTVDetailsRequired: string;
    IsCustomerCheckbox: "";
    IsEmployeeCheckbox: "";
    PrevAddress: string;
    IsCCTVStore: string;
    IsCCTVVehicle: string;
    FormVersion: number;
    LanguageVersion: number;
    SubmittedOnBehalfOfCustomer: boolean | null;
    IsCCTVCarPark: string;
    AdditionalInfoReqd: string;
    StorePostCode: string;
    VisitStartDateTime: string;
    VisitEndDateTime: string;

    constructor() {
        this.StyleCode = '';
        this.Title = '';
        this.LastName = '';
        this.FirstName = '';
        this.CurrentAddress = new Address();
        this.PreviousAddress = new Address();
        this.EmailAddress = '';
        this.ContactNumber = '';
        this.CustomerNumber = '';
        this.OrderNumber = '';
        this.ComplaintRefNumber = '';
        this.EmployeeNumber = '';
        this.StartDT = '';
        this.EndDT = '';
        this.OfficeFunction = '';
        this.DetailsOfRequest = '';
        this.StoreConcerned = '';
        this.VisitDT = '';
        this.VisitDateTime = '';
        this.ClothingWorn = '';
        this.VehicleMake = '';
        this.Model = '';
        this.Colour = '';
        this.RegistrationNumber = '';
        this.RequestType = [];
        this.DocumentList = [];
        this.CreatedDT = new Date().toISOString().split('T')[0];
        this.CCTVDetailsRequired = '';
        this.IsCustomerCheckbox = '';
        this.IsEmployeeCheckbox = '';
        this.PrevAddress = '';
        this.IsCCTVStore = '';
        this.IsCCTVVehicle = '';
        this.FormVersion = 0;
        this.LanguageVersion = 0;
        this.SubmittedOnBehalfOfCustomer = null;
        this.IsCCTVCarPark = '';
        this.AdditionalInfoReqd = '';
        this.StorePostCode = '';
        this.VisitStartDateTime = '';
        this.VisitEndDateTime = '';
    }
}
