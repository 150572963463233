<template>
  <div class="gd-documents mb-gutter">
    <div class="gd-form-part">
      <h3>
        {{
          `${translate("labeltext.partB")}${translate(
            "labeltext.formCompletionHeader"
          )}`
        }}
      </h3>
      <p class="mb-medium">
        {{ $t("labeltext.formCompletionBody") }}
      </p>
      <p class="mb-medium">
        {{ $t("labeltext.authThirdParty") }}
      </p>
    </div>

    <div class="form-field form-field--gd">
      <label class="field-row">
        <span
          class="label label--text label--inline"
          data-auto="documentproof-lbl"
        >
          {{ $t("labeltext.doctype") }}
        </span>
        <select v-model="initModel.selectedDocument" name="documentproof">
          <option value="">
            {{ $t("labeltext.docTypeDefaultValue") }}
          </option>
          <option
            v-for="(docType, index) in getDropdownValues"
            :value="docType.value"
            :key="index"
          >
            {{ getDDLTranslatedValue(docType.resourceKeyVal) }}
          </option>
        </select>
      </label>
    </div>

    <div class="form-field form-field--gd">
      <div class="field-row">
        <span
          class="label label--text label--inline"
          data-auto="choosefile-lbl"
          >{{ $t("labeltext.selectUpload") }}</span
        >
        <div class="gd-file-upload">
          <label class="gd-file-selector">
            <input
              type="file"
              name="choosefile"
              ref="fileupload"
              @change="fileChange($event.target?.files)"
            />
            <br />
            <span class="font-small" data-auto="choosefiletext-lbl">
              (<span v-for="(ext, index) in allowedFileTypes" :key="index"
                >.{{ ext
                }}<span v-if="index !== allowedFileTypes.length - 1"
                  >,
                </span></span
              >)
            </span>
          </label>
          <button
            class="button button--alt"
            id="uploadfile"
            type="button"
            @click="upload"
          >
            {{ $t("labeltext.upload") }}
          </button>
        </div>
      </div>
    </div>
    <div class="gd-form-part top-padding">
      <h3>
        <span v-if="showFileUploadSuccessMessage" class="success-text">
          {{ $t("labeltext.fileUploadSuccessful") }}
        </span>
      </h3>
    </div>

    <div class="gd-file-list font-small" v-if="newUser.DocumentList.length > 0">
      <p>{{ $t("labeltext.listUploadedFiles") }}</p>
      <ul class="list-styled">
        <li v-for="(item, index) in newUser.DocumentList" :key="index">
          {{ index + 1 }}. {{ getSelectedDocName(item.DocumentTypeId) }} -
          {{ item.FileLocation }}
        </li>
      </ul>
    </div>

    <div>
      <h3>{{ $t("labeltext.declaration") }}</h3>
    </div>

    <div class="form-field form-field--gd">
      <label class="field-row gd-form-declaration">
        <span class="gd-field__option">
          <input
            type="checkbox"
            id="Declaration"
            value="Declaration"
            v-model="initModel.Declaration"
            name="tncdeclaration"
          />
          <span class="css-checkbox"></span>
        </span>
        &nbsp;
        <span
          class="label label--text label--inline label--multi-line no-padding"
          data-auto="Declaration-lbl"
          >{{ $t("labeltext.declarationTextV2") }}</span
        >
      </label>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ResourceItem } from "@/models/SARLegalInitModel";
import UserDocumentsMixin from "@/mixins/userDocuments.mixin";

export default defineComponent({
  name: "UserDocumentsV7",
  mixins: [UserDocumentsMixin],
  computed: {
    getDropdownValues(): any {
      return (
        this.initModel.DocumenTypeList != null &&
        this.initModel.DocumenTypeList.filter(
          (docType: ResourceItem) =>
            this.getDDLTranslatedValue(docType.resourceKeyVal) != ""
        )
      );
    },
  },
});
</script>

<style scoped>
.no-padding {
  padding-top: 0px !important;
}
</style>