export class SARLegalAttachmentModel {
    SarID: string;
    DocumentTitle: string;
    FileName: string;
    FileGuid: string;
    UploadedDate: string;

    constructor() {
        this.SarID = '';
        this.DocumentTitle = '';
        this.FileName = '';
        this.FileGuid = '';
        this.UploadedDate = '';
    }
}