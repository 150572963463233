<template>
  <div>
    <div class="gd-user-contact-details mb-gutter type-of-request mt-gutter">
      <fieldset class="form-field">
        <div class="grid parentStyle">
          <div class="grid__item">
            <legend class="sectionHeaderStyle">
              {{ $t("labeltext.typeOfRequestV2") }}
            </legend>
          </div>
          <table>
            <tr>
              <td style="width: 2%"></td>
              <td style="width: 98%">
                <div class="shadedArea">
                  <div class="container">
                    <label>
                      <div class="check">
                        <input
                          type="checkbox"
                          id="DataAccessRequest"
                          name="typeofrequest"
                          value="1"
                          v-model="newUser.RequestType"
                          :disabled="initModel.isDisabled"
                        />
                        <span class="css-checkbox"></span>
                      </div>
                    </label>
                    <div class="requestHeading">
                      <span
                        class="label label--inline typeHeaderStyle"
                        data-auto="DataAccessRequest-lbl"
                      >
                        {{ $t("labeltext.requestDataAccess") }}
                      </span>
                    </div>
                    <div class="requestInfo">
                      <span class="tooltip-icon iconFix">
                        <i data-auto="AccessRequestInfo-btn"
                          >i
                          <v-tooltip
                            activator="parent"
                            location="bottom"
                            width="320px"
                            :contained="true"
                            :open-on-click="true"
                          >
                            {{
                              getBrandTranslation(
                                "labeltext.tooltipAccessRequest"
                              )
                            }}
                          </v-tooltip>
                        </i>
                      </span>
                    </div>
                  </div>
                  <label class="label--inline helpfulTextStyle">
                    <div
                      v-html="
                        $t('labeltext.dataAccessRequestHelpfulInfoV2')
                      "
                    ></div>
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td style="width: 2%"></td>
              <td style="width: 98%">&nbsp;</td>
            </tr>
            <tr>
              <td style="width: 2%"></td>
              <td style="width: 98%">
                <div class="shadedArea">
                  <div class="container">
                    <label>
                      <div class="check">
                        <input
                          type="checkbox"
                          id="DataDeleteRequest"
                          name="typeofrequest"
                          value="2"
                          v-model="newUser.RequestType"
                          :disabled="initModel.isDisabled"
                        />
                        <span class="css-checkbox"></span>
                      </div>
                    </label>
                    <div class="requestHeading">
                      <span
                        class="label label--inline typeHeaderStyle"
                        data-auto="DataDeleteRequest-lbl"
                      >
                        {{ $t("labeltext.requestDataDelete") }}
                      </span>
                    </div>
                    <div class="requestInfo">
                      <span class="tooltip-icon">
                        <i data-auto="DeleteRequestInfo-btn"
                          >i
                          <v-tooltip
                            activator="parent"
                            location="bottom"
                            width="320px"
                            :contained="true"
                            :open-on-click="true"
                          >                   
                            {{
                              getBrandTranslation("labeltext.tooltipDataDeletion")
                            }}
                          </v-tooltip>
                        </i>
                      </span>
                    </div>
                  </div>
                  <label class="label--inline helpfulTextStyle">
                    <div
                      v-html="
                        $t('labeltext.dataDeleteRequestHelpfulInfoV2')
                      "
                    ></div>
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td style="width: 2%"></td>
              <td style="width: 98%">&nbsp;</td>
            </tr>
            <tr>
              <td style="width: 2%"></td>
              <td style="width: 98%">
                <div class="shadedArea">
                  <div class="container">
                    <label>
                      <div class="check">
                        <input
                          type="checkbox"
                          id="DataRectificationRequest"
                          name="typeofrequest"
                          value="3"
                          v-model="newUser.RequestType"
                          :disabled="initModel.isDisabled"
                        />
                        <span class="css-checkbox"></span>
                      </div>
                    </label>
                    <div class="requestHeading">
                      <span
                        class="label label--inline typeHeaderStyle"
                        data-auto="DataRectificationRequest-lbl"
                      >
                        {{ $t("labeltext.requestDataRect") }}
                      </span>
                    </div>
                    <div class="requestInfo">
                      <span class="tooltip-icon iconFix">
                        <i data-auto="RectificationRequestInfo-btn"
                          >i
                          <v-tooltip
                            activator="parent"
                            location="bottom"
                            width="320px"
                            :contained="true"
                            :open-on-click="true"
                          >
                            {{
                              getBrandTranslation(
                                "labeltext.tooltipDataRectification"
                              )
                            }}
                          </v-tooltip>
                        </i>
                      </span>
                    </div>
                  </div>
                  <label class="label--inline helpfulTextStyle">
                    <div
                      v-html="
                        $t('labeltext.dataRectifyRequestHelpfulInfoV2')
                      "
                    ></div>
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td style="width: 2%"></td>
              <td style="width: 98%">&nbsp;</td>
            </tr>
            <tr>
              <td style="width: 2%"></td>
              <td style="width: 98%">
                <div class="shadedArea">
                  <div class="container">
                    <label>
                      <div class="check">
                        <input
                          type="checkbox"
                          id="DataProcessingRequest"
                          name="typeofrequest"
                          value="4"
                          v-model="newUser.RequestType"
                          :disabled="initModel.isDisabled"
                        />
                        <span class="css-checkbox"></span>
                      </div>
                    </label>
                    <div class="requestHeading">
                      <span
                        class="label label--inline typeHeaderStyle"
                        data-auto="DataProcessingRequest-lbl"
                      >
                        {{ $t("labeltext.requestDataObject") }}
                      </span>
                    </div>
                    <div class="requestInfo">
                      <span class="tooltip-icon iconFix">
                        <i data-auto="ProcessingRequestInfo-btn"
                          >i
                          <v-tooltip
                            activator="parent"
                            location="bottom"
                            width="320px"
                            :contained="true"
                            :open-on-click="true"
                          >
                            {{
                              getBrandTranslation("labeltext.tooltipDataObject")
                            }}
                          </v-tooltip>
                        </i>
                      </span>
                    </div>
                  </div>
                  <label class="label--inline helpfulTextStyle">
                    <div
                      v-html="
                        $t('labeltext.dataProcessRequestHelpfulInfoV2')
                      "
                    ></div>
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td style="width: 2%"></td>
              <td style="width: 98%">&nbsp;</td>
            </tr>
          </table>
        </div>
      </fieldset>
    </div>

    <div class="mb-gutter">
      <h3>{{ $t("labeltext.reqDetailsText") }}</h3>
    </div>

    <div class="form-field form-field--gd--full">
      <label class="field-row">
        <textarea
          v-model="newUser.DetailsOfRequest"
          :placeholder="
            initModel.isDisabled == true
              ? ''
              : translate('labeltext.reqDetailsPlc')
          "
          :disabled="initModel.isDisabled"
          row="2"
        ></textarea>
      </label>
    </div>

    <div class="mb-gutter">
      <h3>{{ $t("labeltext.partAHeadingText") }}</h3>
      <p>
        {{ getBrandTranslation("labeltext.partABodyText") }}
      </p>
    </div>

    <div class="gd-user-contact-details gd-user-contact-details--alt mb-gutter">
      <div class="form-field form-field--gd">
        <label class="field-row">
          <span class="label label--text label--inline" data-auto="title-lbl">
            {{ $t("labeltext.title") }}
          </span>
          <select
            v-model="initModel.selectedTitleValue"
            name="title"
            :disabled="initModel.isDisabled"
          >
            <option value="">
              {{ $t("labeltext.titleDefaultValue") }}
            </option>
            <option
              v-for="(titleDDL, index) in getDropdownValues"
              :value="titleDDL.value"
              :key="index"
            >
              {{ getDDLTranslatedValue(titleDDL.resourceKeyVal) }}
            </option>
          </select>
        </label>
      </div>

      <div
        class="form-field form-field--gd"
        v-if="initModel.selectedTitleValue == '5'"
        id="otherTitle"
      >
        <label class="field-row">
          <span
            class="label label--text label--inline"
            data-auto="othertitle-lbl"
            >{{ $t("labeltext.otherTitleLabel") }}</span
          >
          <input
            type="text"
            v-model="initModel.selectedOtherTitle"
            name="othertitle"
            maxlength="15"
            :disabled="initModel.isDisabled"
            :placeholder="
              initModel.isDisabled == true
                ? ''
                : translate('labeltext.otherTitlePlc')
            "
          />
        </label>
      </div>

      <div class="form-field form-field--gd">
        <label class="field-row">
          <span
            class="label label--text label--inline"
            data-auto="surname-lbl"
            >{{ $t("labeltext.surname") }}</span
          >
          <input
            type="text"
            v-model="newUser.LastName"
            name="surname"
            maxlength="30"
            :disabled="initModel.isDisabled"
            :placeholder="
              initModel.isDisabled == true
                ? ''
                : translate('labeltext.surname')
            "
          />
        </label>
      </div>

      <div class="form-field form-field--gd">
        <label class="field-row">
          <span
            class="label label--text label--inline"
            data-auto="forename-lbl"
            >{{ $t("labeltext.forename") }}</span
          >
          <input
            type="text"
            v-model="newUser.FirstName"
            name="forename"
            maxlength="50"
            :disabled="initModel.isDisabled"
            :placeholder="
              initModel.isDisabled == true
                ? ''
                : translate('labeltext.forename')
            "
          />
        </label>
      </div>

      <div class="form-field form-field--gd">
        <label class="field-row">
          <span
            class="label label--text label--inline"
            data-auto="curraddressline1-lbl"
            >{{ $t("labeltext.currentAddress") }}</span
          >
          <input
            type="text"
            v-model="newUser.CurrentAddress.AddressLine1"
            :disabled="initModel.isDisabled"
            :placeholder="
              initModel.isDisabled == true
                ? ''
                : translate('labeltext.addline1Plc')
            "
            name="curraddressline1"
            maxlength="100"
          />
        </label>
      </div>

      <div class="form-field form-field--gd">
        <label class="field-row">
          <span class="label label--text label--inline"></span>
          <input
            type="text"
            v-model="newUser.CurrentAddress.AddressLine2"
            :disabled="initModel.isDisabled"
            :placeholder="
              initModel.isDisabled == true
                ? ''
                : translate('labeltext.addLine2Plc')
            "
            name="curraddressline2"
            maxlength="100"
          />
        </label>
      </div>

      <div class="form-field form-field--gd">
        <label class="field-row">
          <span class="label label--text label--inline"></span>
          <input
            type="text"
            v-model="newUser.CurrentAddress.AddressLine3"
            :disabled="initModel.isDisabled"
            :placeholder="
              initModel.isDisabled == true
                ? ''
                : translate('labeltext.addLine3Plc')
            "
            name="curraddressline3"
            maxlength="100"
          />
        </label>
      </div>

      <div class="form-field form-field--gd">
        <label class="field-row">
          <span class="label label--text label--inline"></span>
          <input
            type="text"
            v-model="newUser.CurrentAddress.Town"
            :disabled="initModel.isDisabled"
            :placeholder="
              initModel.isDisabled == true
                ? ''
                : translate('labeltext.townPlc')
            "
            name="currtown"
            maxlength="50"
          />
        </label>
      </div>

      <div class="form-field form-field--gd">
        <label class="field-row">
          <span class="label label--text label--inline"></span>
          <input
            type="text"
            v-model="newUser.CurrentAddress.County"
            :disabled="initModel.isDisabled"
            :placeholder="
              initModel.isDisabled == true
                ? ''
                : translate('labeltext.countyPlc')
            "
            name="currcounty"
            maxlength="50"
          />
        </label>
      </div>

      <div class="form-field form-field--gd">
        <label class="field-row">
          <span class="label label--text label--inline"></span>
          <select
            v-model="newUser.CurrentAddress.Country"
            :disabled="initModel.isDisabled"
            name="currcountry"
          >
            <option value="">
              {{ $t("labeltext.countryDefaultValue") }}
            </option>
            <option
              v-for="(countryVal, index) in initModel.CountriesList"
              :value="countryVal.CountryCode"
              :key="index"
            >
              {{ countryVal.CountryName }}
            </option>
          </select>
        </label>
      </div>

      <div class="form-field form-field--gd">
        <label class="field-row">
          <span
            class="label label--text label--inline"
            data-auto="currpostcode-lbl"
            >{{ $t("labeltext.eireCode") }}</span
          >
          <input
            type="text"
            v-model="newUser.CurrentAddress.PostCode"
            :disabled="initModel.isDisabled"
            :placeholder="
              initModel.isDisabled == true
                ? ''
                : translate('labeltext.eireCode')
            "
            name="currpostcode"
            maxlength="25"
          />
        </label>
      </div>

      <div class="form-field form-field--gd">
        <div class="field-row">
          <span class="label label--text label--inline label--multi-line">{{
            $t("labeltext.prevAddressWarning")
          }}</span>

          <div class="gd-field-multi-select form-field--gd__options">
            <label>
              <input
                type="radio"
                id="PrevAddressYes"
                value="true"
                v-model="newUser.PrevAddress"
                :disabled="initModel.isDisabled"
                @change="clearInputFieldsPrevAddress"
              />
              <span class="css-radio"></span>
              <span class="label label--inline label-padding" data-auto="isPrevAddress-yes-lbl">
                {{ $t("labeltext.yesText") }}
              </span>
            </label>

            <label>
              <input
                type="radio"
                id="PrevAddressNo"
                value="false"
                v-model="newUser.PrevAddress"
                :disabled="initModel.isDisabled"
                @change="clearInputFieldsPrevAddress"
              />
              <span class="css-radio"></span>
              <span class="label label--inline label-padding" data-auto="isPrevAddress-no-lbl">
                {{ $t("labeltext.noText") }}
              </span>
            </label>
          </div>
        </div>
      </div>

      <div class="mb-gutter" v-if="newUser.PrevAddress.toString() === 'true'">
        <div class="form-field form-field--gd">
          <label class="field-row">
            <span
              class="label label--text label--inline"
              data-auto="prevaddressline1-lbl"
            >
              {{ $t("labeltext.prevAddress") }}
            </span>
            <input
              type="text"
              v-model="newUser.PreviousAddress.AddressLine1"
              :placeholder="
                initModel.isDisabled == true
                  ? ''
                  : translate('labeltext.addline1Plc')
              "
              :disabled="initModel.isDisabled"
              name="prevaddressline1"
              maxlength="100"
            />
          </label>
        </div>

        <div class="form-field form-field--gd">
          <label class="field-row">
            <span class="label label--text label--inline"></span>
            <input
              type="text"
              v-model="newUser.PreviousAddress.AddressLine2"
              :placeholder="
                initModel.isDisabled == true
                  ? ''
                  : translate('labeltext.addLine2Plc')
              "
              :disabled="initModel.isDisabled"
              name="prevaddressline2"
              maxlength="100"
            />
          </label>
        </div>

        <div class="form-field form-field--gd">
          <label class="field-row">
            <span class="label label--text label--inline"></span>
            <input
              type="text"
              v-model="newUser.PreviousAddress.AddressLine3"
              :placeholder="
                initModel.isDisabled == true
                  ? ''
                  : translate('labeltext.addLine3Plc')
              "
              :disabled="initModel.isDisabled"
              name="prevaddressline3"
              maxlength="100"
            />
          </label>
        </div>

        <div class="form-field form-field--gd">
          <label class="field-row">
            <span class="label label--text label--inline"></span>
            <input
              type="text"
              v-model="newUser.PreviousAddress.Town"
              :placeholder="
                initModel.isDisabled == true
                  ? ''
                  : translate('labeltext.townPlc')
              "
              :disabled="initModel.isDisabled"
              name="prevtown"
              maxlength="50"
            />
          </label>
        </div>

        <div class="form-field form-field--gd">
          <label class="field-row">
            <span class="label label--text label--inline"></span>
            <input
              type="text"
              v-model="newUser.PreviousAddress.County"
              :placeholder="
                initModel.isDisabled == true
                  ? ''
                  : translate('labeltext.countyPlc')
              "
              :disabled="initModel.isDisabled"
              name="prevcounty"
              maxlength="50"
            />
          </label>
        </div>

        <div class="form-field form-field--gd">
          <label class="field-row">
            <span class="label label--text label--inline"></span>
            <select
              v-model="newUser.PreviousAddress.Country"
              :disabled="initModel.isDisabled"
              class="fixedWidth"
              name="prevcountry"
            >
              <option value="">
                {{ $t("labeltext.countryDefaultValue") }}
              </option>
              <option
                v-for="(countryVal, index) in initModel.CountriesList"
                :value="countryVal.CountryCode"
                :key="index"
              >
                {{ countryVal.CountryName }}
              </option>
            </select>
          </label>
        </div>

        <div class="form-field form-field--gd">
          <label class="field-row">
            <span class="label label--text label--inline">
              {{ $t("labeltext.eireCode") }}
            </span>
            <input
              type="text"
              v-model="newUser.PreviousAddress.PostCode"
              :placeholder="
                initModel.isDisabled == true
                  ? ''
                  : translate('labeltext.eireCode')
              "
              :disabled="initModel.isDisabled"
              name="prevpostcode"
              maxlength="25"
            />
          </label>
        </div>
      </div>

      <div class="form-field form-field--gd">
        <label class="field-row">
          <span
            class="label label--text label--inline"
            data-auto="emailaddress-lbl"
          >
            {{ $t("labeltext.emailAddress") }}
          </span>
          <input
            type="text"
            v-model="newUser.EmailAddress"
            :placeholder="
              initModel.isDisabled == true
                ? ''
                : translate('labeltext.emailAddress')
            "
            :disabled="initModel.isDisabled"
            name="emailaddress"
            maxlength="50"
          />
        </label>
      </div>

      <div class="form-field form-field--gd">
        <label class="field-row">
          <span
            class="label label--text label--inline"
            data-auto="contactnumber-lbl"
          >
            {{ $t("labeltext.contactNumber") }}
          </span>
          <input
            type="text"
            v-model="newUser.ContactNumber"
            :placeholder="
              initModel.isDisabled == true
                ? ''
                : translate('labeltext.contactNumber')
            "
            :disabled="initModel.isDisabled"
            name="contactnumber"
            maxlength="15"
          />
        </label>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ResourceItem } from "@/models/SARLegalInitModel";
import UserContactDetailsMixin from "@/mixins/userContactDetails.mixin";

export default defineComponent({
  name: "UserContactDetailsV5",
  mixins: [UserContactDetailsMixin],
  computed: {
    getDropdownValues(): any {
      return (
        this.initModel.TitleList != null &&
        this.initModel.TitleList.filter(
          (titleDDL: ResourceItem) =>
            this.getDDLTranslatedValue(titleDDL.resourceKeyVal) != ""
        )
      );
    },
  },
});
</script>

<style scoped>
.typeHeaderStyle {
  font-style: italic;
  font-weight: bold !important;
  font-size: 17px !important;
}
.shadedArea {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 10px !important;
}
.helpfulTextStyle {
  padding-top: 8px !important;
}
.parentStyle {
  border: 2px solid #b7c2c7;
  padding-right: 10px !important;
}
.sectionHeaderStyle {
  font-size: 16px !important;
  font-weight: 700 !important;
}

.iconFix {
  margin-left: 0px !important;
}

.container {
  display: flex;
  flex-direction: row !important;
  flex-wrap: nowrap;
  justify-content: flex-start;
  column-gap: 10px;
}

@media (max-width: 560px) {
  .container {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    text-align: center;
  }

  .requestHeading {
    margin-top: 15px;
  }

  .requestInfo {
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .helpfulTextStyle {
    text-align: center;
  }
}
</style>