<template>
  <div class="gd-header-wrap">
    <header class="bar bar--header" role="banner">
      <div class="gd-market-logo">
        <img class="" alt="Local market logo" height="60" />
      </div>

      <div class="bar__center">
        <h1>
          <span class="headertextalign">{{
            getTranslatedValue("labeltext.dataProtectionAct")
          }}</span>
        </h1>
        <h2>
          <span class="headertextalign">{{
            getTranslatedValue("labeltext.dataSAR")
          }}</span>
        </h2>
      </div>
    </header>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CommonMixin from "@/mixins/common.mixin";

export default defineComponent({
  name: "Header",
  mixins: [CommonMixin],
  props: {
    styleCodeFromParent: String,
  },
});
</script>