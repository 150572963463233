import { useUserModule } from '@/store/user-module';
import appConfig from "@/config/application-config";

export class BaseService {
  getLegalAuthenticationHeader() {
    const userModule = useUserModule().state;
    const config = {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "application/json",
        Authorization: userModule.jwtTokenAuthLegal
          ? appConfig.authHeaderPrefix + userModule.jwtTokenAuthLegal
          : "",
        "Content-Type": "application/json",
      },
    };
    return config;
  }

  getLegalAuthenticationHeaderForUpload() {
    const userModule = useUserModule().state;
    const config = {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Authorization: userModule.jwtTokenAuthLegal
          ? appConfig.authHeaderPrefix + userModule.jwtTokenAuthLegal
          : "",
        "Content-Type": "multipart/form-data"
      },
    };
    return config;
  }

  getLegalAuthenticationHeaderForDownload() {
    const userModule = useUserModule().state;
    const config = {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "application/json",
        Authorization: userModule.jwtTokenAuthLegal
          ? appConfig.authHeaderPrefix + userModule.jwtTokenAuthLegal
          : "",
        responseType: 'arraybuffer'
      },
    };
    return config;
  }

  getCustomerAuthenticationHeader() {
    const userModule = useUserModule().state;
    const config = {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "application/json",
        Authorization: userModule.jwtTokenAuthCustomer
          ? appConfig.authHeaderPrefix + userModule.jwtTokenAuthCustomer
          : "",
        "Content-Type": "application/json",
      },
    };
    return config;
  }

  getCustomerAuthenticationHeaderForUpload() {
    const userModule = useUserModule().state;
    const config = {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Authorization: userModule.jwtTokenAuthCustomer
          ? appConfig.authHeaderPrefix + userModule.jwtTokenAuthCustomer
          : "",
        "Content-Type": "multipart/form-data"
      },
    };
    return config;
  }
}