import { IReportStatus, IReportStyle } from "./service-request-response-model";

export class SARReportModel {
    SARID: string;
    EmailAddress: string;
    LastName: string;
    BrandNames: IReportStyle[];
    StyleId: string;
    StartDT: string;
    EndDT: string;
    Status: IReportStatus[];
    StatusID: string;
    Index: number;
    reportdatepickerLang: string;
    Brandname: string;
    StatusText: string;

    constructor() {
        this.SARID = '';
        this.EmailAddress = '';
        this.LastName = '';
        this.BrandNames = [];
        this.StyleId = '';
        this.StartDT = '';
        this.EndDT = '';
        this.Status = [];
        this.StatusID = '';
        this.Index = 1;
        this.reportdatepickerLang = '';
        this.Brandname = '';
        this.StatusText = '';
    }
}