<template>
  <div class="gd-attachment mb-gutter">
    <div class="mb-gutter">
      <h3>
        {{
          `${getTranslatedValueEx("labeltext.partB")} ${getTranslatedValueEx(
            "labeltext.formCompletionHeader"
          )}`
        }}
      </h3>
    </div>
    <div class="gd-file-list font-small">
      <ul class="list-styled">
        <li v-for="(doc, index) in newUser.DocumentList" :key="index">
          <img
            src="./../../../assets/images/attachment.png"
            class="legal__attachment"
          />
          <a
            @click="downloadAttachments(doc.Guid, doc.FileLocation)"
            class="legal__attachmentlist"
          >
            <span class="legal__attachmentlist"
              >{{
                getTranslatedValueEx(
                  `labeltext.${getSelectedDocName(doc.DocumentTypeId)}`
                )
              }}
              -
            </span>
            {{ doc.FileLocation }}
          </a>
        </li>
      </ul>
    </div>

    <div>
      <h3>
        {{
          `${getTranslatedValueEx(
            "labeltext.declarationHeader1"
          )} ${getTranslatedValueEx("labeltext.declarationHeader2")}`
        }}
      </h3>
    </div>

    <div class="form-field form-field--gd"></div>

    <div class="form-field form-field--gd">
      <label class="field-row gd-form-declaration">
        {{ getTranslatedValueEx("labeltext.declarationType1") }}
      </label>
    </div>

    <div class="form-field form-field--gd">
      <label class="field-row gd-form-declaration">
        <span class="gd-field__option">
          <input
            type="checkbox"
            id="Declaration"
            value="Declaration"
            v-model="initModel.Declaration"
            name="tncdeclaration"
            :disabled="initModel.isDisabled"
          />
          <span class="css-checkbox"></span>
        </span>
        &nbsp;
        <span
          class="label label--text label--inline label--multi-line no-padding"
          data-auto="Declaration-lbl"
          >{{ getTranslatedValueEx("labeltext.declarationText") }}</span
        >
      </label>
    </div>

    <div class="form-field form-field--gd">
      <label
        class="field-row gd-form-declaration"
      >
      {{$t('labeltext.declarationSubHeader1')}}
      </label>
    </div>

    <div class="form-field form-field--gd">
      <label class="field-row gd-form-declaration">
        {{
          getTranslatedValueWithParams("labeltext.declarationType2", {
            brandPlcName1: initModel.brandPlcName,
          })
        }}
      </label>
    </div>

    <div class="form-field form-field--gd">
      <label class="field-row gd-form-declaration">
        <span class="gd-field__option">
          <input
            type="checkbox"
            id="ColleagueDeclaration"
            value="ColleagueDeclaration"
            v-model="initModel.ColleagueDeclaration"
            name="tnccolleaguedeclaration"
            :disabled="initModel.isDisabled"
          />
          <span class="css-checkbox"></span>
        </span>
        &nbsp;
        <span
          class="label label--text label--inline label--multi-line no-padding"
          data-auto="ColleagueDeclaration-lbl"
          >{{ getTranslatedValueEx("labeltext.declarationtext2") }}</span
        >
      </label>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SARAttachmentsMixin from "@/mixins/SARAttachments.mixin";

export default defineComponent({
  name: "SARAttachmentsV8",
  mixins: [SARAttachmentsMixin]
});
</script>

<style scoped>
.no-padding {
  padding-top: 0px !important;
}
</style>