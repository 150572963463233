export class SARConsentHistoryModel {
    CustomerId: string;
    OpcoName: string;
    Channel: string;
    PrivacyPolicyVerId: string;
    MarketingChannelVersion: string;
    OptIn: string;
    ConsentHstrySubmitedDate: string;
    Source: string;
    SubmittedBy: string;

    constructor() {
        this.CustomerId = '';
        this.OpcoName = '';
        this.Channel = '';
        this.PrivacyPolicyVerId = '';
        this.MarketingChannelVersion = '';
        this.OptIn = '';
        this.ConsentHstrySubmitedDate = '';
        this.Source = '';
        this.SubmittedBy = '';
    }
}