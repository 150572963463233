import { createI18n } from 'vue-i18n';

// Import the translation files
import de from '@/locales/de';
import en from '@/locales/en';
import es from '@/locales/es';
import fr from '@/locales/fr';
import pl from '@/locales/pl';
import pt from '@/locales/pt';
import ro from '@/locales/ro';

// Set the languages and allow i18n to react to browser changes (this was automatic previously)
const i18n = createI18n({
    locale: navigator.language.split('-')[0] || process.env.VUE_APP_I18N_LOCALE,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
    globalInjection: true,
    messages: { de, en, es, fr, pl, pt, ro },
    enableLegacy: true,
    warnHtmlInMessage: "off",
    silentTranslationWarn: true,
    silentFallbackWarn: true
});

export default i18n;