<template>
  <div class="nav-container wrap-v-medium">
    <nav class="grid__row menu__navigation">
      <ul class="grid">
        <li>
          <router-link :to="{ name: 'SARHome' }"
            ><i class="icon icon--store"></i
            >{{ $t("labeltext.NavigationHome") }}</router-link
          >
        </li>
        <li>
          <router-link :to="{ name: 'SARReport' }"
            ><i class="icon icon--navicon"></i
            >{{ $t("labeltext.NavigationReports") }}</router-link
          >
        </li>
      </ul>
    </nav>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "NavBar",
});
</script>
