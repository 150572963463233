export class AuthModel {
    isRequestAuthorized: boolean | undefined;

    constructor() {
        this.isRequestAuthorized = undefined;
    }
}

export const RouteName = {
    SARManagement: 'SARMgmt',
    SARLegalTeamReports: 'SARReport',
    SARHome: 'SARHome',
    SARCustomer: 'SARCustomer',
    SARReportHome: 'SARReport-Home'
}

export class AttachmentFileDetail {
    guidFileName: string;
    originalFileName: string;

    constructor() {
        this.guidFileName = '';
        this.originalFileName = '';
    }
}

export interface IShowAcknowledgement {
    isAcknowledgementVisible: boolean,
    ackSARId: any,
};

export const ChildComponentSection = {
    PartA: 'UserContactDetails',
    PartB: 'UserCustEmpRelationship',
    PartC: 'UserCCTVDetails',
    PartD: 'UserDocuments',
    PartE: 'UserAttachmentDetails'
};

export interface IStyleVersionMap {
    data: Record<string, string[]>;
}

export const STYLEVERSIONMAP: IStyleVersionMap = {
    data: {
        "sf_8": ["V2"],
        "sf_9": ["V3", "V2"],
        "sf_10": ["V2"],
    }
};

export interface ITranslationSub {
    name: string;
    value: string;
}

export const GridDefault = {
    PagingSize: 20
}

export class FooterProps {
    itemsPerPageOptions: (number | { title: string; value: number })[];
    disableItemsPerPage: Boolean;
    disablePagination: Boolean;
    showCurrentPage: Boolean;
    showFirstLastPage: Boolean;
    firstIcon: String;
    lastIcon: String;
    prevIcon: String;
    nextIcon: String;

    constructor() {
        this.itemsPerPageOptions = [20, 40, 60, 80, 100];
        this.disableItemsPerPage = true;
        this.disablePagination = true;
        this.showCurrentPage = true;
        this.showFirstLastPage = true;
        this.firstIcon = 'mdi-page-first';
        this.lastIcon = 'mdi-page-last';
        this.prevIcon = 'mdi-chevron-left';
        this.nextIcon = 'mdi-chevron-right';
    }
}