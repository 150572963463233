import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "top" }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_header = _resolveComponent("v-header")!
  const _component_v_body = _resolveComponent("v-body")!
  const _component_v_acknowledgement = _resolveComponent("v-acknowledgement")!
  const _component_v_footer = _resolveComponent("v-footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("link", {
      rel: "stylesheet",
      href: _ctx.opcostylesheets
    }, null, 8, _hoisted_2),
    _createVNode(_component_v_header, { styleCodeFromParent: _ctx.styleCode }, null, 8, ["styleCodeFromParent"]),
    (!_ctx.showAcknowledgement)
      ? (_openBlock(), _createBlock(_component_v_body, {
          key: 0,
          onShowAckComponent: _ctx.toggleAcknowledgementComponent
        }, null, 8, ["onShowAckComponent"]))
      : _createCommentVNode("", true),
    (_ctx.showAcknowledgement)
      ? (_openBlock(), _createBlock(_component_v_acknowledgement, {
          key: 1,
          custSARID: _ctx.acknowldegementSARID
        }, null, 8, ["custSARID"]))
      : _createCommentVNode("", true),
    _createVNode(_component_v_footer)
  ]))
}