<template>
  <div class="mb-gutter">
    <div class="alert alert--error">
      <ul class="list-styled">
        <span>{{ this.errorMsgFromParent }}</span>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "SARError",
  props: {
    errorMsgFromParent: {
      type: String,
      required: true,
    },
  },
});
</script>
