<template>
  <div class="gd-cctv-details mb-gutter">
    <div class="mb-gutter">
      <h3>
        {{
          `${translate("labeltext.partB")}${translate(
            "labeltext.cctvHeader"
          )}`
        }}
      </h3>
    </div>
    <div class="form-field form-field--gd--full">
      <label class="field-row"
        >{{ $t("labeltext.cctvHelpfulText") }}
      </label>
    </div>

    <div class="form-field form-field--gd">
      <div class="field-row">
        <span
          class="label label--text label--inline"
          data-auto="cctvdetails-lbl"
          >{{ $t("labeltext.cctvfootageReq") }}
        </span>
        <div class="gd-field-multi-select form-field--gd__options">
          <label>
            <input
              type="radio"
              id="CCTVYes"
              value="true"
              ref="CCTVOption"
              :disabled="initModel.isDisabled"
              v-model="newUser.CCTVDetailsRequired"
              @change="clearInputFieldsCCTV"
            />
            <span class="css-radio"></span>
            <span class="label label--inline label-padding" data-auto="cctvdetails-yes-lbl">
              {{ $t("labeltext.yesText") }}
            </span>
          </label>

          <label>
            <input
              type="radio"
              id="CCTVNo"
              value="false"
              :disabled="initModel.isDisabled"
              v-model="newUser.CCTVDetailsRequired"
              @change="clearInputFieldsCCTV"
            />
            <span class="css-radio"></span>
            <span class="label label--inline label-padding" data-auto="cctvdetails-no-lbl">
              {{ $t("labeltext.noText") }}
            </span>
          </label>
        </div>
      </div>
    </div>

    <div v-if="newUser.CCTVDetailsRequired.toString() === 'true'">
      <div class="form-field form-field--gd">
        <div class="field-row">
          <span
            class="label label--text label--inline"
            data-auto="cctvstorevehicle-lbl"
          >
            {{ $t("labeltext.yesStoreVehicle") }}
          </span>
          <div class="gd-field-multi-select form-field--gd__options">
            <label>
              <input
                type="checkbox"
                id="CCTVStore"
                value="true"
                name="cctvstorevehicle"
                :disabled="initModel.isDisabled"
                v-model="newUser.IsCCTVStore"
                @change="clearInputFieldsStoreVehicle"
              />
              <span class="css-checkbox"></span>
              <span
                class="label label--inline label-padding"
                data-auto="cctvstorevehicle-IsCCTVStore-lbl"
              >
                {{ $t("labeltext.store") }}
              </span>
            </label>

            <label>
              <input
                type="checkbox"
                id="CCTVCarPark"
                value="true"
                name="cctvstorevehicle"
                :disabled="initModel.isDisabled"
                v-model="newUser.IsCCTVCarPark"
                @change="clearInputFieldsStoreVehicle"
              />
              <span class="css-checkbox"></span>
              <span
                class="label label--inline label-padding"
                data-auto="cctvstorevehicle-IsCCTVCarPark-lbl"
              >
                {{ $t("labeltext.carPark") }}
              </span>
            </label>
          </div>
        </div>
      </div>

      <div
        v-if="
          newUser.IsCCTVStore.toString() === 'true' ||
          (newUser.IsCCTVCarPark && newUser.IsCCTVCarPark.toString() === 'true')
        "
      >
        <div class="form-field form-field--gd">
          <div class="field-row">
            <span
              class="label label--text label--inline"
              data-auto="storeeirecode-lbl"
            >
              {{ $t("labeltext.storeEirecode") }}
            </span>
            <input
              type="text"
              :disabled="initModel.isDisabled"
              v-model="newUser.StorePostCode"
              :placeholder="
                initModel.isDisabled == true
                  ? ''
                  : translate('labeltext.storeEirecode')
              "
              name="storepostcode"
              style="max-width: 45% !important"
            />
            <span class="tooltip-icon tooltip-icon-label">
              <i data-auto="StoreEireCodeInfo-btn"
                >i
                <v-tooltip
                  activator="parent"
                  location="bottom"
                  width="320px"
                  :contained="true"
                  :open-on-click="true"
                >
                  {{ translate("labeltext.storeEireCodeTooltip") }}
                </v-tooltip>
              </i>
            </span>
          </div>
        </div>

        <div class="form-field form-field--gd">
          <div class="field-row">
            <span
              class="label label--text label--inline"
              data-auto="storedateofvisit-lbl"
            >
              {{ $t("labeltext.dateOfVisit") }}
            </span>
            <date-picker
              :placeholder="
                initModel.StoreDateofvisit == '' && initModel.isDisabled
                  ? '  '
                  : translate('labeltext.selectDatePlc')
              "
              v-model="initModel.StoreDateofvisit"
              :disabled="initModel.isDisabled"
              :locale="initModel.datepickerLang"
              :enable-time-picker="false"
              :format="dateFormat"
              auto-apply
              hide-input-icon
              name="storedateofvisit"
            >
            </date-picker>
          </div>
        </div>

        <div class="form-field form-field--gd">
          <label class="field-row">
            <span
              class="label label--text label--inline"
              data-auto="startvisittime-lbl"
            >
              {{ $t("labeltext.startTimeOfVisit") }}</span
            >

            <div class="gd-field-multi-select gd-field-multi-select--time">
              <select
                v-model="initModel.storeStartHourOfVisit"
                :disabled="initModel.isDisabled"
                name="starthourofvisit"
              >
                <option value="">{{ $t("labeltext.hour") }}</option>
                <option v-for="(item, index) in 24" :key="index">
                  {{
                    index.toLocaleString("en-US", {
                      minimumIntegerDigits: 2,
                      useGrouping: false,
                    })
                  }}
                </option>
              </select>

              <select
                v-model="initModel.storeStartMinuteOfVisit"
                :disabled="initModel.isDisabled"
                name="startminuteofvisit"
              >
                <option value="">{{ $t("labeltext.minute") }}</option>
                <option v-for="(item, index) in 60" :key="index">
                  {{
                    index.toLocaleString("en-US", {
                      minimumIntegerDigits: 2,
                      useGrouping: false,
                    })
                  }}
                </option>
              </select>
            </div>
          </label>
        </div>

        <div class="form-field form-field--gd">
          <label class="field-row">
            <span
              class="label label--text label--inline"
              data-auto="endvisittime-lbl"
              >{{ $t("labeltext.endTimeOfVisit") }}</span
            >

            <div class="gd-field-multi-select gd-field-multi-select--time">
              <select
                v-model="initModel.storeEndHourOfVisit"
                :disabled="initModel.isDisabled"
                name="endhourofvisit"
              >
                <option value="">{{ $t("labeltext.hour") }}</option>
                <option v-for="(item, index) in 24" :key="index">
                  {{
                    index.toLocaleString("en-US", {
                      minimumIntegerDigits: 2,
                      useGrouping: false,
                    })
                  }}
                </option>
              </select>

              <select
                v-model="initModel.storeEndMinuteOfVisit"
                :disabled="initModel.isDisabled"
                name="endminuteofvisit"
              >
                <option value="">{{ $t("labeltext.minute") }}</option>
                <option v-for="(item, index) in 60" :key="index">
                  {{
                    index.toLocaleString("en-US", {
                      minimumIntegerDigits: 2,
                      useGrouping: false,
                    })
                  }}
                </option>
              </select>
            </div>
          </label>
        </div>

        <div class="form-field form-field--gd">
          <label class="field-row">
            <span
              class="label label--text label--inline"
              data-auto="addinfo-lbl"
            >
              {{ $t("labeltext.additionalInfoReqd") }}
            </span>
            <textarea
              v-model="newUser.AdditionalInfoReqd"
              :placeholder="
                initModel.isDisabled == true
                  ? ''
                  : translate('labeltext.additionalInfoReqdPlaceholder')
              "
              :disabled="initModel.isDisabled"
              name="addinfo"
              maxlength="100"
              rows="3"
            ></textarea>
          </label>
        </div>
      </div>

      <div
        v-if="
          newUser.IsCCTVCarPark && newUser.IsCCTVCarPark.toString() === 'true'
        "
      >
        <div class="form-field form-field--gd">
          <label class="field-row">
            <span
              class="label label--text label--inline"
              data-auto="vehiclemake-lbl"
            >
              {{ $t("labeltext.vehicleMake") }}
            </span>
            <input
              type="text"
              v-model="newUser.VehicleMake"
              :disabled="initModel.isDisabled"
              :placeholder="
                initModel.isDisabled == true
                  ? ''
                  : translate('labeltext.vehicleMake')
              "
              name="vehiclemake"
              maxlength="25"
            />
          </label>
        </div>

        <div class="form-field form-field--gd">
          <label class="field-row">
            <span
              class="label label--text label--inline"
              data-auto="vehiclemodel-lbl"
            >
              {{ $t("labeltext.model") }}
            </span>
            <input
              type="text"
              v-model="newUser.Model"
              :disabled="initModel.isDisabled"
              :placeholder="
                initModel.isDisabled == true
                  ? ''
                  : translate('labeltext.model')
              "
              name="vehiclemodel"
              maxlength="25"
            />
          </label>
        </div>

        <div class="form-field form-field--gd">
          <label class="field-row">
            <span
              class="label label--text label--inline"
              data-auto="vehiclecolour-lbl"
            >
              {{ $t("labeltext.colour") }}
            </span>
            <input
              type="text"
              v-model="newUser.Colour"
              :disabled="initModel.isDisabled"
              :placeholder="
                initModel.isDisabled == true
                  ? ''
                  : translate('labeltext.colour')
              "
              name="vehiclecolour"
              maxlength="25"
            />
          </label>
        </div>

        <div class="form-field form-field--gd">
          <label class="field-row">
            <span
              class="label label--text label--inline"
              data-auto="vehicleregnumber-lbl"
            >
              {{ $t("labeltext.regNo") }}
            </span>
            <input
              type="text"
              v-model="newUser.RegistrationNumber"
              :disabled="initModel.isDisabled"
              :placeholder="
                initModel.isDisabled == true
                  ? ''
                  : translate('labeltext.regNo')
              "
              name="vehicleregnumber"
              maxlength="25"
            />
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import UserCCTVDetailsMixin from "@/mixins/userCCTVDetails.mixin";

export default defineComponent({
  name: "UserCCTVDetailsV5",
  mixins: [UserCCTVDetailsMixin],
  methods: {
    clearInputFieldsCCTV: function () {
      if (this.newUser.CCTVDetailsRequired.toString() === "false") {
        this.newUser.IsCCTVStore = "";
        this.newUser.IsCCTVVehicle = "";
        this.newUser.StoreConcerned = "";
        this.initModel.StoreDateofvisit = "";
        this.initModel.storeStartHourOfVisit = "";
        this.initModel.storeStartMinuteOfVisit = "";
        this.initModel.storeEndHourOfVisit = "";
        this.initModel.storeEndMinuteOfVisit = "";
        this.newUser.VisitDT = "";
        this.newUser.ClothingWorn = "";
        this.newUser.VehicleMake = "";
        this.newUser.Model = "";
        this.newUser.Colour = "";
        this.newUser.RegistrationNumber = "";
      }
    },
    clearInputFieldsStoreVehicle: function () {
      if (
        this.newUser.IsCCTVStore.toString() !== "true" &&
        this.newUser.IsCCTVCarPark &&
        this.newUser.IsCCTVCarPark.toString() !== "true"
      ) {
        this.newUser.StorePostCode = "";
        this.initModel.StoreDateofvisit = "";
        this.initModel.storeStartHourOfVisit = "";
        this.initModel.storeStartMinuteOfVisit = "";
        this.initModel.storeEndHourOfVisit = "";
        this.initModel.storeEndMinuteOfVisit = "";
        this.newUser.VisitDT = "";
        this.newUser.AdditionalInfoReqd = "";
        this.newUser.VehicleMake = "";
        this.newUser.Model = "";
        this.newUser.Colour = "";
        this.newUser.RegistrationNumber = "";
      } else if (
        this.newUser.IsCCTVStore.toString() === "true" &&
        this.newUser.IsCCTVCarPark &&
        this.newUser.IsCCTVCarPark.toString() !== "true"
      ) {
        this.newUser.VehicleMake = "";
        this.newUser.Model = "";
        this.newUser.Colour = "";
        this.newUser.RegistrationNumber = "";
      }
    },
  },
});
</script>

<style scoped>
/* Scoped style to constrain the width of the date picker */
.dp__main {
    font-family: var(--dp-font-family);
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    box-sizing: border-box;
    position: relative;
    max-width: 49% !important;
}
</style>