export default {
    validationtext: {
        validationHeading:'Proszę poprawić poniższe błędy',
        validationHeadingV2:'Proszę poprawić następujące błędy',
        validationReqType: 'Proszę wybrać przynajmniej jeden wniosek',
        validationReqTypeV2: 'Proszę wybrać co najmniej jeden rodzaj żądania spośród dostępnych opcji.',
        validationTitle: 'Proszę wybrać tytuł',
        validationTitleV2: 'Proszę uzupełnić „Tytuł”',
        validationOtherTitle: 'Proszę wpisać wartość w pole "Inny tytuł"',
        validationForename: 'Proszę wpisać imię',
        validationForenameV2: 'Proszę uzupełnić swoje „Imię”',
        validationSurname: 'Proszę wpisać nazwisko',
        validationSurnameV2: 'Proszę uzupełnić swoje „Imię”',
        validationEmailAddress: 'Prosze wpisać adres email',
        validationEmailAddressV2: 'Podaj swój adres e-mail',
        validationCCTV: 'Proszę potwierdzić czy wymagane jest nagranie z monitoringu',
        validationValidEmailAdd: 'Proszę wpisać ważny adres email',
        validationValidEmailAddV2: 'Prosimy o wprowadzenie poprawnego adresu e-mail',
        validationDocType:'Proszę wybrać Typ Dokumentu przed przesłaniem',
        validationSelectFile: 'Proszę wybrać plik przed przesłaniem',
        validationLoadingForm: 'Wystąpił błąd w przesyłaniu. Proszę spróbować później',
        validationNotAuth: 'Nie ma Pan/Pani dostępu do tej strony',
        validationSubmitForm: 'Wystąpił błąd w składaniu wniosku. Proszę spróbować później',
        validationRetreiveDetails: 'Wystąpiły błędy podczas pobierania szczegółów.  Proszę spróbować później',
        validationDownloadFile: 'Wystąpiły  błędy podczas ściągania pliku.  Proszę spróbować później',
        validation404: "Wniosek nie został znaleziony",
        validationMaxFileSize: 'Rozmiar pliku przekracza maksymalny limit {filesize}',
        validationFileUploadFailed: 'Błędy podczas przesyłania',
        validationAllowedFileTypes: 'Dopuszczalne typy pliku do przesłania są {allowedFileExtensions}',
        validationStartDate: "Data rozpoczęcia nie może być późniejsza niż data dzisiejsza",
        validationEndDate: "Data końcowa nie może być późniejsza niż data dzisiejsza",
        validationStoreVisitDate: "Data wizyty nie może być późnieszja niż data późniejsza",
        validationEndDateLessThanStartDate: "Końcowa data nie może być wcześniejsza niż data rozpoczęcia",
    
        validationNoRecordsFound: "Brak wyników wyszukiwania",
        validationSearchFailed: 'Wystąpił błąd. Proszę spróbować później',
    
        validationAddNotes: "Wpisz notatkę",
        validationAddNotesError:"wystąpiło kilka błędów podczas dodawania notatki. Spróbuj ponownie później",
        validationDocTitle: "Podaj tytuł dokumentu",
    
        validationAddTaskDescription: "Opis nie może być puste",
        validationAddTaskAssignee: "Cesjonariusz nie może być puste",
        validationAddTaskError:"wystąpiło kilka błędów podczas dodawania zadania. Spróbuj ponownie później",
    
        validationconsenthistory: "Podaj identyfikator zgody na retreive szczegóły",
        validationConsentHistoryGrid: "Niektóre błędy wystąpiły podczas retreiving historię zgody. Spróbuj ponownie później.",
        validationNoRecordsFoundConsentHstry: 'Nie znaleziono wprowadzonej numer referencyjny klienta rekordy'
    },
    labeltext: {
        dataProtectionAct: 'Ustawa o ochronie danych osobowych',
        dataProtectionActV2: 'Ogólne rozporządzenie o ochronie danych',
        dataSAR: 'Formularz Wniosku Osoby, której Dane Dotyczą',
        dataSARV2: 'Formularz wniosku o dostęp do danych',
        completedByWarning:'NINIEJSZY FORMULARZ UZUPEŁNIA OSOBA, KTÓREJ DOTYCZĄ WPROWADZANE DANE',
        completedByWarningV2:'Formularz musi zostać wypełniony przez osobę chcącą uzyskać dostęp do swoich danych osobowych',
        typeOfRequest: '*Rodzaj Wniosku',
        typeOfRequestV2: 'Rodzaj wniosku',
        requestDataAccess: 'Wniosek o dostęp do danych',
        requestDataAccessV2: 'Prawo dostępu do danych',
        requestDataDelete: 'Wniosek o usunięcie danych ',
        requestDataDeleteV2: 'Prawo do usunięcia (prawo do bycia zapomnianym)',
        requestDataRect: 'Wniosek o sprostowanie danych',
        requestDataRectV2: 'Prawo do korekty',
        requestDataObject: 'Wniosek dot. wyrażenia sprzeciwu wobec przetwarzania Pana/i danych',
        requestDataObjectV2: 'Prawo do sprzeciwu',
        partAHeadingText: 'Część A: Twoje dane',
        partAHeadingTextV2: 'Część A: informacje kontaktowe',
        partABodyText: 'Prosimy uzupełnić poniższy formularz. Podane przez Pana/ią informacje będą wykorzystywane przez {brandPlcName1} w celu przetworzenia oraz zarządzania tym wnioskiem. {brandPlcName2} może skorzystać z usług strony trzeciej przy przetwarzaniu Pana/i wniosku i w związku z tym może udostępnić Pana/i dane osobowe tej stronie trzeciej',
        partABodyTextV2: 'Prosimy o wypełnienie tego formularza. Informacje podane przez użytkownika zostaną wykorzystane przez firmę {brandPlcName1} do przetworzenia wniosku. Wniosek może wymagać od {brandPlcName2} skorzystania z usług podwykonawcy, w związku z czym dane osobowe użytkownika mogą zostać przekazane temu podwykonawcy w celu ukończenia przetwarzania wniosku. Po zamknięciu wniosku użytkownika, jego dane będą przechowywane przez okres jednego roku, a następnie zostaną usunięte',
        title: '*Tytuł',
        titleDefaultValue: 'Proszę wybrać tytuł',
        titleDefaultValueV2: 'Wybierz „Tytuł”',
        otherTitleLabel:'*Jeśli inny, proszę wskazać',
        otherTitlePlc: 'Inny tytuł',
        surname: '*Nazwisko/Nazwisko panieńskie',
        surnameV2: '*Nazwisko',
        forename: '*Imię',
        forenameV2: '*Imię',
        currentAddress: 'Obecny adres',
        currentAddressV2: 'Adres',
        addline1Plc:'Adres',
        addLine2Plc: 'Adres',
        addLine3Plc: 'Adres',
        townPlc: 'Miejscowość',
        townPlcV2: 'Miasto',
        countyPlc: ' ',
        countyPlcV2: 'Departament',
        countryDefaultValue: 'Proszę wybrać kraj',
        countryDefaultValueV2: 'Wybierz kraj',
        postCode: 'Kod pocztowy',
        postCodeV2: 'Kod pocztowy',
        prevAddressWarning: 'Czy zechciałby Pan/Pani podać poprzedni adres, który może obejmować Państwa dane osobowe?',
        prevAddressWarningV2: 'Czy chcesz dodać kolejny adres, pod którym mogły zostać zebrane Twoje dane osobowe?',
        prevAddress: 'Poprzedni adres',
        emailAddress: '*Adres email',
        emailAddressV2: '*Adres e-mail',
        contactNumber: 'Numer tel. kontaktowy',
        contactNumberV2: 'Numer telefonu',
        custEmpRel:'Proszę określić relację z Castorama',
        relationshipDetailsText: 'Please indicate the relationship the subject of the personal data has with {brandPlcName1} and provide the further information requested.',
        relationshipDetailsTextV2: 'Wskaż relację między podmiotem danych osobowych a firmą {brandPlcName1} i przekaż żądane dodatkowe informacje.',
        customer:'Klient',
        employee:'Pracownik',
        employeeV2:'pracownik (jeśli użytkownik był lub jest pracownikiem firmy {brandPlcName})',
        custNo:'Numer klienta',
        custNoV2:'Jeśli jesteś klientem: Numer zamówienia',
        orderNo: 'Numer zamówienia',
        complainRef:' Numer reklamacji',
        complainRefV2:' Numer referencyjny reklamacji',
        empNo: 'Numer pracownika',
        empNoV2: 'Jeśli jesteś pracownikiem: Numer rejestracyjny',
        startDate: 'Data rozpoczęcia',
        startDateV2: 'Data wpisu ',
        endDate:'Data zakończenia (jeśli dotyczy)',
        endDateV2:'Data wydania (jeśli dotyczy)',
        storeHeadOff: 'Stanowisko w sklepie/siedzibie',
        storeHeadOffV2: 'Stanowisko w sklepie/siedzibie',
        partBHeaderText:'Część B: Proszę szczegółowo opisać swój wniosek (w odniesieniu do monitoringu proszę wypełnić Część C)',
        reqDetailsText:'Please provide any further relevant details regarding your request:',
        reqDetailsTextV2:'Prosimy o podanie wszelkich innych istotnych szczegółów dotyczących wniosku:',
        reqDetailsPlc: 'Szczegóły wniosku',
        reqDetailsPlcV2: 'Wymagane szczegóły',
        partCHeaderText: 'Część C: Nagranie z monitoringu',
        cctvfootageReq: '*Czy wymagane jest nagranie z monitoringu',
        yesText: 'Tak',
        noText: 'Nie',
        yesStoreVehicle: 'Jeżeli tak, dotyczy to: ',
        store: 'Sklepu',
        vehicle: 'Pojazdu',
        storeConcerned: 'Sklep, którego dotyczy',
        dateOfVisit:'Data wizyty',
        timeOfVisit: 'Czas wizyty (format 24 godzinny)',
        hour: 'Godzina',
        minute: 'Minuty',
        clothAtVisit: 'Ubranie noszone w trakcie wizyty',
        vehicleMake:'Marka pojazdu',
        model: 'Model',
        colour: 'Kolor',
        regNo: 'Numer rejestracyjny',
        attachments: 'Załączniki',
        partDHeadingText: 'Część D: Uzupełnienie formularza ',
        partDBodyText: ' ',
        authThirdParty:'Jeżeli upoważnił Pan/i stronę trzecią do złożenia wniosku lub otrzymania Pana/i danych osobowych w Pana/i imieniu (np. prawnik), prosimy o kopię dokumentu zawierającego upoważnienie',
        authThirdPartyV2:'Jeśli użytkownik upoważnia stronę trzecią (np. pełnomocnika) do złożenia wniosku w jego imieniu, najpierw należy przesłać kopię upoważnienia.',
        docTypeDefaultValue: 'Proszę wybrać dokument',
        docTypeDefaultValueV2: 'Wybierz typu dokumentu',
        idProof: ' ',
        idProofV2: 'Dowód tożsamości',
        addProof: ' ',
        authDoc:'Upoważnienie',
        otherDoc:'Inne',
        chooseFile: 'Wybierz plik',
        upload: 'Ściągnij',
        declaration: 'Oświadczenie',
        declarationText: 'INFORMACJE PRZEKAZANE W NINIEJSZYM WNIOSKU SĄ POPRAWNE A JA JESTEM OSOBĄ, KTÓREJ ONE DOTYCZĄ. ROZUMIEM, ŻE JEŻELI NIE WYPEŁNIĘ NINIEJSZEGO FORMULARZA POPRAWNIE, ROZPATRZENIE MOJEGO WNIOSKU MOŻE SIĘ OPÓŹNIĆ ',
        declarationTextV2: 'Oświadczam, że informacje przekazane w tym formularzu są poprawne oraz że jestem podmiotem danych, do których odnosi się wniosek. Zostałem poinformowany(-a), że może wystąpić opóźnienie w przetwarzaniu wniosku, jeśli formularz jest niekompletny lub nieprawidłowy.',
        declarationHeader1: 'Oświadczenie:',
        declarationHeader2:'prosimy o wybranie oświadczenia odpowiadającego wnioskowi użytkownika:',
        declarationHeader2V2:'prosimy o wybranie oświadczenia odpowiadającego wnioskowi użytkownika:',
        declarationSubHeader1: 'Wyłącznie do użytku wewnętrznego: ',
        declarationType1: 'Jeśli użytkownik jest osobą, do której odnoszą się dane osobowe:',
        declarationType1V2: 'Jeśli użytkownik jest osobą, do której odnoszą się dane osobowe:',
        declarationType2: 'Jeśli użytkownik jest wewnętrznym pracownikiem firmy {brandPlcName1}, prosimy o wypełnienie w imieniu osoby, do której odnoszą się dane osobowe: ',
        declarationType2V2: 'Jeśli użytkownik jest wewnętrznym pracownikiem firmy {brandPlcName1}, prosimy o wypełnienie w imieniu osoby, do której odnoszą się dane osobowe: ',
        declarationtext2: ' Informacje podane w formularzu wniosku są poprawne i odpowiadają informacjom przekazanym przez osobę, do której się one odnoszą. Osoba, do której odnosi się ten formularz, rozumie, że jeśli przekazane informacje nie są wystarczające do prawidłowego wypełnienia formularza wniosku, wniosek może zostać opóźniony.',
        declarationtext2V2: ' Informacje podane w formularzu wniosku są poprawne i odpowiadają informacjom przekazanym przez osobę, do której się one odnoszą. Osoba, do której odnosi się ten formularz, rozumie, że jeśli przekazane informacje nie są wystarczające do prawidłowego wypełnienia formularza wniosku, wniosek może zostać opóźniony.',
        cancel: 'Anuluj',
        print: 'Drukuj',
        submit: 'Prześlij',
        submitV2: 'Wyślij',
        ackText: 'Państwa wniosek został przesłany pomyślnie',
        reqID: 'Numer wniosku#:',
        makeNewReq: 'Nowy wniosek',
        fileUploadSuccessful: 'Plik ściągnięty pomyślnie',
        selectDatePlc: 'Wybierz datę',
        addtionalRequestTypeText: 'Dla innego typu wniosku proszę uzupełnić część B tego fomrularza',
        listUploadedFiles: 'Przesłane pliki:',
        Mr: 'Pan',
        Mrs:'Pani',
        Miss: 'Panna',
        Ms: '',
        OtherTitle: 'Inne',
        OtherTitleV2: '',
        partA: 'Część A: ',
        partB: 'Część B: ',
        partBV2: 'Część B: ',
        partC: 'Część C: ',
        partD: 'Część D: ',
        cctvHeader:'Nagranie z monitoringu',
        formCompletionHeader: 'Uzupełnienie formularza',	
        formCompletionHeaderV2: 'dodawanie załącznika, jeśli jest on istotną częścią wniosku',
        formCompletionBody: 'W celu zapewnienia bezpieczeństwa danych użytkownika oraz zależnie od charakteru i treści jego wniosku możemy poprosić użytkownika o dodatkowe informacje potwierdzające tożsamość.',
        formCompletionBodyV2: 'W celu zapewnienia bezpieczeństwa danych użytkownika oraz zależnie od charakteru i treści jego wniosku możemy poprosić użytkownika o dodatkowe informacje potwierdzające tożsamość.',
        
        reportRequestID: 'Numer wniosku',
        reportEmailID: 'Email',
        reportLastName: 'Nazwisko',
        reportBrandName: 'Nazwa handlowa',
        reportStartDate: 'Data rozpoczęcia',
        reportEndDate: 'Data zakończenia',
        reportStatus: 'Statut',
        reportBrandNameDefaultValue: 'Wybrać nazwę handlową',
        reportStatusDefaultValue: 'Wybrać statut',
        reportHeaderName: 'NAZWA',
        reportHeaderReceivedType: 'OTRZYMAŁ TYP',
        reportHeaderReceivedDate: 'OTRZYMAŁ DT',
        reportHeaderAccessData: 'DANE DOSTĘPOWE',
        reportHeaderDataRect: 'RECTIFICATION DANYCH',
        reportHeaderDataDel: 'usuwanie danych',
        reportHeaderObject: 'Prawo sprzeciwu',
        reportHeaderCompletedDate: 'Zakończono DT',
        
        reportSearch: 'Szukaj',
        reportDownload: 'Pobieranie',
        reportRecords: 'Dokumentacja',
        reportsYes:'Y',
        reportsNo:'N',
        reportsRecords:'Showing xxx to xxx of records',
        reportPaginationText:'Pokazuje {from} do {to} z {count} rekordy | {count} rekordy | 1 rekord', 
        originalSARData: 'Oryginalny SAR danych jest jak poniżej',
    
        NavigationHome: 'Dom',
        NavigationReports: 'Raporty',
    
        requestStatus: 'Statut wniosku',
        assignTo :'Przypisać do',
        requestArrivalType:'Typ wniosku przychodzącego',
        SARSubmittedDate:'Data przesłania SAR',
        actualStartDate:'Aktualna Data',
        expectedCompletionDate:'Data przesłania SAR',
        actualCompletionDate:'Spodziewana data zakończenia',
        notes:'Notatki',
        historicalNotes:'Notatki historyczne',
    
        Submitted:'Przesłane',
        InProgress:'W trakcie',
        OnHold:'w oczekiwaniu',
        ResponseSent:'Odpowiedź wysłana',
        Complete:'Zakończone',
        Open: 'Otwarty',
    
        Web:'Web',
        Phone:'Telefon',
        SocialMedia:'Media społecznościowe',
        Post:'Stanowisko',
        Email:'Email',
    
        doctype: 'Typ dokumentu',
        selectUpload : 'Wybierz i prześlij plik',
        noFile : 'Nie wbrany plik',
    
        jan :	'styczeń',
        feb :	'luty',
        mar :	'Marsz',
        apr :	'kwiecień',
        may :	'Może',
        jun :	'czerwiec',
        jul :	'lipiec',
        aug :	'sierpień',
        sep :	'wrzesień',
        oct :	'październik',
        nov :	'listopad',
        dec :	'grudzień',
        sun :	'niedziela',
        mon :	'poniedziałek',
        tue :	'wtorek',
        wed :	'środa',
        thu :	'czwartek',
        fri :	'piątek',
        sat :	'sobota',
    
    
        tooltipAccessRequest: "Prawo dostępu: Osoba, której dane dotyczą jest uprawniona do otrzymania kopii danych osobowych podlegających przetwarzaniu przez {brandPlcName1}.",
        tooltipDataDeletion: "Prawo do usunięcia danych: osoba, której dane dotyczą, ma prawo żądania od {brandPlcName1} niezwłocznego usunięcia dotyczących jej danych osobowych. Nie ma to zastosowania w zakresie w jakim przetwarzanie jest niezbędne do wywiązania się z prawnego obowiązku lub do ustalenia, dochodzenia lub obrony roszczeń.",
        tooltipDataObject: "Prawo do sprzeciwu: osoba, której dane dotyczą, ma prawo wnieść sprzeciw wobec przetwarzania dotyczących jej danych przez {brandPlcName1} na potrzeby marketingu bezpośredniego lub w innym celu chyba, że istnieją prawnie uzasadnione podstawy do przetwarzania lub podstawy do ustalenia, dochodzenia lub obrony roszczeń.",
        tooltipDataRectification: "Prawo do sprostowania danych: Osoba, której dane dotyczą, ma prawo żądania od {brandPlcName1} sprostowania dotyczących jej danych osobowych, które są nieprawidłowe. Z uwzględnieniem celów przetwarzania, osoba, której dane dotyczą, ma prawo żądania uzupełnienia niekompletnych danych osobowych, w tym poprzez przedstawienie dodatkowego oświadczenia.",
        tooltipAccessRequestV2: "Polityka ochrony danych pomoże Ci zrozumieć, w jaki sposób Twoje dane są wykorzystywane.",
        dataAccessRequestHelpfulInfoV2: 'Skorzystanie z prawa do dostępu umożliwia użytkownikowi otrzymanie swoich danych oraz uzyskanie wiedzy o ich wykorzystaniu. <br />W określonych przypadkach dostęp do niektórych informacji może być ograniczony w celu uniknięcia niekorzystnego wpływu, jaki mógłby mieć na prawa innych osób, tajemnice handlowe lub prawa własności intelektualnej.<br /><strong>Jeśli użytkownik chciałby zapoznać się z informacjami dotyczącymi swoich wcześniejszych zakupów lub informacjami dotyczącymi swojego konta, może to zrobić natychmiast, logując się do swojego konta w naszej witrynie i przechodząc do sekcji „Moje konto”.</strong>',
        dataDeleteRequestHelpfulInfoV2: 'Użytkownik ma prawo zwrócić się do nas o usunięcie swoich danych osobowych.<br />W określonych przypadkach, takich jak zobowiązanie prawne do przechowywania informacji lub konieczność ich zachowania w celu skorzystania z naszych praw w sądzie, usunięcie może być częściowe lub możemy odmówić jego wykonania.<br /><strong>Jeśli użytkownik chciałby usunąć informacje dotyczące płatności lub zaktualizować swoje preferencje marketingowe, może to zrobić natychmiast, logując się do swojego konta w naszej witrynie i przechodząc do sekcji „Moje konto”.</strong>',
        dataRectifyRequestHelpfulInfoV2:'Prawo do korekty umożliwia użytkownikowi zmianę lub aktualizację swoich danych osobowych.<br /><strong>Jeśli użytkownik chciałby zaktualizować swoje dane osobowe, na przykład wprowadzić nowy adres, zmienić imię lub nazwisko bądź wprowadzić nowe informacje dotyczące płatności, może to zrobić natychmiast, logując się do swojego konta w naszej witrynie i przechodząc do sekcji „Moje konto”.</strong>',
        dataProcessRequestHelpfulInfoV2:'Prawo do sprzeciwu umożliwia użytkownikowi wyrażenie niezgody na wykorzystanie jego danych w określony sposób (np. do celów marketingu bezpośredniego, profilowania itp.).<br /><strong>Użytkownik może w dowolnym momencie zrezygnować z otrzymywania komunikacji marketingowej, logując się do naszej witryny i przechodząc do sekcji „Moje konto” lub klikając łącze do anulowania subskrypcji zamieszczone u dołu każdej wiadomości e-mail lub SMS.</strong>',
        tooltipDataDeletionV2: "Jeśli chcesz dokonać nowych zakupów po usunięciu konta, musisz utworzyć nowe konto.",
        tooltipDataRectificationV2: "Jeśli przeniosłeś się lub zmieniłeś numer telefonu, zaktualizuj swoje dane.",
        tooltipDataObjectV2: "W kilku przypadkach konieczne będzie podkreślenie powodów związanych z konkretną sytuacją, które uzasadniają sprzeciw (z wyjątkiem kwestii poszukiwania, w przypadku których uzasadnienie nie jest wymagane).",
        sarmgmtAddNote: "Dodaj notatki",
        sarmgmtAddNotesButton: "Dodaj",
        sarmgmtCancelNotes: "Anuluj",
        sarmgmtDocTitle: 'Tytuł dokumentu',
        sarmgmtUploadDoc: 'Prześlij dokument',
        sarmgmtattachmentUploadedBy: 'Przesłanych przez {uploadedby} w {timestamp}', 
    
        sarmgmtAddTask: "Dodaj Zadania",
        sarmgmtViewTask: "Zobacz Task",	
        sarmgmtCancelTasks: "Ukryj zadanie",
        TaskID:"Zadanie #",
        Description:"Opis",
        Assignee:"Mandatariusz",
        CreatedDT:"Stworzony",
        CompletedDT:"Zakończony",
        Comment:"Komentarz",
        taskStatus: "Statut",
    
        taskDeleteMessage: "Proszę potwierdzić, że chcesz usunąć zadanie",
        taskDelete: "Kasować",
        taskSave: 'Zapisać',
        sarmgmtAddNotes: 'dodał komentarz',
    
        consentCustomerId: "Identyfikator klienta",
        consentMarketingTextHeader: "Marketing klienta zgody: ",
        consentTooltipCustRef: "Identyfikator klienta: Odnosi się do identyfikatora przeciwko której zgoda klienta jest przechowywany w repozytorium PKBR zgodą. Identyfikator ten może być unikalny numer referencyjny, numer lojalność, e-mail lub numer telefonu. Proszę odnieść się do wytycznych na identyfikator klienta dla PKBR repozytorium dla danego rynku.",
    
        consentHstryCustomerId : 'Identyfikator klienta',
        consentHstryOpcoName: 'Nazwa handlowa',
        consentHstryChannel: 'Kanał',
        consentHstryPrivacyPolicyVerId: 'Polityka prywatności Ver Id',
        consentHstryMarketingChannelVersion: 'Marketing Kanał Version',
        consentHstryOptIn: 'Opt In',
        consentHstrySubmitedDate: 'zgłosił Data',
        consentHstrySource: 'Źródło',
        consentHstrySubmittedBy: 'Przez zgłosił',
        consentHstryfileDownloadName: 'Zgoda Report.csv',
        consentHistoryButton: "Uzyskaj historię zgody",
        taskEdit: 'Edytować'
    },    
}