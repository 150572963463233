export default {
    validationtext: {
        validationHeading:'Corectați următoarele erori:',
        validationReqType: 'Furnizați cel puțin un tip de solicitare',
        validationTitle: 'Furnizați un titlu',
        validationOtherTitle: 'Introduceți o valoare în câmpul "Alt titlu"',
        validationForename: 'Furnizați un prenume',
        validationSurname: 'Furnizați un nume de familie',
        validationEmailAddress: 'Furnizați o adresă de e-mail',
        validationCCTV: 'Vă rugăm să confirmați dacă este necesară înregistrarea CCTV',
        validationValidEmailAdd: 'Vă rugăm să furnizați o adresă de e-mail valabilă',
        validationDocType:'Selectați un tip de document înainte de încărcare',
        validationSelectFile: 'Selectați un fișier înainte de încărcare',
        validationLoadingForm: 'Există unele erori la încărcarea formularului. Vă rugăm să încercați din nou mai tarziu',
        validationNotAuth: 'Nu sunteți autorizat (ă) să vizualizați această pagină',
        validationSubmitForm: 'Anumite erori au apărut la trimiterea formularului. Vă rugăm să încercați din nou mai târziu',
        validationRetreiveDetails: 'Anumite erori au apărut la reluarea detaliilor. Vă rugăm să încercați din nou mai târziu',
        validationDownloadFile: 'Au apărut unele erori în timpul descărcării fișierului. Vă rugăm să încercați din nou mai târziu',
        validation404: "Resursa pe care ați solicitat-o nu a putut fi găsită",
        validationMaxFileSize: 'Dimensiunea fișierului depășește limita maximă permisă de {filesize}',
        validationFileUploadFailed: 'Au apărut unele erori la încărcarea fișierului. Vă rugăm să încercați din nou mai târziu',
        validationAllowedFileTypes: 'Tipurile de fișiere acceptate pentru încărcare sunt {allowedFileExtensions}. Încărcați un fișier cu extensie de fișier compatibilă',
        validationStartDate: "Data de începere nu poate fi mai mare decât data de astăzi",
        validationEndDate: "Data de încetare nu poate fi mai mare decât data de astăzi",
        validationStoreVisitDate: "Data vizitei nu poate fi mai mare decât data de astăzi",
        validationEndDateLessThanStartDate: "Data de încetare nu poate fi mai mică decât data de începere",
    
        validationNoRecordsFound: "Nu s-au găsit rezultate pentru criteriile de căutare introduse",
        validationSearchFailed: 'Au apărut unele erori. Vă rugăm să încercați mai târziu',
        
        validationAddNotes: "Vă rugăm să introduceți o notă",
        validationAddNotesError:"Unele erori au avut loc în timp ce adăugarea de note. Vă rugăm să încercați din nou mai târziu",
        validationDocTitle: "Vă rugăm să introduceți un titlu document",
    
        validationAddTaskDescription: "Descrierea nu poate fi necompletat",
        validationAddTaskAssignee: "Repartizatã nu poate fi necompletat",
        validationAddTaskError:"Unele erori au avut loc în timp ce adăugarea de sarcini. Vă rugăm să încercați din nou mai târziu",
    
        
        validationconsenthistory: "Vă rugăm să introduceți un id de acord cu retreive detalii",
        validationConsentHistoryGrid: "Unele erori au avut loc în timp ce retreiving istoricul consimțământ. Vă rugăm să încercați din nou mai târziu.",
        validationNoRecordsFoundConsentHstry: 'Nu există pentru ID-ul de referință client introdus înregistrări'
    },
    labeltext: {
        dataProtectionAct: 'Document privind protecția datelor',
        dataSAR: 'Formular de solicitare persoană vizată',
        completedByWarning:'ACEST FORMULAR TREBUIE COMPLETAT DE PERSOANA CARE ESTE VIZATĂ DE DATELE PERSONALE SOLICITATE',
        typeOfRequest: '*Tipul solicitării',
        requestDataAccess: 'Cerere de accesare date',
        requestDataDelete: 'Cerere de ștergere date',
        requestDataRect: 'Cerere de rectificare date',
        requestDataObject: 'Cerere de obiecție față de prelucrarea datelor',
        partAHeadingText: 'Partea A: Datele dvs.',
        partABodyText: 'Vă rugăm să completați formularul de mai jos. Informațiile pe care le furnizați vor fi utilizate de către {brandPlcName1} pentru a procesa și administra această cerere de acces. {brandPlcName2} poate utiliza o terță parte pentru a procesa cererea și astfel poate fi necesară divulgarea datelor dvs. personale către acea terță parte',
        title: '*Formulă de adresare',
        titleDefaultValue: 'Selectați un titlu',
        otherTitleLabel:'*Dacă ați ales “Alta”, vă rugăm specificați',
        otherTitlePlc: 'alt titlu',
        surname: '*Nume de familie',
        surnameV2: '*Nume de familie',
        forename: '*Prenume',
        forenameV2: '*Prenume',
        currentAddress: 'Adresa actuală',
        addline1Plc:'Adresă 1',
        addLine2Plc: 'Adresă 2',
        addLine3Plc: 'Adresă 3',
        townPlc: 'Oraș',
        countyPlc: 'Județ / Sector',
        countryDefaultValue: 'Selectați o țară',
        postCode: 'Cod poștal',
        postCodeV2: 'Cod poștal',
        prevAddressWarning: 'Doriți să includeți vreo adresă anterioară pentru care ar putea fi stocate alte date personale?',
        prevAddress: 'Adresa anterioară',
        emailAddress: '*Adresă email',
        contactNumber: 'Telefon de contact',
        custEmpRel:'Vă rugăm indicați tipul de relație',
        relationshipDetailsText: 'Please indicate the relationship the subject of the personal data has with {brandPlcName1} and provide the further information requested.',
        relationshipDetailsTextV2: 'Please indicate the relationship the subject of the personal data has with {brandPlcName1} and provide the further information requested.',
        customer:'Client',
        employee:'Angajat (include angajații actuali și anteriori)',
        custNo:'Număr client',
        orderNo: 'Număr comandă',
        complainRef:' Ref sesizare',
        empNo: 'Număr angajat',
        startDate: 'Data angajării',
        endDate:'Data încetării (dacă este cazul)',
        storeHeadOff: 'Funcție magazin/sediu',
        partBHeaderText:'Partea B: Vă rugăm să furnizați mai jos date privind cererea dvs. (pentru CCTV completați Partea C)',
        reqDetailsText:'Please provide any further relevant details regarding your request:',
        reqDetailsPlc: 'Detalii privind solicitarea',
        partCHeaderText: 'Partea C: Înregistrare CCTV',
        cctvfootageReq: '*Înregistrare CCTV necesară',
        yesText: 'Da',
        noText: 'Nu',
        yesStoreVehicle: 'Dacă da, se referă la ',
        store: 'Magazin',
        vehicle: 'Vehicul',
        storeConcerned: 'Magazinul în cauză',
        dateOfVisit:'Data vizitei',
        timeOfVisit: 'Ora vizitei (24 ore)',
        hour: 'Ora',
        minute: 'Minutul',
        clothAtVisit: 'Îmbrăcămintea la momentul vizitei',
        vehicleMake:'Marcă vehicul',
        model: 'Model',
        colour: 'Culoare',
        regNo: 'Număr de înmatriculare',
        attachments: 'Anexe',
        partDHeadingText: 'Partea D: Completarea formularului',
        partDBodyText: 'Cu excepția cazului în care s-a agreat altfel, cererea dvs. trebuie să fie însoțită de copii a două documente oficiale care indică în mod clar numele și adresa dvs. actuală pentru a confirma identitatea dvs. față de {brandPlcName1}; unul dintre aceste documente trebuie să includă o fotografie a dvs. Exemple de documente adecvate de identificare: copii după permisul actual de conducere, pașaport și copii după declarațiile fiscale sau facturi de utilități din urmă cu trei luni față de data cererii dvs',
        authThirdParty:'Dacă ați autorizat o terță parte pentru a face o cerere sau pentru a primi datele dvs. personale în locul dvs. (de exemplu, un solicitant), furnizați de asemenea o copie a documentului care acordă această autoritate',
        docTypeDefaultValue: 'Selectați un tip de document',
        idProof: 'Document de identificare',
        addProof: 'Dovada adresei',
        authDoc:'Document de autorizare',
        otherDoc:'Altul',
        chooseFile: 'Alege fișierul',
        upload: 'Încărcați fișierul',
        declaration: 'Declarație',
        declarationText: 'INFORMAȚIILE FURNIZATE ÎN CADRUL ACESTUI FORMULAR DE SOLICITARE SUNT CORECTE ȘI CONFIRM CĂ SE REFERĂ LA PROPRIA PERSOANĂ. ÎNȚELEG FAPTUL CĂ DACĂ NU COMPLETEZ CORECT ACEST FORMULAR, SOLICITAREA MEA POATE FI PRELUCRATĂ CU ÎNTÂRZIERE',
        cancel: 'Anulare',
        print: 'Listare',
        submit: 'Trimitere',
        ackText: 'Solicitarea dvs. a fost trimisă cu succes',
        reqID: 'Solicitare Carte de Identitate#:',
        makeNewReq: 'Faceți o altă solicitare',
        fileUploadSuccessful: 'Fișier încărcat cu succes',
        selectDatePlc: 'Selectați data',
        addtionalRequestTypeText: 'Pentru orice alte tipuri de solicitări, furnizați detaliile solicitării în partea B a formularului',
        listUploadedFiles: 'Fișiere incarcate:',
        Mr: 'Dl.',
        Mrs:'Dna.',
        Miss: 'Dra.',
        Ms: ' ',
        OtherTitle: 'Altele',
        partA: 'Partea A: ',
        partB: 'Partea B: ',
        partBV2: 'Partea B: ',
        partC: 'Partea C: ',
        partD: 'Partea D: ',
        cctvHeader:'Înregistrare CCTV',
        formCompletionHeader: 'Completarea formularului',
        
        reportRequestID: 'Solicitare Carte de Identitate',
        reportEmailID: 'Adresa de email',
        reportLastName: 'Numele de familie al clientului',
        reportBrandName: 'Numele de marcă',
        reportStartDate: 'Data de început',
        reportEndDate: 'Data de încetare',
        reportStatus: 'Status',
        reportBrandNameDefaultValue: 'Selectați marcă',
        reportStatusDefaultValue: 'Selectați Status',
        reportHeaderName: 'NUME',
        reportHeaderReceivedType: 'TIPUL PRIMITE',
        reportHeaderReceivedDate: 'PRIMITE DT',
        reportHeaderAccessData: 'DATE DE ACCES',
        reportHeaderDataRect: 'DATE RECTIFICARE',
        reportHeaderDataDel: 'DATE DELETION',
        reportHeaderObject: 'DREPTUL LA OBIECT',
        reportHeaderCompletedDate: 'finalizat DT',
        
        reportSearch: 'Căutare',
        reportDownload: 'Descarca',
        reportRecords: 'Înregistrări',
        reportsYes:'Y',
        reportsNo:'N',
        reportsRecords:'Showing xxx to xxx of records',
        reportPaginationText:'Se afișează {from} la {to} din {count} de înregistrări | {count} înregistrări | 1 înregistrare',
    
        NavigationHome: 'Acasă',
        NavigationReports: 'rapoarte',
    
        requestStatus: 'Starea solicitării',
        assignTo :'Alocat la',
        requestArrivalType:'Solicitați tipul de sosire',
        SARSubmittedDate:'Data de inregistrare SAR',
        actualStartDate:'Data de începere efectivă',
        expectedCompletionDate:'Data estimativa de finalizare',
        actualCompletionDate:'Data de finalizare efectivă',
        notes:'Notițe',
        historicalNotes:'Note istorice',
        originalSARData: 'Datele SAR original este la fel de mai jos',
    
        Submitted:'Inregistrat',
        InProgress:'In progres',
        OnHold:'In asteptare',
        ResponseSent:'Răspuns trimis',
        Complete:'Complet',
        Open: 'Deschis',
    
        Web:'Web',
        Phone:'Telefon',
        SocialMedia:'Social Media',
        Post:'Post',
        Email:'Email',
        
        doctype: 'tipul documentului',
        selectUpload : 'selectati un fisier pentru incarcare',
        noFile : 'Niciun fișier ales',
    
        jan :	'ianuarie',
        feb :	'februarie', 
        mar :	'Martie',
        apr :	'Aprilie',
        may :	'Mai',
        jun :	'iunie',
        jul :	'iulie',
        aug :	'August',
        sep :	'Septembrie',
        oct :	'octombrie',
        nov :	'noiembrie',
        dec :	'decembrie',
        sun :	'duminică',
        mon :	'luni',
        tue :	'marţi',
        wed :	'miercuri',
        thu :	'joi',
        fri :	'vineri',
        sat :	'sâmbătă',
    
        
        tooltipAccessRequest: "Cerere de acces la date personale: Aveți dreptul, cu anumite excepții, de a obține o copie a datelor dvs. personale deținute de {brandPlcName1}. Orice informație furnizată dvs. va avea datele personale ale terților redactate, pentru a proteja confidențialitatea acelor terțe părți, cu excepția cazului în care avem consimțământul acestor terțe părți sau este rezonabil în circumstanțe să furnizăm datele personale ale terților catre dvs.",
        tooltipDataDeletion: "Solicitări pentru ștergerea datelor: S-ar putea să aveți dreptul ca datele dvs. personale deținute de {brandPlcName1} să fi fost șterse. Rețineți că acest lucru nu se aplică în toate cazurile, cum ar fi cazul în care suntem obligați prin lege să păstrăm datele dvs. sau când datele sunt necesare pentru a aduce sau a apăra o pretentie legală.",
        tooltipDataObject: "Cererile privind dreptul de a se opune prelucrării datelor: Aveți dreptul să vă opuneți prelucrării datelor dvs. personale de către {brandPlcName1} în scopuri de direct marketing . De asemenea, aveți dreptul să vă opuneți prelucrării datelor dvs. personale de către {brandPlcName2} în alte scopuri, dar acest lucru nu se aplică în toate cazurile, cum ar fi cazul în care datele sunt necesare pentru a face sau a apăra o solicitare legală.",
        tooltipDataRectification: "Solicitări pentru rectificarea datelor: Aveți dreptul să corectați datele dvs. personale deținute de {brandPlcName1} dacă acestea sunt inexacte. De asemenea, ne puteți cere să completam datele dvs. personale dacă sunt incomplete.",
    
        sarmgmtAddNote: "Adăugați Note",
        sarmgmtAddNotesButton: "Adăuga",
        sarmgmtCancelNotes: "Anulare",
        sarmgmtDocTitle: 'Titlul documentului',
        sarmgmtUploadDoc: 'Încărcați un document',
        sarmgmtattachmentUploadedBy: 'Incarcat de {uploadedby} pe {timestamp}',
    
        sarmgmtAddTask: "Adăugați Sarcini",
        sarmgmtViewTask: "Vizualizarea sarcinilor",	
        sarmgmtCancelTasks: "Ascundeți activități",
        TaskID:"Sarcina #",
        Description:"Descriere",
        Assignee:"Cesionar",
        CreatedDT:"Creată",
        CompletedDT:"terminat",
        Comment:"cometariu",
        taskStatus: "Status",
    
        taskDeleteMessage: "Vă rugăm să confirmați că doriți să ștergeți sarcina",
        taskDelete: "Șterge",
        taskSave: 'Salvați',
        sarmgmtAddNotes: 'a adăugat un comentariu',
    
        consentCustomerId: "Număr de înregistrare client",
        consentMarketingTextHeader: "Consimțământul de marketing pentru clienți: ",
        consentTooltipCustRef: "ID-ul clientului: Se referă la ID-ul împotriva căruia consimțământul clienților este stocat în GDPR Consimțământul Repository. Acest ID poate fi un număr unic număr de referință, numărul de loialitate, e-mail sau telefon. Vă rugăm să consultați îndrumări privind codul de client pentru GDPR Depozitului pentru piața dumneavoastră respectivă.",
    
        consentHstryCustomerId : 'Număr de înregistrare client',
        consentHstryOpcoName: 'Nume de brand',
        consentHstryChannel: 'Canal',
        consentHstryPrivacyPolicyVerId: 'Id-ul Ver Politica de confidențialitate',
        consentHstryMarketingChannelVersion: 'Marketing Versiunea canal',
        consentHstryOptIn: 'Opt In',
        consentHstrySubmitedDate: 'Data Submited',
        consentHstrySource: 'Sursă',
        consentHstrySubmittedBy: 'Înscris în bibliotecă de',
        consentHstryfileDownloadName: 'Clienți Consimțământul Report.csv',
        consentHistoryButton: "Obțineți istoric de consimțământ",
        taskEdit: 'Editați'
    },    
}