import { defineComponent } from "vue";
import { SARLegalInitModel } from "@/models/SARLegalInitModel";
import { GSARService } from "@/services/gasr.service";
import { UploadConfig } from "@/config/application-config";
import CommonMixin from "@/mixins/common.mixin";
import VueScrollTo from "vue-scrollto";

export default defineComponent({
  name: "userDocumentsMixin",
  mixins: [CommonMixin],
  props: {
    initModel: {
      type: SARLegalInitModel,
      required: true,
    },
    newUser: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      files: new FormData(),
      fileName: "",
      showFileUploadSuccessMessage: false,
      validationMessage: new Array<string>(),
      selectedFile: {} as File,
      allowedFileTypes: [
        "doc",
        "docx",
        "xls",
        "xlsx",
        "pdf",
        "rtf",
        "jpg",
        "png",
        "gif",
        "txt",
      ],
    };
  },
  methods: {
    getSelectedDocName(docID: string) {
      let dropdownKey = this.initModel.DocumenTypeList.filter((x) => {
        return x.value == docID;
      })[0].resourceKeyVal;
      return this.translate("labeltext." + dropdownKey);
    },

    fileChange(fileList: File[]) {
      this.files.append("file", fileList[0], fileList[0].name);
      if (fileList.length !== 0) {
        this.selectedFile = fileList[0];
        this.fileName = fileList[0].name;
        this.showFileUploadSuccessMessage = false;
      }
    },

    clear() {
      const fileUploadInput = this.$refs.fileupload as any;
      fileUploadInput.type = "text";
      fileUploadInput.type = "file";
    },

    upload() {
      this.ValidateInput();

      if (!this.validationMessage.length) {
        let self = this;

        const service = new GSARService();
        service
          .upload(this.files)
          .then((response) => {
            var obj = {} as any;
            obj["DocumentTypeId"] = this.initModel.selectedDocument;
            obj["Guid"] = response.data;
            obj["FileLocation"] = this.fileName;

            this.newUser.DocumentList.push(obj);
            this.files = new FormData();
            this.clear();
            this.initModel.selectedDocument = "";
            this.fileName = "";
            this.showFileUploadSuccessMessage = true;
          })
          .catch((error) => {
            self.validationMessage = [];
            self.validationMessage.push(
              self.translate("validationtext.validationFileUploadFailed")
            );
            self.showFileUploadSuccessMessage = false;
            self.$emit("showValidationMessage", self.validationMessage);
          });
      } else {
        this.scrollToTop();
      }
    },

    ValidateInput() {
      this.validationMessage = [];

      if (!this.initModel.selectedDocument) {
        this.validationMessage.push(
          this.translate("validationtext.validationDocType")
        );
      }

      if (!this.fileName) {
        this.validationMessage.push(
          this.translate("validationtext.validationSelectFile")
        );
      } else {
        let fileExtension = this.fileName.split(".").pop();
        if (fileExtension) {
          let allowedFileExtension = this.allowedFileTypes;
          if (allowedFileExtension.indexOf(fileExtension.toLowerCase()) < 0) {
            this.validationMessage.push(
              this.$t("validationtext.validationAllowedFileTypes", {
                allowedFileExtensions: this.allowedFileTypes.toString(),
              })
            );
          }
        }
      }

      if (
        this.selectedFile !== undefined &&
        Math.round(this.selectedFile.size / 1024) >
          UploadConfig.maxFileUploadSize
      ) {
        this.validationMessage.push(
          this.$t("validationtext.validationMaxFileSize", {
            filesize: UploadConfig.maxFileUploadinMB,
          })
        );
        this.files = new FormData();
      }

      if (this.validationMessage.length !== 0) {
        this.showFileUploadSuccessMessage = false;
      }

      this.$emit("showValidationMessage", this.validationMessage);
    },

    showDeclaration(): boolean {
      return this.initModel.Declaration || !this.initModel.ColleagueDeclaration;
    },

    showColleagueDeclaration(): boolean {
      return this.initModel.ColleagueDeclaration || !this.initModel.Declaration;
    },

    scrollToTop() {
      VueScrollTo.scrollTo("#top", 0);
    },
  },
});
