<template>
  <div
    class="gd-attachment mb-gutter"
    v-if="sarMgmtModel.CurrentAttachments != null"
  >
    <div class="mb-gutter">
      <h3>{{ $t("labeltext.attachments") }}</h3>
    </div>
    <div class="gd-file-list font-small">
      <ul class="list-styled">
        <li
          v-for="(attachment, index) in sarMgmtModel.CurrentAttachments"
          :key="index"
        >
          <img
            src="./../../../assets/images/attachment.png"
            class="legal__attachment"
          />
          <a
            @click="downloadAttachments(attachment.FileGuid, attachment.FileName)"
            class="legal__attachmentlist"
          >
            {{ attachment.FileName }}
          </a>
          <div class="legal__attachmenttext">
            {{ getLegalAttachmentText(attachment) }}
          </div>
          <div>
            <span>{{ attachment.DocumentTitle }}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SARLegalMgmtModel } from "@/models/SARLegalMgmtModel";
import CommonMixin from "@/mixins/common.mixin";
import { AttachmentFileDetail } from "@/common/common.model";

export default defineComponent({
  name: "SARMgmtAttachment",
  mixins: [CommonMixin],
  props: {
    sarMgmtModel: {
      type: SARLegalMgmtModel,
      required: true,
    },
  },
  methods: {
    getLegalAttachmentText(attachment: any): string {
      return this.$t("labeltext.sarmgmtattachmentUploadedBy", {
        uploadedby: attachment.UploadedBy,
        timestamp: attachment.UploadedDate.replace("T", " "),
      });
    },
    
    downloadAttachments(guidFileName: string, originalFileName: string) {     
      let attachmentFileDetails = new AttachmentFileDetail();
      attachmentFileDetails.guidFileName = guidFileName;
      attachmentFileDetails.originalFileName = originalFileName;

      this.$emit("emmitedFileDetailsLegal", attachmentFileDetails);
    },
  },
});
</script>