<template>
  <div class="app__body">
    <v-userdetails
      @showAcknowledgementComponent="isAcknowledgementComponentVisible"
    ></v-userdetails>
  </div>
</template>

<script lang="ts">
import { IShowAcknowledgement } from "@/common/common.model";
import { defineComponent } from "vue";
import UserDetails from "./UserDetails/UserDetails.vue";

export default defineComponent({
  name: "Body",
  components: {
    "v-userdetails": UserDetails,
  },
  data() {
    return {
      showAckComponent: false,
    };
  },
  methods: {
    isAcknowledgementComponentVisible(data: IShowAcknowledgement) {
      this.$emit("showAckComponent", data);
    },
  },
});
</script>