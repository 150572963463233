
export class ResourceItem {
    text: string;
    value: string;
    resourceKeyVal: string;

    constructor() {
        this.text = '';
        this.value = '';
        this.resourceKeyVal = '';
    }
}

export class Disabled {
    to: Date;

    constructor() {
        this.to = new Date((new Date().getFullYear() + 100), 0, 1);
    }
}

export class SARLegalInitModel {
    SARID: string;
    storeTime: string;
    employeeStartDate: string;
    isPostSuccessful: boolean;
    locale: string;
    StoreDateofvisit: any;
    StoreTimeofvisit: string;
    Declaration: boolean;          // Original code implies string but uses number in places
    errors: any[];
    modelErrors: any[];
    selectedTitle: string;
    selectedTitleValue: string | number;    // Original code implies string but uses number in places
    selectedOtherTitle: string;
    isDisabled: boolean;
    selectedDocument: string;
    disabled: Disabled;
    tempToken: string;
    TitleList: ResourceItem[];
    CountriesList: any[];
    DocumenTypeList: ResourceItem[];
    RequestGuid: string;
    RequestStatusList: any[];
    RequestArrivalTypeList: any[];
    TaskStatusList: any[];
    brandPlcName: string;
    datepickerLang: string;
    formVersion: number;
    languageVersion: number;
    ComponentMappings: any[];
    StoreStartDateofvisit: any;
    StoreEndDateofvisit: any;
    storeStartHourOfVisit: string | number;     // Original code implies string but uses number in places
    storeStartMinuteOfVisit: string | number;   // Original code implies string but uses number in places
    storeEndHourOfVisit: string | number;       // Original code implies string but uses number in places
    storeEndMinuteOfVisit: string | number;     // Original code implies string but uses number in places
    ColleagueDeclaration: boolean | null;     // Original code implies string but uses number in places

    constructor() {
        this.SARID = '';
        this.storeTime = '';
        this.employeeStartDate = '';
        this.isPostSuccessful = false;
        this.locale = '';
        this.StoreDateofvisit = '';
        this.StoreTimeofvisit = '';
        this.Declaration = false;
        this.errors = [];
        this.modelErrors = [];
        this.storeStartHourOfVisit = "";
        this.storeStartMinuteOfVisit = '';
        this.selectedTitle = '';
        this.selectedTitleValue = '';
        this.selectedOtherTitle = '';
        this.isDisabled = true;
        this.selectedDocument = '';
        this.disabled = new Disabled()
        this.tempToken = '';
        this.TitleList = [
            {
                text: 'Mr',
                value: "1",
                resourceKeyVal: 'Mr'
            },
            {
                text: 'Mrs',
                value: "2",
                resourceKeyVal: 'Mrs'
            },
            {
                text: 'Miss',
                value: "3",
                resourceKeyVal: 'Miss'
            },
            {
                text: 'Ms',
                value: "4",
                resourceKeyVal: 'Ms'
            },
            {
                text: 'Other',
                value: "5",
                resourceKeyVal: 'OtherTitle'
            }
        ];
        this.CountriesList = [];
        this.DocumenTypeList = [
            {
                text: 'ID Proof',
                value: "1",
                resourceKeyVal: 'idProof'
            },
            {
                text: 'Address Proof',
                value: "2",
                resourceKeyVal: 'addProof'
            },
            {
                text: 'Authorization Document',
                value: "3",
                resourceKeyVal: 'authDoc'
            },
            {
                text: 'Other',
                value: "4",
                resourceKeyVal: 'otherDoc'
            }
        ];
        this.RequestGuid = "";
        this.RequestStatusList = [];
        this.RequestArrivalTypeList = [];
        this.RequestStatusList = [];
        this.RequestArrivalTypeList = [];
        this.TaskStatusList = [];
        this.brandPlcName = '';
        this.datepickerLang = '';
        this.formVersion = 0;
        this.languageVersion = 0;
        this.ComponentMappings = [];
        this.StoreStartDateofvisit = '';
        this.StoreEndDateofvisit = '';
        this.storeStartHourOfVisit = "";
        this.storeStartMinuteOfVisit = '';
        this.storeEndHourOfVisit = "";
        this.storeEndMinuteOfVisit = '';
        this.ColleagueDeclaration = false;
    }
}