<template>
  <div>
    <div class="grid mb-gutter">
      <div class="form-field form-field--gd grid__item">
        <label class="field-row">
          <span data-auto="SARId-lbl" class="label label--text label--inline">
            {{ $t("labeltext.reportRequestID") }}
          </span>
          <input type="text" v-model="initModel.SARID" name="SARID" disabled />
        </label>
      </div>
      <div class="form-field form-field--gd grid__item">
        <label class="field-row">
          <span
            data-auto="SARSubmittedDate-lbl"
            class="label label--text label--inline"
          >
            {{ $t("labeltext.SARSubmittedDate") }}
          </span>
          <input
            type="text"
            v-model="sarLegalMgmt.SARCreatedDT"
            name="SARSubmittedDate"
            disabled
          />
        </label>
      </div>
      <div class="form-field form-field--gd grid__item">
        <label class="field-row">
          <span
            data-auto="requestStatus-lbl"
            class="label label--text label--inline"
          >
            {{ $t("labeltext.requestStatus") }}
          </span>
          <select
            @change="emitChangesToParent"
            v-model="sarLegalMgmt.RequestStatusID"
            name="RequestStatusID"
            class="fixedWidth"
          >
            <option
              v-for="(RequestStatusVal, index) in initModel.RequestStatusList"
              :value="RequestStatusVal.RequestStatusID"
              :key="index"
            >
              {{ getDDLTranslatedValue(RequestStatusVal.Resource_Key) }}
            </option>
          </select>
        </label>
      </div>
      <div class="form-field form-field--gd grid__item">
        <label class="field-row">
          <span
            data-auto="requestArrivalType-lbl"
            class="label label--text label--inline"
          >
            {{ $t("labeltext.requestArrivalType") }}
          </span>
          <select
            @change="emitChangesToParent"
            v-model="sarLegalMgmt.RequestArrivalTypeID"
            name="RequestArrivalTypeID"
            class="fixedWidth"
          >
            <option
              v-for="(
                RequestArrivalType, index
              ) in initModel.RequestArrivalTypeList"
              :value="RequestArrivalType.RequestArrivalTypeID"
              :key="index"
            >
              {{ getDDLTranslatedValue(RequestArrivalType.Resource_Key) }}
            </option>
          </select>
        </label>
      </div>
      <div class="form-field form-field--gd grid__item">
        <div class="field-row">
          <span
            data-auto="actualStartDate-lbl"
            class="label label--text label--inline"
          >
            {{ $t("labeltext.actualStartDate") }}
          </span>
          <date-picker
            :model-value="sarLegalMgmt.ActualStartDT"
            @update:model-value="actualStartDTChanged"
            :locale="initModel.datepickerLang"
            :enable-time-picker="false"
            :format="dateFormat"
            auto-apply
            hide-input-icon
            name="ActualStartDate"
          >
          </date-picker>
        </div>
      </div>
      <div class="form-field form-field--gd grid__item">
        <div class="field-row">
          <span
            data-auto="expectedCompletionDate-lbl"
            class="label label--text label--inline"
          >
            {{ $t("labeltext.expectedCompletionDate") }}
          </span>
          <date-picker
            :model-value="sarLegalMgmt.ExpectedCompletionDT"
            @update:model-value="expectedCompletionDTChanged"
            :locale="initModel.datepickerLang"
            :enable-time-picker="false"
            :format="dateFormat"
            auto-apply
            hide-input-icon
            name="ExpectedCompletionDT"
          >
          </date-picker>
        </div>
      </div>
      <div class="form-field form-field--gd grid__item">
        <div class="field-row">
          <span
            data-auto="actualCompletionDate-lbl"
            class="label label--text label--inline"
          >
            {{ $t("labeltext.actualCompletionDate") }}
          </span>
          <date-picker
            :model-value="sarLegalMgmt.ActualCompletionDT"
            @update:model-value="actualCompletionDTChanged"
            :locale="initModel.datepickerLang"
            :enable-time-picker="false"
            :format="dateFormat"
            auto-apply
            hide-input-icon
            name="ActualCompletionDT"
          >
          </date-picker>
        </div>
      </div>
      <div class="form-field form-field--gd grid__item">
        <label class="field-row">
          <span
            data-auto="assignTo-lbl"
            class="label label--text label--inline"
          >
            {{ $t("labeltext.assignTo") }}
          </span>
          <input
            type="text"
            @change="emitChangesToParent"
            v-model="sarLegalMgmt.Assignee"
            name="AssignTo"
            maxlength="200"
          />
        </label>
      </div>
    </div>
    <div>
      <!-- file upload -->
      <v-fileupload
        :initModel="initModel"
        :sarMgmtModel="sarLegalMgmt"
        @showValidationMessage="showErrorMessageForFileUploadFromChild"
      >
      </v-fileupload>
    </div>
    <div>
      <!-- Attachment Download -->
      <v-fileattachment
        :sarMgmtModel="sarLegalMgmt"
        @emmitedFileDetailsLegal="downloadFileForLegal"
      >
      </v-fileattachment>
    </div>
    <div class="padding--top">
      <div class="mb-gutter">
        <button
          v-if="!showAddNoteSection"
          class="mb-gutter button addnotes-btn"
          @click.prevent="expandNotes"
        >
          {{ $t("labeltext.sarmgmtAddNote") }}
        </button>
        <div v-if="showAddNoteSection" class="mb-gutter">
          <div class="form-field form-field--gd">
            <label class="field-row">
              <span
                data-auto="addNewTaskDesc-lbl"
                class="label label--text label--inline"
              >
                {{ $t("labeltext.notes") }}
              </span>
              <textarea
                v-model="sarLegalNotes.Notes"
                name="addNotes"
                maxlength="3000"
              ></textarea>
            </label>
          </div>
          <div class="grid grid--inline grid-align-items-right">
            <div class="grid__item form-field form-field--gd">
              <label class="field-row">
                <button class="button" @click.prevent="addNotesEvent">
                  {{ $t("labeltext.sarmgmtAddNotesButton") }}
                </button>
              </label>
            </div>
            <div class="grid__item form-field form-field--gd">
              <label class="field-row">
                <button class="button button--alt" @click.prevent="cancelNotes">
                  {{ $t("labeltext.sarmgmtCancelNotes") }}
                </button>
              </label>
            </div>
          </div>
        </div>
        <div
          v-if="sarLegalMgmt.HistoricalNotes"
          class="form-field gd-form-declaration div--background div--scroll"
        >
          <label class="field-row cusror--default">
            <div
              ul
              name="HistoricalNotes"
              class="label label--text label--inline"
            >
              <div class="form-field form-field--gd"></div>
              <div
                class="form-field form-field--gd div--padding"
                v-for="(item, index) in sarLegalMgmt.HistoricalNotes"
                :key="index"
              >
                <div>
                  <p style="font-weight: bold">
                    {{ item.NotesCreatedBy }} {{ renderHistoricalNotes() }} -
                    {{ item.NotesCreatedDate.replace("T", " ") }} :
                  </p>
                  <p>
                    {{ item.Notes }}
                  </p>
                </div>
              </div>
            </div>
          </label>
        </div>
      </div>
      <div class="padding--top" style="display: block">
        <div class="mb-gutter">
          <div class="form-field form-field--gd">
            <button
              v-if="!showCreateTaskSection"
              class="mb-gutter button addnotes-btn"
              @click.prevent="expandCreateTasks"
            >
              {{ $t("labeltext.sarmgmtAddTask") }}
            </button>
            <div v-if="showCreateTaskSection == true">
              <div class="">
                <div class="form-field form-field--gd">
                  <label class="field-row">
                    <span
                      data-auto="addNewTaskDesc-lbl"
                      class="label label--text label--inline"
                    >
                      {{ $t("labeltext.Description") }}
                    </span>
                    <textarea
                      v-model="sarLegalTask.Description"
                      name="addNewTaskDesc"
                      maxlength="1000"
                    ></textarea>
                  </label>
                </div>
                <div class="form-field form-field--gd">
                  <label class="field-row">
                    <span
                      data-auto="addNewTaskComment-lbl"
                      class="label label--text label--inline"
                    >
                      {{ $t("labeltext.Comment") }}
                    </span>
                    <textarea
                      v-model="sarLegalTask.Comment"
                      name="addNewTaskComment"
                      maxlength="3000"
                    ></textarea>
                  </label>
                </div>
                <div class="form-field form-field--gd">
                  <label class="field-row">
                    <span
                      data-auto="addNewTaskAsignee-lbl"
                      class="label label--text label--inline"
                    >
                      {{ $t("labeltext.Assignee") }}
                    </span>
                    <input
                      type="text"
                      v-model="sarLegalTask.Assignee"
                      name="addNewTaskAsignee"
                      maxlength="100"
                    />
                  </label>
                </div>
              </div>
              <div class="grid grid--inline grid-align-items-right">
                <div class="grid__item form-field form-field--gd">
                  <label class="field-row">
                    <button type="button" class="button" @click="addNewTask">
                      {{ $t("labeltext.sarmgmtAddNotesButton") }}
                    </button>
                  </label>
                </div>
                <div class="grid__item form-field form-field--gd">
                  <label class="field-row">
                    <button
                      type="button"
                      class="button button--alt"
                      @click="cancelTask"
                    >
                      {{ $t("labeltext.sarmgmtCancelNotes") }}
                    </button>
                  </label>
                </div>
              </div>
            </div>
            <div>
              <label class="field-row cusror--default">
                <v-data-table
                  v-if="showtaskgrid"
                  :headers="headers"
                  :items="sargridsearchTaskResultFromParent"
                  :items-per-page="itemsPerPage"
                  :items-per-page-options="footerProps.itemsPerPageOptions"
                  :footer-props="footerProps"
                  v-model:expanded="expanded"
                  ref="taskTable"
                  item-key="SARManagementTaskID"
                  item-value="SARManagementTaskID"
                  fixed-header
                  show-expand
                  dense
                  class="table-styled mb-gutter"
                >
                  <template v-slot:[`item.SARManagementTaskID`]="{ item }">
                    <button
                      class="glyphicon glyphicon-eye-open"
                      @click.prevent="deleteTask(item.SARManagementTaskID)"
                    >
                      X
                    </button>
                  </template>

                  <template
                    #item.data-table-expand="{
                      internalItem,
                      item,
                      toggleExpand,
                    }"
                  >
                    <td>
                      <button
                        ref="toggleButton"
                        @click.prevent="
                          setEditorProperties(item);
                          toggleExpand(internalItem);
                        "
                        class="button taskedit-btn"
                      >
                        {{ $t("labeltext.taskEdit") }}
                      </button>
                    </td>
                  </template>
                  <template v-slot:expanded-row="{ columns, item }">
                    <tr>
                      <td
                        :colspan="columns.length"
                        style="padding-top: 12px; padding-bottom: 12px"
                      >
                        <div class="grid editable-grid">
                          <div class="form-field form-field--gd grid__item">
                            <label class="field-row">
                              <span
                                data-auto="requestStatusEdit-lbl"
                                class="label label--text label--inline"
                              >
                                {{ $t("labeltext.taskStatus") }}
                              </span>
                              <select
                                v-model="item.TaskStatusID"
                                name="RequestStatusID"
                                class="fixedWidth"
                              >
                                <option
                                  v-for="(
                                    TaskStatusVal, index
                                  ) in initModel.TaskStatusList"
                                  :value="TaskStatusVal.TaskStatusID"
                                  :key="index"
                                >
                                  {{
                                    getDDLTranslatedValue(
                                      TaskStatusVal.Resource_Key
                                    )
                                  }}
                                </option>
                              </select>
                            </label>
                          </div>

                          <div class="form-field form-field--gd grid__item">
                            <label class="field-row">
                              <span
                                data-auto="descriptionEdit-lbl"
                                class="label label--text label--inline"
                              >
                                {{ $t("labeltext.Description") }}
                              </span>
                              <textarea
                                v-model="description"
                                maxlength="1000"
                              ></textarea>
                            </label>
                          </div>
                          <div class="form-field form-field--gd grid__item">
                            <label class="field-row">
                              <span
                                data-auto="commentEdit-lbl"
                                class="label label--text label--inline"
                                >{{ $t("labeltext.Comment") }}</span
                              >
                              <textarea
                                v-model="comment"
                                rows="5"
                                maxlength="3000"
                              ></textarea>
                            </label>
                          </div>
                          <div class="form-field form-field--gd grid__item">
                            <label class="field-row">
                              <span
                                data-auto="assigneeEdit-lbl"
                                class="label label--text label--inline"
                              >
                                {{ $t("labeltext.Assignee") }}
                              </span>
                              <input
                                type="text"
                                v-model="assignee"
                                maxlength="100"
                              />
                            </label>
                          </div>
                        </div>
                        <button
                          type="button"
                          class="button button--align--right"
                          @click.prevent="updateTaskDetails(item)"
                        >
                          {{ $t("labeltext.taskSave") }}
                        </button>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-gutter">
        <h3>{{ $t("labeltext.consentMarketingTextHeader") }}</h3>
      </div>
      <div class="grid mb-gutter">
        <div class="form-field form-field--gd grid__item">
          <label class="field-row">
            <span
              data-auto="SARConsentId-lbl"
              class="label label--text label--inline"
            >
              {{ $t("labeltext.consentCustomerId") }}
            </span>

            <span class="tooltip-icon tooltip-icon-label">
              <i data-auto="ConsentHistoryInfo-btn"
                >i
                <v-tooltip
                  activator="parent"
                  location="bottom"
                  width="320px"
                  :contained="true"
                  :open-on-click="true"
                >
                  {{
                    $t("labeltext.consentTooltipCustRef", {
                      brandPlcName1: initModel.brandPlcName,
                      brandPlcName2: initModel.brandPlcName,
                    })
                  }}
                </v-tooltip>
              </i>
            </span>

            <input
              type="text"
              v-model="sarLegalMgmt.ConsentId"
              name="SARConsentID"
              @change="emitChangesForConsentHistory"
              maxlength="100"
            />
          </label>
        </div>
        <div class="form-field form-field--gd grid__item">
          <label class="field-row">
            <button type="button" class="button" @click="getConsentEvent">
              {{ $t("labeltext.consentHistoryButton") }}
            </button>
          </label>
        </div>
        <div v-if="showConsentHstryGrid">
          <label class="field-row cusror--default">
            <v-data-table
              class="table-styled mb-gutter"
              :items="sarConsentHistoryDataModel"
              :items-per-page="itemsPerPage"
              :items-per-page-options="footerProps.itemsPerPageOptions"
              :footer-props="footerProps"
              item-key="CustomerId"
              fixed-header
              show-expand
              dense
            >
            </v-data-table>
          </label>
          <export-excel
            :data="sarConsentHistoryDataModel"
            :fields="consentHistoryDownloadOptions.json_fields"
            type="csv"
            :name="translate('labeltext.consentHstryfileDownloadName')"
          >
            <button type="button" class="button button--alt button--block">
              {{ $t("labeltext.reportDownload") }}
            </button>
          </export-excel>
        </div>
      </div>
      <hr />
      <span data-auto="assignTo-lbl" class="label label--text label--inline">
        {{ $t("labeltext.originalSARData") }}
      </span>
      <hr />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SARLegalInitModel } from "@/models/SARLegalInitModel";
import { SARLegalMgmtModel } from "@/models/SARLegalMgmtModel";
import { SARLegalMgmtNotesModel } from "@/models/SARLegalMgmtNotesModel";
import { SARLegalMgmtTaskModel } from "@/models/SARLegalMgmtTaskModel";
import CommonMixin from "@/mixins/common.mixin";
import SARMgmtFileUpload from "./SARMgmtFileUpload.vue";
import SARMgmtAttachment from "./SARMgmtAttachment.vue";
import {
  FooterProps,
  GridDefault,
  AttachmentFileDetail,
} from "@/common/common.model";
import { ref } from "vue";
import { SARMgmtTasks } from "@/models/SARMgmtInitModel";

export default defineComponent({
  name: "SARMgmtEdit",
  mixins: [CommonMixin],
  components: {
    "v-fileupload": SARMgmtFileUpload,
    "v-fileattachment": SARMgmtAttachment,
  },
  props: {
    initModel: {
      type: SARLegalInitModel,
      required: true,
    },
    sarLegalMgmt: {
      type: SARLegalMgmtModel,
      required: true,
    },
    sarLegalNotes: {
      type: SARLegalMgmtNotesModel,
      required: true,
    },
    sargridsearchTaskResultFromParent: {
      type: Array,
      required: true,
    },
    sarLegalTask: {
      type: SARLegalMgmtTaskModel,
      required: true,
    },
    showtaskgrid: {
      required: true,
    },
    sarConsentHstryGrid: {
      required: true,
    },
    showConsentHstryGrid: {
      required: true,
    },
    sarConsentHistoryDataModel: {
      required: true,
    },
    consentHistoryDownloadOptions: {
      required: true,
    },
  },
  data() {
    return {
      showAddNoteSection: false,
      showCreateTaskSection: false,
      isbold: true,
      dateFormat: "dd/MM/yyyy",
      itemsPerPage: GridDefault.PagingSize,
      footerProps: new FooterProps(),
      headers: [] as any,
      expanded: ref([]),
      consentHeaders: [] as any,
      description: "",
      comment: "",
      assignee: "",
    };
  },

  created() {
    this.buildGridHeadings();
  },

  methods: {
    buildGridHeadings() {
      this.headers = [
        {
          title: "",
          key: "data-table-expand",
        },
        {
          title: this.translate("labeltext.TaskID"),
          key: "RowNum",
        },
        {
          title: this.translate("labeltext.taskStatus"),
          key: "TaskStatusResource_Key",
        },
        {
          title: this.translate("labeltext.Description"),
          key: "Description",
        },
        {
          title: this.translate("labeltext.Assignee"),
          key: "Assignee",
        },
        {
          title: this.translate("labeltext.CreatedDT"),
          key: "CreatedDT",
        },
        {
          title: this.translate("labeltext.CompletedDT"),
          key: "CompletedDT",
        },
        {
          title: this.translate("labeltext.Comment"),
          key: "Comment",
        },
        {
          title: "",
          key: "SARManagementTaskID",
        },
      ];

      this.consentHeaders = [
        {
          title: this.translate("labeltext.consentHstryCustomerId"),
          key: "CustomerId",
        },
        {
          title: this.translate("labeltext.consentHstryOpcoName"),
          key: "OpcoName",
        },
        {
          title: this.translate("labeltext.consentHstryChannel"),
          key: "Channel",
        },
        {
          title: this.translate("labeltext.consentHstryPrivacyPolicyVerId"),
          key: "PrivacyPolicyVerId",
        },
        {
          title: this.translate(
            "labeltext.consentHstryMarketingChannelVersion"
          ),
          key: "MarketingChannelVersion",
        },
        {
          title: this.translate("labeltext.consentHstryOptIn"),
          key: "OptIn",
        },
        {
          title: this.translate("labeltext.consentHstrySubmitedDate"),
          key: "ConsentHstrySubmitedDate",
        },
        {
          title: this.translate("labeltext.consentHstrySource"),
          key: "Source",
        },
        {
          title: this.translate("labeltext.consentHstrySubmittedBy"),
          key: "SubmittedBy",
        },
      ];
    },

    updateTaskEditButtonText() {
      var editElemtOpen = document.getElementsByClassName(
        "VueTables__child-row-toggler--closed"
      );
      if (editElemtOpen) {
        for (var i = 0; i < editElemtOpen.length; i++) {
          editElemtOpen[i].setAttribute(
            "data-value",
            this.translate("labeltext.taskEdit")
          );
        }
      }
    },

    expectedCompletionDTChanged(date: any) {
      this.sarLegalMgmt.ExpectedCompletionDT = date;
      this.emitChangesToParent();
    },
    
    actualCompletionDTChanged(date: any) {
      this.sarLegalMgmt.ActualCompletionDT = date;
      this.emitChangesToParent();
    },

    actualStartDTChanged(date: any) {
      this.sarLegalMgmt.ActualStartDT = date;
      this.emitChangesToParent();
    },

    emitChangesToParent() {
      this.$emit("sarMgmttUpdate");
    },

    addNotesEvent() {
      this.$emit("sarMgmtNotesUpdate");
      if (
        this.sarLegalNotes !== null &&
        this.sarLegalNotes !== undefined &&
        this.sarLegalNotes.Notes.trim()
      ) {
        this.showAddNoteSection = false;
      }
    },

    expandNotes() {
      this.showAddNoteSection = true;
    },

    cancelNotes() {
      this.sarLegalNotes.Notes = "";
      this.initModel.modelErrors = [];
      this.showAddNoteSection = false;
    },

    renderHistoricalNotes() {
      return this.translate("labeltext.sarmgmtAddNotes");
    },

    expandCreateTasks() {
      this.showCreateTaskSection = true;
    },
    cancelCreateTasks() {
      this.showCreateTaskSection = false;
    },

    addNewTask() {
      this.sarLegalTask.IsUpdateRequest = 0;
      this.$emit("sarMgmtAddNewTask", undefined);
    },

    cancelTask() {
      this.sarLegalTask.Description = "";
      this.sarLegalTask.Assignee = "";
      this.sarLegalTask.Comment = "";
      this.showCreateTaskSection = false;
    },

    showErrorMessageForFileUploadFromChild(validationMessages: any) {
      this.$emit("showValidationMessageEventFromChild", validationMessages);
    },

    downloadFileForLegal(fileDetails: AttachmentFileDetail) {
      this.$emit("fileDownloadEventFromChild", fileDetails);
    },

    setEditorProperties(item: any) {      
      this.description = item.Description;
      this.comment = item.Comment;
      this.assignee = item.Assignee;
    },

    updateTaskDetails(rowDetails: any) {
      this.sarLegalTask.TaskStatusID = rowDetails.TaskStatusID;
      this.sarLegalTask.CompletedDT = rowDetails.CompletedDT;
      this.sarLegalTask.SARManagementTaskID = rowDetails.SARManagementTaskID;
      this.sarLegalTask.SARManagementID = rowDetails.SARManagementID;
      this.sarLegalTask.IsUpdateRequest = 1;

      var editModel = {} as any;
      editModel.Description = this.description;
      editModel.Comment = this.comment;
      editModel.Assignee = this.assignee;

      var refObject = {} as any;
      refObject.refTable = this.$refs;
      refObject.rowNumber = rowDetails.RowNum;
      refObject.EditModel = editModel;

      this.expanded = [];
      this.$emit("sarMgmtAddNewTask", refObject);
    },

    deleteText() {
      return this.translate("labeltext.taskDelete");
    },

    confirmBoxText() {
      return this.translate("labeltext.taskDeleteMessage");
    },

    cancelText() {
      return this.translate("labeltext.cancel");
    },

    deleteTask(taskId: string) {
      if (confirm(this.translate("labeltext.taskDeleteMessage"))) {
        this.sarLegalTask.IsUpdateRequest = 2;
        this.sarLegalTask.SARManagementTaskID = taskId;
        this.$emit("sarMgmtAddNewTask", undefined);
      }
    },

    getConsentEvent() {
      this.$emit("sarMgmtConsentHistory");
    },

    emitChangesForConsentHistory() {
      this.$emit("sarMgmttUpdate");
      this.$emit("sarMgmtConsentHistory");
    },
  },
});
</script>

<style scoped>
@media (max-width: 500px) {
  .dc-app .grid__item {
    max-width: 100%;
    flex: 0 0 100%;
  }
}
/* Scoped style to constrain the width of the date picker */
.dp__main {
  font-family: var(--dp-font-family);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  box-sizing: border-box;
  position: relative;
  width: 49% !important;
}
.dc-app .button.taskedit-btn {
  height: 2rem;
  min-width: 3rem;
  background-color: #0098db;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 10px 10px 10px 10px;
  cursor: pointer;
}
</style>
