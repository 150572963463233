<template>
  <div id="top">
    <link rel="stylesheet" :href="opcostylesheets" />
    <v-header v-bind:styleCodeFromParent="styleCode"> </v-header>
    <v-body
      v-if="!showAcknowledgement"
      @showAckComponent="toggleAcknowledgementComponent"
    ></v-body>
    <v-acknowledgement
      v-if="showAcknowledgement"
      :custSARID="acknowldegementSARID"
    ></v-acknowledgement>
    <v-footer></v-footer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
import Body from "@/components/body/Body.vue";
import UserAcknowledgement from "@/components/body/UserDetails/UserAcknowledgement.vue";

export default defineComponent({
  name: "SARCustomer",
  components: {
    "v-header": Header,
    "v-footer": Footer,
    "v-body": Body,
    "v-acknowledgement": UserAcknowledgement,
  },
  data() {
    return {
      showAcknowledgement: false,
      acknowldegementSARID: "",
      styleCode: "",
      opcostylesheets: "",
    };
  },
  created() {
    const styleCodeQueryString =
      this.$route.query.Session?.toString() ||
      this.$route.query.session?.toString();

    if (styleCodeQueryString !== undefined) {
      this.styleCode = styleCodeQueryString.toLowerCase();
      this.importStyleSheet();
    }
  },
  methods: {
    toggleAcknowledgementComponent(data: any) {
      this.acknowldegementSARID = data.ackSARId;
      this.showAcknowledgement = data.isAcknowledgementVisible;
    },
    importStyleSheet() {
      let dynamicStyleSheet = `styles_${
        this.isNewerScrewfix() ? "sf_6" : this.styleCode
      }.css`;
      this.opcostylesheets = `/../..${process.env.VUE_APP_PUBLIC_PATH}/assets/css/` + dynamicStyleSheet;
    },
    isNewerScrewfix() {
      const version = this.styleCode.match(/sf_(\d+)/);
      return version && !isNaN(Number(version[1])) && Number(version[1]) >= 6;
    },
  },
});
</script>
