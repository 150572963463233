export class SARLegalMgmtNotesModel {
    SARId: string;
    Notes: string;
    NotesCreatedDate: string;
    NotesCreatedBy: string;

    constructor() {
        this.SARId = "";
        this.Notes = "";
        this.NotesCreatedDate = "";
        this.NotesCreatedBy = "";
    }
}