import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "app__body",
  id: "top"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_error = _resolveComponent("v-error")!
  const _component_v_sarreports = _resolveComponent("v-sarreports")!
  const _component_v_footer = _resolveComponent("v-footer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.showErrorPage)
        ? (_openBlock(), _createBlock(_component_v_error, {
            key: 0,
            errorMsgFromParent: _ctx.errorMessage
          }, null, 8, ["errorMsgFromParent"]))
        : _createCommentVNode("", true),
      (!_ctx.showErrorPage)
        ? (_openBlock(), _createBlock(_component_v_sarreports, {
            key: 1,
            onShowErrorEvent: _ctx.onShowErrorPage,
            onShowHeaderEvent: _ctx.onShowHeaderFooter
          }, null, 8, ["onShowErrorEvent", "onShowHeaderEvent"]))
        : _createCommentVNode("", true),
      (_ctx.showHeaderFooterComponent)
        ? (_openBlock(), _createBlock(_component_v_footer, { key: 2 }))
        : _createCommentVNode("", true)
    ])
  ]))
}