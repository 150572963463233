import { defineComponent } from "vue";
import { SARLegalInitModel } from "@/models/SARLegalInitModel";
import { STYLEVERSIONMAP } from "@/common/common.model";
import CommonMixin from "@/mixins/common.mixin";

export default defineComponent({
  name: "userCustEmpRelationshipMixin",
  mixins: [CommonMixin],
  props: {
    initModel: {
      type: SARLegalInitModel,
      required: true,
    },
    newUser: {
      type: Object,
      required: true,
    },
    styleCode: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      dateFormat: "dd/MM/yyyy",
    };
  },
  methods: {
    clearInputFieldsCustomerEmployee() {
      if (this.newUser.IsCustomerCheckbox.toString() !== "true") {
        this.newUser.CustomerNumber = "";
        this.newUser.OrderNumber = "";
        this.newUser.ComplaintRefNumber = "";
      }
      if (this.newUser.IsEmployeeCheckbox.toString() !== "true") {
        this.newUser.EmployeeNumber = "";
        this.newUser.StartDT = "";
        this.newUser.EndDT = "";
        this.newUser.OfficeFunction = "";
      }
    },

    autosize() {
      var el = document.getElementsByName("requestdetail")[0];
      setTimeout(function () {
        el.style.cssText = "height:auto; padding:0";

        el.style.cssText = "-moz-box-sizing:content-box";
        el.style.cssText = "height:" + (el.scrollHeight + 5) + "px";
      }, 0);
    },

    // Provide a get translated method for specific view
    getTranslatedValueEx(label: string) {
      let version =
        this.styleCode && STYLEVERSIONMAP.data[this.styleCode.toLowerCase()]
          ? STYLEVERSIONMAP.data[this.styleCode.toLowerCase()]
          : "";

      let isNewerScrewfix = version && Array.isArray(version);

      let value = null;
      if (isNewerScrewfix) {
        for (let i = 0; i < version.length; i++) {
          try {
            let newLabel = `${label}${version[i]}`;
            value = this.$t(newLabel);
            if (newLabel !== value) {
              break;
            } else {
              value = null;
            }
          } catch (error) {}
        }
      }

      let defaultValue = "";
      try {
        if (value === null || value === undefined) {
          defaultValue = this.$t(label);
        } else {
          defaultValue = value;
        }
      } catch (error) {}

      return defaultValue;
    },

    getTranslatedValueWithParams(label: string, params: {}) {
      let version =
        this.styleCode && STYLEVERSIONMAP.data[this.styleCode.toLowerCase()]
          ? STYLEVERSIONMAP.data[this.styleCode.toLowerCase()]
          : "";

      let isNewerScrewfix = version && Array.isArray(version);

      let value = null;
      if (isNewerScrewfix) {
        for (let i = 0; i < version.length; i++) {
          try {
            let newLabel = `${label}${version[i]}`;
            value = this.$t(newLabel, params);
            if (newLabel !== value) {
              break;
            } else {
              value = null;
            }
          } catch (error) {}
        }
      }

      let defaultValue = "";
      try {
        if (value === null || value === undefined) {
          defaultValue = this.$t(label, params);
        } else {
          defaultValue = value;
        }
      } catch (error) {}

      return defaultValue;
    },
  },
});
