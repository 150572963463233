import App from './App.vue'
import { createApp, h } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import i18n from './locales/i18n';
import router from './router'
import excel from "vue-excel-export";
import VueScrollTo from "vue-scrollto";

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import './assets/css/fonts.css'
import './assets/css/dc-design-components/content/css/core.css'
import './assets/css/common.css'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
});

// Instantiate Pinia - this will resolve all custom stores
const pinia = createPinia();

// Set the persisted state store - defaults to localStorage which is what is being used by GSAR
// To configure options see - https://prazdevs.github.io/pinia-plugin-persistedstate/guide/config.html
pinia.use(piniaPluginPersistedstate);

const app = createApp({
  render: () => h(App)
});

app.use(pinia);
app.use(i18n);
app.use(router);
app.use(excel);
app.use(vuetify);
app.use(VueScrollTo);

app.component('date-picker', VueDatePicker);

app.mount('#app');
